import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
import cogoToast from "cogo-toast"
import {Mixpanel} from '../../Components/Helpers/MixPanel'


export const userLogin = createAsyncThunk(
    'login',
    async ({ email, password }, { rejectWithValue }) => {
      try {
        const res = await axios.post(
          `${apiUrl}auth/signin`,
          { 
            username: email, 
            password
          }
        )
        if(res.status === 200){
            let role = res.data.user.is_sales_agent
            if(role){
                cogoToast.success("Login Successful!",{
                    position: 'bottom-right'
                })
                let result = res.data
                Mixpanel.identify(result.user.sales_agent_id)
                Mixpanel.track('Successful Login')
                Mixpanel.people.set({
                  $name: result.user.first_name + ' ' + result.user.last_name,
                  $email: result.user.email
                })
                return res.data
            }
            else{
                cogoToast.error("User not authorized to access this page!")
                return rejectWithValue();
            }
        }
      } catch (error) {
        if(error.response.status === 401){
          cogoToast.error('Invalid Credentials!')
          return rejectWithValue(error.response)
        }
        else{
          cogoToast.error('Invalid Credentials!')
          return rejectWithValue(error.response)
        }
      }
    }
 )

export const userRegister = createAsyncThunk(
    'register',
    async ({ email, password, firstname, lastname, languages }, { rejectWithValue }) => {
      try {
        const res = await axios.post(
          `${apiUrl}sales/register`,
          { 
            email: email, 
            password,
            first_name: firstname,
            last_name: lastname,
            languages: languages
          }
        )
        if(res.status === 200){
            cogoToast.success("Registration Successful!, Kindly login to proceed");
            Mixpanel.track('Signup')
            return res.data
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            cogoToast.error('Oops! Looks like a user already exist with this credentials.')
          return rejectWithValue(error.response.data.error)
        } else {
            cogoToast.error('Oops! Looks like a user already exist with this credentials.')
          return rejectWithValue(error.response.data.error)
        }
      }
    }
)

export const userForgot = createAsyncThunk(
    'forgot',
    async ({ email }, { rejectWithValue }) => {
      try {
        const res = await axios.post(
          `${apiUrl}sales/forgot-password`,
          { 
            email: email, 
          }
        )
        if(res.status === 200){
          cogoToast.success(
            "Check your email for password reset instructions!",
            {
              position: "top-center",
            }
          );
            return res.data
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            cogoToast.error('Kindly check that the credentials entered is valid!')
          return rejectWithValue(error.response.data)
        } else {
            cogoToast.error('Kindly check that the credentials entered is valid!')
          return rejectWithValue(error.response.data)
        }
      }
    }
 )

 export const userReset = createAsyncThunk(
  'reset',
  async ({ otp, userId, password }, { rejectWithValue }) => {
    try {
      const res = await axios.patch(
        `${apiUrl}sales/reset-password`,
        { 
          password: password, 
          user_id: userId,
          otp: otp
        }
      )
      if(res.status === 200){
        cogoToast.success("Password successfully changed, Login to continue", {
          position: "top-right",
          });
            return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
           cogoToast.error(`The link is either invalid or has expired, please request again!`);
        return rejectWithValue(error.response.data)
      } else {
          cogoToast.error(`The link is either invalid or has expired, please request again!`); 
        return rejectWithValue(error.response.data)
      }
    }
  }
)