import { createSlice } from '@reduxjs/toolkit'
import { getAgentCampaign } from './campaignActions'



const initialState = {
    loader: false,
    campaigns: [],
    campaign: {}
}

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
      findCampaign: (state, {payload}) =>{
          // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        let campaign = state.campaigns.find((val) => val.id === payload)
        state.campaign = campaign
      }
    
  },
  extraReducers: {
    //   Get Agent campaigns ----------
    [getAgentCampaign.pending]: (state)=>{
        state.loader = true
    },
    [getAgentCampaign.fulfilled]: (state, {payload}) =>{
        state.loader = false
        state.campaigns = payload.data.filter(item => item.status === 'Active');
    },
    [getAgentCampaign.rejected]: (state) =>{
        state.loader = false
    }
   
  }
})

// Action creators are generated for each case reducer function
export const { findCampaign } = campaignSlice.actions

export default campaignSlice.reducer