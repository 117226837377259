import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
import cogoToast from "cogo-toast";
import {Mixpanel} from '../../Components/Helpers/MixPanel'


const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
};
  
export const getAssignedFarmers = createAsyncThunk(
    'assignedFarmers',
    async ({callStatus, page, id}, {rejectWithValue, dispatch}) => {
      try {
        if(callStatus === 'all'){
          const res = await axios.get(
            `${apiUrl}sales/farmers?page=${page}&campaign=${id}`,
            {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${getToken()}`
              }, 
            }
          )
          if(res.status === 200){
              return res.data
          }   
      } 
      else if(callStatus === 'all'){
        const res = await axios.get(
          `${apiUrl}sales/farmers?page=${page}&campaign=${id}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return res.data
        }
      }
      else{
        const res = await axios.get(
          `${apiUrl}sales/farmers?call_status=${callStatus}&page=${page}&campaign=${id}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return res.data
        }
      }
        
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
 )

 export const getAssignedInfo = createAsyncThunk(
  'assignedInfo',
  async ({id}, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/farmers/overview?campaign=${id}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)
 

export const searchAssignedFarmers = createAsyncThunk(
  'searchAssignedFarmers',
  async ({campaignId, val}, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/farmers?campaign=${campaignId}&search=${val}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

 export const getCrops = createAsyncThunk(
  'crops',
  async (arg, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}crops`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getLivestock = createAsyncThunk(
  'livestock',
  async (arg, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}livestock`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)


export const getStates = createAsyncThunk(
  'states',
  async (arg, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}states`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getLGAByStateID = createAsyncThunk(
  'lgas',
  async ({id},{rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}lgas/${id}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

 export const getFarmerById = createAsyncThunk(
  'FarmerById',
  async ({id}, {rejectWithValue, dispatch}) => {
    try {
      const res = await axios.get(
        `${apiUrl}sales/farmers/${id}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
           const stateId = res.data.data.state_id
           let resp;
           if(stateId != null){
               resp = await axios.get(
                  `${apiUrl}lgas/${stateId}`,
                  {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }, 
                  }
                )
              return {
                data: res.data, 
                lga: resp.data
              }
           }
           else{
             return{
              data: res.data, 
              lga: []
             }
           }
              
          
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const updateFarmersProfile = createAsyncThunk(
'updateProfile',
async ({ livestock,crop, id }, { rejectWithValue, dispatch }) => {
  try {
    const res = await axios.put(
      `${apiUrl}sales/farmers/${id}`,
      { 
        livestock: livestock,
        crop: crop,
      }
      ,
      {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
        }, 
      }
    )
    if(res.status === 200){
      cogoToast.success("Farmer updated successfully!")
        return res.data
    }
  } catch (error) {
    if(error.response.status === 401){
      dispatch({type: 'auth/Logout'})
    }
    else{
      return rejectWithValue(error.response)
    }
  }
}
)

export const getFarmersComplaints = createAsyncThunk(
  'farmersComplaint',
  async ({id}, { rejectWithValue, dispatch}) => {
    // const state = getState()
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/farmer/complaint/${id}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getFarmersOrders = createAsyncThunk(
  'farmersOrders',
  async ({id, page, campaignId}, {rejectWithValue, dispatch}) => {
    // const state = getState()
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/farmers/orders/${id}?page=${page}&campaign=${campaignId}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getFarmerAssignment = createAsyncThunk(
  'getFarmerAssignment',
  async ({id}, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/assignment/farmers/${id}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)


export const getFarmersCalls = createAsyncThunk(
  'farmersCalls',
  async ({id, page,campaignId}, {rejectWithValue, dispatch}) => {
    // let assignedFarmers = getState().home.assignedFarmers
    // let assignmentID = assignedFarmers.find((val) => val.farmer.id === parseInt(id)).id
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/farmers/call-log/${id}?page=${page}&campaign=${campaignId}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getFarmersCart = createAsyncThunk(
  'farmersCart',
  async ({id, campaignId}, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/farmers/cart/${id}?campaign=${campaignId}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const UpdateFarmersCart = createAsyncThunk(
  'updateFarmersCart',
  async ({ id, quantity, campaignId }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}sales/cart_items/${id}`,
        { 
          quantity: quantity,
          campaign: campaignId
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return {data:res.message, id, quantity}
      }
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)


export const deleteFarmersCart = createAsyncThunk(
  'deleteFarmersCart',
  async ({ id, campaignId }, { rejectWithValue, dispatch }) => {
  

    try {
      const res = await axios.delete(
        `${apiUrl}sales/cart_items/${id}?campaign=${campaignId}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          cogoToast.success("Product has been deleted from cart!")
          return {data:res.message, id}
      }
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const createOrder = createAsyncThunk(
  'createOrder',
  async ({ resp }, { rejectWithValue, getState, dispatch }) => {
      const cartItems  = getState().home.cart
      const userId  = getState().auth.userInfo.sales_agent_id

    try {
      const res = await axios.post(
        `${apiUrl}sales/cart/checkout`,
        { 
          total_price: resp.totalPrice,
          farmer_id: resp.farmerId,
          state_id: resp.stateID,
          lga_id: resp.lgaID,
          address: resp.deliveryAddress,
          cart_items: cartItems,
          campaign: resp.campaignId
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 201){
        cogoToast.success("Order created successfully")
        let result;
          let code = res.data.short_code
          let productItems = getState().home.cart
          .map(value => {
            return value.product_name;
          })
          .join(', ');

          Mixpanel.identify(userId)
          Mixpanel.track('Order Created',{
            "Short Code": code,
            "Farmer PhoneNumber": resp.farmerPhonenumber,
            "Product Items":  productItems
          })

          // api call to send sms to the farmer
          result = await axios.post(
            `${apiUrl}sales/send-sms`,
            {
              // phone_number: resp.farmerPhonenumber,
              phone_number: "+2348136465992", 
              code,
              items: productItems
            },
            {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${getToken()}`
              }, 
            }
          )

          return {
                  data: res,
                  sms: result
                }
          
      }
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getAssignedFollowUps = createAsyncThunk(
  'AssignedFollowUps',
  async ({page, campaignId}, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/follow-up/farmers?page=${page}&campaign=${campaignId}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

