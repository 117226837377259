import React from 'react'
// import logo from '../../assets/logo.svg'
import logo1 from '../../assets/Black.svg'
import message from '../../assets/message.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearResetSuccess } from '../../redux/Auth/auth'

const ResetSuccessPage = () => {
    
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const goLogin = () =>{
        navigate('/')
        dispatch(clearResetSuccess())
    }

  return (
    <>
      <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[450px] h-[450px] rounded-full blur-[150px]"></div>

        <div className="flex justify-between">
          <div>
          <img src={logo1} alt="logo" className="md:w-[129px] md:h-[40px]" />
          </div>
          <div className="z-10">
          
          </div>
        </div>

        {/* forgot section divs */}
        <div className="md:max-w-lg lg:max-w-md mx-auto md:py-32 py-20">
         
          <div className='flex justify-center'>
            <div className='bg-[#DBFFDF] w-[60px] h-[60px] rounded-full flex items-center justify-center'>
                <img src={message} alt="info" />
            </div>
        </div>

          <div className='mt-4'>
            <h6 className="text-primary font-semibold text-xl text-center">
                    Your Password Has Been Reset
            </h6>
            <p className="text-secondary1 text-center font-medium text-sm mt-1">
            Your password has been successufully reset, you can access your account by clicking the button
            </p>
          </div>

            <div className="mt-8">
                  <button
                    onClick={goLogin}
                    type="submit"
                    className="bg-primary rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                    Login
                  </button>
             </div>
        
        </div>
      </div>
    </>
  )
}

export default ResetSuccessPage
