import React,{useState} from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { useSelector } from 'react-redux';
import chevron_down from '../../assets/chevron-down.svg'
import chevron_up from '../../assets/chevron-up.svg'
import logout_icon from '../../assets/logout1.svg'
import { Logout } from '../../redux/Auth/auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const SideWrapper = ({children}) => {

    const dispatch = useDispatch()
    const {userInfo} = useSelector((state) => state.auth);
    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)
    const toggleLogout = () =>{
        setIsOpen(!isOpen)
    }

    const handleLogout = () =>{
        dispatch(Logout())
        navigate('/')
      }

    return (  
        <>
        <div className="block lg:flex bg-[#f5f5f5]">
            <div className="w-full lg:w-[256px] lg:bg-[#2B8C34] text-white h-full lg:h-full lg:min-h-screen">
            <Sidebar />
            </div>
            <div className="px-[24px] w-full lg:h-screen overflow-y-scroll">
            {/* USER DETAIL FOR MOBILE SCREEN */}
            <div className="flex items-end justify-end mt-[15px]">
                <div className='relative flex gap-3 items-center'>
                    <div className="hidden lg:flex items-center justify-center rounded-full cursor-pointer bg-[#2B8C34] w-[40px] h-[40px]">
                    <p className="text-center font-medium text-sm text-white">{userInfo? userInfo.first_name.charAt(0).toUpperCase(): "S"}{userInfo? userInfo.last_name.charAt(0).toUpperCase(): "A"}</p>
                    </div>
                    <div onClick={toggleLogout} className='flex items-center gap-3 cursor-pointer'>
                           <p className='text-black font-semibold text-sm'>  {userInfo? userInfo.first_name: "Sales"} {userInfo? userInfo.last_name : "Agent"}</p>
                           <img  className='cursor-pointer' src={
                                   isOpen ? chevron_up : chevron_down} alt="chevron" />
                    </div>

                    <div className={
                            isOpen ?
                            'absolute top-12 w-full left-0 right-0' :
                            'hidden absolute top-14 w-full left-0'
                                }>
                            <button onClick={handleLogout} className='w-full py-4 flex items-center justify-center px-4 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all'>
                                <img src={logout_icon} alt="logout_icon" />
                                <p className='text-white font-medium text-sm ml-2'>Log Out</p>
                            </button>
                        </div>


                </div>
              
            
            </div>
            <div className="">
                {children}
            </div>
         </div>
        </div>
        </>
    );
}
 
export default SideWrapper;