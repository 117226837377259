import React from 'react'
import close from '../../assets/Buttons.svg'
import notificationsIcon from '../../assets/Avatar (1).svg'

const Notifications = ({ closeNotification }) => {
  return (
    <div>
      {' '}
      <div className="relative">
        <div
          className="absolute w-[384px] max-h-[510px] overflow-x-none overflow-y-scroll z-10 mt-[4px] right-0 notification_scroll_div"
          style={{ overflowX: 'hidden' }}
        >
          <div
            className="w-[384px] h-full  mt-[4px] right-0 bg-white rounded-[8px] p-[24px]"
            style={{
              boxShadow:
                ' 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08)',
            }}
          >
            <div className="flex justify-between items-center">
              <p className="font-[600] text-[16px] text-[#5C715E] leading-5">
                Notifications
              </p>
              <img
                src={close}
                alt="close"
                className="cursor-pointer"
                onClick={closeNotification}
              />
            </div>
            {/* <div className="flex justify-between my-[20px] cursor-pointer">
              <div className="flex gap-[12px]">
                <p className="font-[600] text-sm leading-[18px] text-[#96A397] hover:text-[#2B8C34]">
                  New
                </p>
                <p className="font-[600] text-sm leading-[18px] text-[#96A397] hover:text-[#2B8C34]">
                  Cleared
                </p>
              </div>
              <p className="font-[600] text-sm leading-[18px] text-[#2B8C34]">
                Clear All
              </p>
            </div> */}
            {/* TODAY */}
            <div>
              <div className="flex justify-between mt-[20px]">
                <p className="text-[#7C8D7E] font-[600] text-[12px] leading-4 mb-[12px]">
                  TODAY
                </p>
                <p className="text-[#2B8C34] text-[14px] font-[500] text-sm leading-[18px] cursor-pointer">
                  Mark all as read
                </p>
              </div>
              {/* ONE */}
              <div>
                <div className="flex gap-[12px] items-center mb-[24px]">
                  <div className="h-[66px]">
                    <img
                      src={notificationsIcon}
                      alt="icon"
                      className="h-[32px] w-[32px]"
                    />
                  </div>

                  <div className="h-[66px]">
                    <p className="font-[600] text-[#5C715E] text-xs pb-[4px] leading-4">
                      November Herbicide Sales
                    </p>
                    <p className="font-[500] text-[#5C715E] text-sm leading-5">
                      You have 5 follow calls due today
                    </p>
                    <p className="pt-[4px] font-[500] text-[#7C8D7E] text-xs leading-4">
                      12:23 PM
                    </p>
                  </div>
                </div>
              </div>

              {/* TWO */}
              <div>
                <div className="flex gap-[12px] items-center  mb-[24px]">
                  <div className="h-[66px]">
                    <img
                      src={notificationsIcon}
                      alt="icon"
                      className="h-[32px] w-[32px]"
                    />
                  </div>

                  <div className="h-[66px]">
                    <p className="font-[600] text-[#5C715E] text-xs pb-[4px] leading-4">
                      November Herbicide Sales
                    </p>
                    <p className="font-[500] text-[#5C715E] text-sm leading-5">
                      You have 5 follow calls due today
                    </p>
                    <p className="pt-[4px] font-[500] text-[#7C8D7E] text-xs leading-4">
                      12:23 PM
                    </p>
                  </div>
                </div>
              </div>

              {/* THREE */}
              <div>
                <div className="flex gap-[12px] items-center  mb-[24px]">
                  <div className="h-[66px]">
                    <img
                      src={notificationsIcon}
                      alt="icon"
                      className="h-[32px] w-[32px]"
                    />
                  </div>

                  <div className="h-[66px]">
                    <p className="font-[600] text-[#5C715E] text-xs pb-[4px] leading-4">
                      November Herbicide Sales
                    </p>
                    <p className="font-[500] text-[#5C715E] text-sm leading-5">
                      You have 5 follow calls due today
                    </p>
                    <p className="pt-[4px] font-[500] text-[#7C8D7E] text-xs leading-4">
                      12:23 PM
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-[16px]" />
            {/* YESTERDAY */}
            <div>
              <p className="text-[#7C8D7E] font-[600] text-[12px] leading-4 mb-[12px]">
                YESTERDAY
              </p>
              {/* ONE */}
              <div>
                <div className="flex gap-[12px] items-center  mb-[24px]">
                  <div className="h-[66px]">
                    <img
                      src={notificationsIcon}
                      alt="icon"
                      className="h-[45px] w-[45px]"
                    />
                  </div>

                  <div className="h-[66px]">
                    <p className="font-[600] text-[#5C715E] text-xs pb-[4px] leading-4">
                      December Discount
                    </p>
                    <p className="font-[500] text-[#5C715E] text-sm leading-5">
                      Solocad has accepted the order for Slasher 3000 from Adamu
                      Adamu
                    </p>
                    <p className="pt-[4px] font-[500] text-[#7C8D7E] text-xs leading-4">
                      12:23 PM
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifications
