import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
import cogoToast from "cogo-toast";
// import {store} from '../../redux/store'
import {Mixpanel} from '../../Components/Helpers/MixPanel'


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};



export const atlasSearch = createAsyncThunk(
  'atlasSearch',
  async ({val}, {rejectWithValue, dispatch, getState}) => {
     const stateId = getState().home.farmer.state_id
     const userId  = getState().auth.userInfo.sales_agent_id
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/atlas-search?product=${val}&state_id=${stateId}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){

        Mixpanel.identify(userId)
        Mixpanel.track('Search Products',{
          "Search Value": val
        })
        return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const addComplaint = createAsyncThunk(
    'addComplaint',
    async ({ farmer_id,complaint }, { rejectWithValue, getState, dispatch }) => {
        const userId  = getState().auth.userInfo.id
        const salesAgentId  = getState().auth.userInfo.sales_agent_id

      try {
        const res = await axios.post(
          `${apiUrl}sales/complaint`,
          { 
            farmer_id: farmer_id,
            complaint: complaint,
            complaint_by_id: userId
          },
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 201){
          Mixpanel.identify(salesAgentId)
          Mixpanel.track('Complaint',{
            "complaint": complaint
          })
          cogoToast.success("Complaint sent successfully")
            return res.data
        }
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
)

export const getNoteHistory = createAsyncThunk(
  'getNoteHistory',
  async ({id}, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/farmers/notes/${id}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)


export const addNotes = createAsyncThunk(
  'addNotes',
  async ({ notes }, { rejectWithValue, getState, dispatch }) => {
      const assignmentID  = getState().call.assignmentId
    try {
      const res = await axios.post(
        `${apiUrl}sales/farmers/notes`,
        { 
          note: notes,
          sales_assignment_log_id: assignmentID
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 201){
         cogoToast.success("Notes added successfully")
          return res.data
      }
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getCartItems = createAsyncThunk(
  'cartItems',
  async ({id, campaignId}, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/farmers/cart/${id}?campaign=${campaignId}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const addItemsToCart = createAsyncThunk(
  'addItemsToCart',
  async ({ result }, { rejectWithValue, dispatch, getState }) => {
    let cart = []
    let cartItem;
    const userId  = getState().auth.userInfo.sales_agent_id
    let type = result.productType ? result.productType.toUpperCase(): ""
    
    if(type === "CROP" || type === "crop"){
      cartItem = [
        {
          ...cart,
          crop_input_id: result.productId,
          quantity: result.quantity,
          item_type: type,
          total_item_price: result.productPrice,
          vendor_id: result.vendorId,
          vendor_name: result.vendorName
        }
      ]
    }
    if(type === "ADVISORY" || type === "advisory"){
      cartItem = [
        {
          ...cart,
          advisory_product_id: result.productId,
          quantity: result.quantity,
          item_type: type,
          total_item_price: result.productPrice,
          vendor_id: result.vendorId,
          vendor_name: result.vendorName
        }
      ]
    }
    if(type === "LIVESTOCK" || type === "livestock"){
      cartItem = [
        {
          ...cart,
          livestock_product_id: result.productId,
          quantity: result.quantity,
          item_type: type,
          total_item_price: result.productPrice,
          vendor_id: result.vendorId,
          vendor_name: result.vendorName
        }
      ]
    }
    if(type === "MECHANIZATION" || type === "mechanization"){
      cartItem = [
        {
          ...cart,
          mechanization_product_id: result.productId,
          quantity: result.quantity,
          item_type: type,
          total_item_price: result.productPrice,
          vendor_id: result.vendorId,
          vendor_name: result.vendorName
        }
      ]
    }
    if(type === "INSURANCE" || type === "insurance"){
      cartItem = [
        {
          ...cart,
          insurance_product_id: result.productId,
          quantity: result.quantity,
          item_type: type,
          total_item_price: result.productPrice,
          vendor_id: result.vendorId,
          vendor_name: result.vendorName
        }
      ]
    }

    if(type === ""){
      cartItem = []
    }


    try {
      const res = await axios.post(
        `${apiUrl}sales/cart/save`,
        { 
          farmer_id: result.farmerId,
          total_price: result.productPrice,
          cart_items: cartItem,
          checkout : false,
          campaign: result.campaignId
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 201){
            Mixpanel.identify(userId)
            Mixpanel.track('Add To Cart',{
              "Total Price": result.productPrice,
              "Campaign Id": result.campaignId,
              "item_type": type,
              "Cart Item": cartItem,
              "Farmer ID": result.farmerId
             })
          cogoToast.success("Product has been added to cart. Check the cart to complete the order!")
          return res.data
      }
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)


export const UpdateCartQantity = createAsyncThunk(
  'updateItemsInCart',
  async ({ id, quantity, campaignId }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}sales/cart_items/${id}`,
        { 
          quantity: quantity,
          campaign: campaignId
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return {data:res.data, id, quantity}
      }
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const deleteCartItems = createAsyncThunk(
  'deleteCartItems',
  async ({ id, campaignId }, { rejectWithValue, dispatch }) => {
  

    try {
      const res = await axios.delete(
        `${apiUrl}sales/cart_items/${id}?campaign=${campaignId}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          cogoToast.success("Product has been deleted from cart!")
          return  {data:res.data, id}
      }
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const createCallsOrder = createAsyncThunk(
  'createcallsOrder',
  async ({ resp }, { rejectWithValue, getState, dispatch }) => {
      const cartItems  = getState().call.cart
      const userId  = getState().auth.userInfo.sales_agent_id

    try {
      const res = await axios.post(
        `${apiUrl}sales/cart/checkout`,
        { 
          total_price: resp.totalPrice,
          farmer_id: resp.farmerId,
          state_id: resp.stateID,
          lga_id: resp.lgaID,
          address: resp.deliveryAddress,
          cart_items: cartItems,
          campaign: resp.campaignId
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 201){
          cogoToast.success("Order created successfully")
          let result;
          let code = res.data.short_code
          let productItems = getState().call.cart
          .map(value => {
            return value.product_name;
          })
          .join(', ');

          Mixpanel.identify(userId)
          Mixpanel.track('Order Created',{
            "Short Code": code,
            "Farmer PhoneNumber": resp.farmerPhonenumber,
            "Product Items":  productItems
          })


          // api call to send sms to the farmer
          result = await axios.post(
            `${apiUrl}sales/send-sms`,
            {
              // phone_number: resp.farmerPhonenumber,
              phone_number: "+2348136465992", 
              code,
              items: productItems
            },
            {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${getToken()}`
              }, 
            }
          )

          return {
                  data: res,
                  sms: result
            }
          
      }
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getFarmersAssignment = createAsyncThunk(
  'getFarmersAssignment',
  async ({id}, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/assignment/farmers/${id}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const updateAssignment = createAsyncThunk(
  'updateAssignment',
  async ({ callStatus, followUpDate, callDuration, note, campaignId }, { rejectWithValue, getState, dispatch }) => {
      const orderPlaced  = getState().call.orderPlaced
      const assignmentID  = getState().call.assignmentId
      const followUpStatus  = getState().call.was_follow_up
      const userId  = getState().auth.userInfo.sales_agent_id

    try {
      const res = await axios.put(
        `${apiUrl}sales/farmers/assignment/${assignmentID}`,
        { 
          call_status: callStatus,
          call_date: new Date(),
          order_placed: orderPlaced,
          call_duration: callDuration,
          follow_up_date: followUpDate === "" ? null :  new Date(followUpDate).toISOString(),
          note: note,
          campaign: campaignId,
          was_follow_up: followUpStatus
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 201){
        Mixpanel.identify(userId)
        Mixpanel.track('End Call',{
          "Call Status": callStatus,
          "Note": note,
          "Call Duration":  callDuration,
          "Order Placed": orderPlaced
        })

        cogoToast.success("Call information recorded successfully!")
          return res.data
      }
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)
