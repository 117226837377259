import { combineReducers } from '@reduxjs/toolkit'

import authReducer from './Auth/auth'
import homeReducer from './Home/home'
import callReducer from './Calls/calls'
import orderReducer from './Orders/orders'
import campaignReducer from './Campaign/campaign'


const appReducer = combineReducers({
    auth: authReducer,
    home: homeReducer,
    call: callReducer,
    order: orderReducer,
    campaign: campaignReducer
})

const rootReducer = (state, action) => {
    if (action.type === 'auth/Logout') {
      state = undefined;
    }
    return appReducer(state, action);
  };

export default rootReducer

