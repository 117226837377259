import React, { useEffect, useState, useMemo, useCallback } from 'react'
import Breadcrumb from '../../Components/Breadcrumbs/Breadcrumb'
import arrow_down from '../../assets/arrow-down.svg'
import checkbox from '../../assets/check-box.svg'
import PaginationComponent from '../../Components/Pagination/Pagination'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAssignedFarmers, getAssignedInfo } from '../../redux/Home/homeActions'
// import warning_state from '../../assets/warning_state.svg'
import moment from 'moment'
import search_icon from '../../assets/search.svg'
import {debounce} from 'lodash'
import { searchAssignedFarmers } from '../../redux/Home/homeActions'
import { findCampaign } from '../../redux/Campaign/campaign'
import { updateFollowUpStatus, updateFarmersAssignment } from '../../redux/Calls/calls'


const Home = () => {
  const { assignedFarmers, loading, assignedTotalCount, assignedInfo } = useSelector(
    (state) => state.home,
  )

  const {id} = useParams()
    const navigate = useNavigate()
  const { campaigns  } = useSelector((state) => state.campaign)

  let campaign = campaigns.find((val) => val.id === id)

  const dispatch = useDispatch()

  const capitalizeFirstLetter = (str) => {
    let val = str.toLowerCase()
    let words = val.split(" ");
   for (let i = 0; i < words.length; i++) {
       words[i] = words[i][0].toUpperCase() +  words[i].substr(1);
   }
    return words.join(" ");
  }

  const [breadcrumb] = useState([
    {
      text: 'All Campaigns',
      href: '/mycampaigns',
      disabled: true,
    },
    {
      text: `${campaign ? campaign.campaign_name: ''}`,
      href: '/',
      disabled: false,
    },
  ])

  const [callStatus] = useState([
    {
      id: 1,
      text: 'All',
      tag: 'all',
    },
    {
      id: 2,
      text: 'Not Called',
      tag: 'NOT_CALLED',
    },
    {
      id: 3,
      text: 'Called',
      tag: 'CALLED',
    },
    {
      id: 4,
      text: 'Missed',
      tag: 'MISSED',
    },
    {
      id: 5,
      text: 'Incomplete',
      tag: 'INCOMPLETE',
    },
    {
      id: 6,
      text: 'Follow Up',
      tag: 'FOLLOW_UP',
    },
  ])

  const [isCallOpen, setIsCallOpen] = useState(false)
  const [callTitle, setCallTitle] = useState('All')
  const [callTag, setCallTag] = useState('all')
  const [callId, setCallID] = useState(1)

  const handleCallStatus = (val) => {
    const { id, text, tag } = val
    setCallTitle(text)
    setCallID(id)
    setIsCallOpen(!isCallOpen)
    setCallTag(tag)
    console.log(tag)
    dispatch(
      getAssignedFarmers({
        callStatus: tag,
        page: 1,
        id: campaign.id
      }),
    )
  }

  const handleCallOpen = () => {
    setIsCallOpen(!isCallOpen)
  }

  const [currentPage, setCurrentPage] = useState(1)

  const onPageChange = (val) => {
    const { page } = val
    setCurrentPage(page)
    dispatch(
      getAssignedFarmers({
        callStatus: callTag,
        page: page,
        id: id
      }),
    )
  }

  useEffect(() => {
    dispatch(
      getAssignedFarmers({
        callStatus: 'all',
        page: 1,
        id: id
      }),
    )
    dispatch(getAssignedInfo({
      id
    }))
    dispatch(findCampaign(id))
  }, [dispatch, id])

  const getCallBg = (val) => {
    let result
    switch (val) {
      case 'INCOMPLETE':
        result = 'missed'
        break
      case 'NOT CALLED':
        result = 'notcalled'
        break;
      case 'FOLLOW UP':
        result = 'pending'
        break
      case 'MISSED':
        result = 'missed'
        break;
      case 'CALLED':
        result = 'called'
        break
      default:
        result = 'notcalled'
        break
    }
    return result
  }

  const getCallText = (val) => {
    let result
    switch (val) {
      case 'INCOMPLETE':
        result = 'missedText'
        break;
      case 'NOT CALLED':
        result = 'notcalledText'
        break;
        case 'MISSED':
          result = 'missedText'
          break;
      case 'FOLLOW UP':
        result = 'pendingText'
        break
      case 'CALLED':
        result = 'calledText'
        break
      default:
        result = 'notcalledText'
        break
    }
    return result
  }

  const [searchValue, setSearchValue] = useState("")

   // handle search
   const handleSearch = useMemo(()=>
   debounce((val)=>{
    //  api to dispatch and make the actions for search
      dispatch(searchAssignedFarmers(val))
   },750),
  [dispatch]
 )

  const handleChange = useCallback((e) =>{
    let val = e.target.value
    setSearchValue(val)
    handleSearch({
      campaignId: id,
      val: val
    })
  },[handleSearch, id])

  const viewProfile = (farmerId, status, id) =>{
      dispatch(updateFollowUpStatus(status))
      dispatch(updateFarmersAssignment(id))
      navigate(`/farmer/${farmerId}`)
  }



  return (
    <>
      <div className="mt-3 pb-10">
        <Breadcrumb breadcrumb={breadcrumb} />
          <div>
            <div className="bg-white px-6 py-8 rounded-md mt-5">
              <h4 className="text-tertiary font-semibold text-lg">
                {campaign ? campaign.campaign_name: ''}
              </h4>
              <p className="text-[#7C8D7E] font-medium mt-1">
              {campaign ? campaign.description : ''}
                 </p>
            </div>

            <div className="mt-5 flex gap-5 md:flex-row flex-col">
              <div
                className="bg-white rounded-lg px-4 py-6 border-l-4 border-l-[#B92043] w-full md:w-[250px]"
              >
                <p className="text-secondary2 text-sm font-semibold">
                  Assigned Follow Up Calls
                </p>
                <h4 className="text-secondary font-semibold text-2xl mt-3">
                  {assignedInfo && assignedInfo.total_assigned_follow_up ? assignedInfo.total_assigned_follow_up : 0}
                </h4>
                <div className='mt-2 flex justify-end'>
                  <Link to="/followup-calls" className='text-[#2B8C34] text-xs font-semibold'>See More</Link>
                </div>
              </div>

              <div to="/orders" className="bg-white rounded-lg px-4 py-6 border-l-4 border-l-[#2B8C34] w-full md:w-[250px]">
                <p className="text-secondary2 text-sm font-semibold">
                  Total Orders
                </p>
                <h4 className="text-secondary font-semibold text-2xl mt-3">
                  {assignedInfo && assignedInfo.total_orders ? assignedInfo.total_orders: 0}
                </h4>
                <div className='mt-2 flex justify-end'>
                  <Link to="/orders" className='text-[#2B8C34] text-xs font-semibold'>See More</Link>
                </div>
              </div>
              <div className="bg-white rounded-lg flex">
                <div className="flex flex-col px-4 py-6 justify-center border-r-[#CED5CF] border-r">
                  <p className="text-secondary2 text-sm font-semibold">
                    Your Progress
                  </p>
                </div>
                <div className="py-6 px-6 flex gap-10">
                  <div>
                    <h6 className="text-secondary2 text-sm font-semibold">
                      Calls Made
                    </h6>
                    <h4 className="text-secondary font-semibold text-2xl mt-3">
                    {assignedInfo && assignedInfo.calls_made ? assignedInfo.calls_made : 0}/{assignedInfo && assignedInfo.total_calls ? assignedInfo.total_calls : 0}
                    </h4>
                  </div>
                  <div>
                    <h6 className="text-secondary2 text-sm font-semibold">
                      Follow Up Calls Made
                    </h6>
                    <h4 className="text-secondary font-semibold text-2xl mt-3">
                          {assignedInfo && assignedInfo.follow_up_calls_made ? assignedInfo.follow_up_calls_made : 0}
                    </h4>
                  </div>
                  <div>
                    <h6 className="text-secondary2 text-sm font-semibold">
                      Sales Made
                    </h6>
                    <h4 className="text-secondary font-semibold text-2xl mt-3">
                    {assignedInfo ? assignedInfo.sales_made: 0}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 bg-white rounded-lg px-6 py-6">
              <h4 className="text-primary font-semibold text-lg">Farmers</h4>

              <div className="mt-5 flex gap-8">
                <div className="flex items-center gap-3">
                  <div>
                    <p className="text-secondary text-sm font-medium">
                      Call Status:
                    </p>
                  </div>
                  <div
                    className="flex gap-3 items-center cursor-pointer relative"
                    onClick={handleCallOpen}
                  >
                    <p className="text-secondary2 text-sm font-medium">
                      {callTitle}
                    </p>
                    <img src={arrow_down} className="w-3 h-3" alt="arrow" />
                    <ul
                      className={
                        isCallOpen
                          ? 'absolute bg-white rounded-md top-6 shadow-bpo w-44 py-3 px-2 z-30'
                          : 'hidden'
                      }
                    >
                      {callStatus.map((val) => (
                        <>
                          <li
                            key={val.id}
                            onClick={() => handleCallStatus(val)}
                            className="px-6 py-2 rounded-md hover:bg-[#EDF7EE]"
                          >
                            <div className="flex gap-3">
                              {callId === val.id && (
                                <img src={checkbox} alt="checkbox" />
                              )}
                              <p className="text-secondary text-sm font-normal">
                                {val.text}
                              </p>
                            </div>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </div>
             
              </div>

              <div className='mt-5'>
                    <div className="relative">
                      <input type="text"
                           onChange={handleChange}   
                           value={searchValue}
                          className="appearance-none w-full placeholder:text-[#96A397] min-w-[400px] 
                          placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] 
                          focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-10"
                          placeholder="Search by farmer, phone number" />
                           <img
                            src={search_icon}
                            alt="search"
                            className="absolute top-1/2 transfrom -translate-y-1/2 left-4 cursor-pointer"
                          />
                      </div>
                </div>
            </div>

            {/* tables */}
            <div className="overflow-x-auto relative mt-2 sm:rounded-tr-lg sm:rounded-tl-lg">
              <table className="w-full text-left">
                <thead className="text-primary bg-white text-sm border-b-[#F7F7F7] border-b-2">
                  <tr>
                    <th scope="col" className="py-43 px-6 font-semibold">
                      Name
                    </th>
                    <th scope="col" className="py-4 px-6 font-semibold">
                      Phone Number
                    </th>
                    <th scope="col" className="py-4 px-6 font-semibold">
                      Date Assigned
                    </th>
                    <th scope="col" className="py-4 px-6 font-semibold">
                      Call Status
                    </th>
                    {/* <th scope="col" className="py-4 px-6 font-semibold">
                      Sales Status
                    </th> */}
                    <th scope="col" className="py-4 px-6 font-semibold">
                      Action
                    </th>
                  </tr>
                </thead>

                {!loading && (
                  <tbody>
                    {assignedFarmers.length > 0
                      ? assignedFarmers.map((item) => (
                          <tr key={item.id} className="bg-white text-sm">
                            <th
                              scope="row"
                              className="py-3 px-6 font-normal text-secondary1 whitespace-nowrap"
                            >
                              {item.farmer ? item.farmer.first_name : ''}{' '}
                              {item.farmer ? item.farmer.last_name : ''}
                            </th>
                            <td className="py-3 px-6 text-secondary1">
                              {item.farmer ? item.farmer.phone_number: ""}
                            </td>
                            <td className="py-3 px-6 text-secondary1">
                              {item.created ? moment(item.created).format('Do, MMM YYYY hh:mm:ss a') : ""}
                            </td>
                            <td className="py-3 px-6">
                              <div className="flex">
                                <div className={getCallBg(item.call_status)}>
                                  <p className={getCallText(item.call_status)}>
                                    {capitalizeFirstLetter(item.call_status)}
                                  </p>
                                </div>
                              </div>
                            </td>
                            {/* <td className="py-4 px-6">
                              <div className="flex">
                                <div className={getSaleBg(item.sales_status)}>
                                  <p className={getSaleText(item.sales_status)}>
                                    {capitalizeFirstLetter(item.sales_status)}
                                  </p>
                                </div>
                              </div>
                            </td> */}
                            <td className="py-4 px-6">
                              <button
                              onClick={() => viewProfile(item.farmer.id, item.call_status, item.id)}
                                className="rounded-lg border-primary border bg-white py-3 px-4 text-primary cursor-pointer hover:bg-[#EDF7EE] hover:text-primary transition-all"
                              >
                                View Profile
                              </button>
                            </td>
                          </tr>
                        ))
                      : ''}
                  </tbody>
                )}
              </table>

              {!loading && assignedFarmers.length === 0 ? (
                <div className="text-center">
                  <p className="text-secondary font-normal mt-12">
                    You have no farmers assigned today!
                  </p>
                </div>
              ) : (
                ''
              )}

              {!loading && assignedFarmers.length > 0 ? (
                <div className="mt-1">
                  <PaginationComponent
                    page={currentPage}
                    onPageChange={onPageChange}
                    pageSize={10}
                    totalItems={assignedTotalCount}
                  />
                </div>
              ) : (
                ''
              )}

              {loading && (
                <div className="animate-pulse w-full mt-4">
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                </div>
              )}
            </div>
          </div>
        
      </div>
    </>
  )
}

export default Home
