import React,{useEffect, useState} from 'react'
import Breadcrumb from '../../Components/Breadcrumbs/Breadcrumb'
import PaginationComponent from '../../Components/Pagination/Pagination'
import { useSelector, useDispatch } from 'react-redux'
import arrow_down from '../../assets/arrow-down.svg'
import checkbox from '../../assets/check-box.svg'
import moment from 'moment'
import DrawerComponent from '../../Components/Drawers/drawers'
import { getOrders } from '../../redux/Orders/orderActions'


const Orders = () => {

  const { userInfo } = useSelector((state) => state.auth)
  const { agentOrders, loader, agentOrderCount } = useSelector((state) => state.order)
  const { campaign } = useSelector((state) => state.campaign)


  const dispatch = useDispatch()

  const [breadcrumb] = useState([
    {
      text: 'All Campaigns',
      href: '/mycampaigns',
      disabled: true,
    },
    {
      text: `${campaign.campaign_name}`,
      href: '/',
      disabled: false,
    },
    {
      text: 'Orders',
      href: '/orders',
      disabled: false,
    }
  ])

  const [currentPage, setCurrentPage] = useState(1)

  const onPageChange = (val) =>{
     const { page } = val
     setCurrentPage(page)
     dispatch(getOrders({
       page: page,
       campaignId: campaign.id
     }))
   }

   const capitalizeFirstLetter = (str) => {
    let val = str.toLowerCase()
    let words = val.split(" ");
   for (let i = 0; i < words.length; i++) {
       words[i] = words[i][0].toUpperCase() +  words[i].substr(1);
   }
    return words.join(" ");
  }

  const getOrderBg = (val) => {
    let result
    switch (val) {
      case 'PENDING':
        result = 'missed'
        break;
      case 'DELIVERED':
        result = 'called'
        break;
      case 'PROCESSING':
        result = 'missed'
        break;
      case 'DISPATCHED':
       result = 'pending'
       break;
      case 'CANCELLED' :
        result= 'notcalled'
        break;
      default:
        result = 'noitem'
        break
    }
    return result
  }


  const getOrderText = (val) =>{
    let result
    switch (val) {
      case 'PENDING':
        result = 'missedText'
        break;
      case 'DELIVERED':
        result = 'calledText'
        break;
      case 'PROCESSING':
        result = 'missedText'
        break;
      case 'DISPATCHED':
       result = 'pendingText'
       break;
      case 'CANCELLED' :
        result= 'notcalledText'
        break;
      default:
        result = 'noitemText'
        break
    }
    return result
  }



  const [paymentStatus] = useState([
    {
      id: 1,
      text: 'All',
      tag: 'all',
    },
    {
      id: 2,
      text: 'Paid',
      tag: 'Paid',
    },
    {
      id: 3,
      text: 'Unpaid',
      tag: 'Unpaid',
    }
  ])

  const [isPayOpen, setIsPayOpen] = useState(false)
  const [payTitle, setPayTitle] = useState('All')
  // const [payTag, setPayTag] = useState('all')
  const [payId, setPayID] = useState(1)

  const handlePayStatus = (val) => {
    const { id, text } = val
    setPayTitle(text)
    setPayID(id)
    setIsPayOpen(!isPayOpen)
    // setPayTag(tag)
  }

  const handlePayOpen = () => {
    setIsPayOpen(!isPayOpen)
  }


const [isDrawerOpen, setIsDrawerOpen] = useState(false)
const [orderInfo, setOrderInfo] = useState({})

const toggleOrder = (id) =>{
  setIsDrawerOpen(!isDrawerOpen)
    // find the order selected
    let order = agentOrders.find((val)=> val.id === id)
    setOrderInfo(order)
}

const closeOrder = () =>{
  setIsDrawerOpen(!isDrawerOpen)
}

  useEffect(()=>{
    dispatch(getOrders({
      page: 1,
      campaignId: campaign.id
    }))
  },[dispatch, campaign])


  return  (
    <>
    <div className='mt-3 pb-10'>
      

    {/* order info drawer */}
    <DrawerComponent title="Order Info" show={isDrawerOpen} showDrawer={closeOrder} >

          <div className='mt-8'>
              <h5 className="text-xs text-secondary1">Order ID</h5>
              <p className='font-medium text-secondary1 text-sm mt-1'>{orderInfo && orderInfo.id ? orderInfo.id: 'N/A' }</p>
            </div>

            <div className='mt-8'>
              <h5 className="text-xs text-secondary1">USSD Short Code</h5>
              <p className='font-medium text-secondary1 text-sm mt-1'>{orderInfo && orderInfo.short_code ? `*347*46*${orderInfo.short_code}#` : '*347*46*4#' }</p>
            </div>

            <div className='mt-8'>
              <h5 className="text-xs text-secondary1">Date</h5>
              <p className='font-medium text-secondary1 text-sm mt-1'>{orderInfo && orderInfo.created ? moment(orderInfo.created).format('Do, MMM YYYY'): "N/A"}</p>
            </div>

            <div className='mt-8'>
              <h5 className="text-xs text-secondary1">Time</h5>
              <p className='font-medium text-secondary1 text-sm mt-1'>{orderInfo && orderInfo.created ? moment(orderInfo.created).format('hh:mm:ss a'): "N/A"}</p>
            </div>

          <div className='mt-8'>
              <h5 className="text-xs text-secondary1">Total Cost</h5>
              <p className='font-medium text-secondary1 text-sm mt-1'>₦{orderInfo && orderInfo.total_display_price ? orderInfo.total_display_price : '0' }</p>
           </div>

           <div className='mt-8'>
              <h5 className="text-xs text-secondary1">Farmer's Name</h5>
              <p className='font-medium text-secondary1 text-sm mt-1'>{orderInfo && orderInfo.farmer ? `${orderInfo.farmer.first_name} ${orderInfo.farmer.last_name}` : 'N/A' }</p>
           </div>

           <div className='mt-8'>
              <h5 className="text-xs text-secondary1">Farmer's Contact</h5>
              <p className='font-medium text-secondary1 text-sm mt-1'>{orderInfo && orderInfo.farmer ? `${orderInfo.farmer.phone_number}` : 'N/A' }</p>
           </div>


          <div className='mt-8'>
            <h5 className="text-xs text-secondary1">Item(s) </h5>
             {/* input orders */}
      {
        orderInfo && orderInfo.input_orders
        ?
        orderInfo.input_orders.length > 0 ?
        orderInfo.input_orders.map((val, index)=>(
          <div key={index} className='flex items-center gap-4 mb-3'>
              <div>
                <p  className='font-medium text-secondary1 text-sm'>{val && val.input_product ?val.input_product.name: 'N/A'} | Qty: {val.quantity} | ₦{val && val.input_product ? val.input_product.display_price: 'N/A'}</p>
              </div>
              <div className='flex'>
                <div className={getOrderBg(val && val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                >
                    <p className={getOrderText( val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                      >{ val.order_status ? capitalizeFirstLetter(val.order_status[val.order_status.length - 1]) : 'PENDING'}</p> 
                  </div>
              </div>
          </div>
        ))
        :
        ""
        :
        ""
      }
      {/* advisory orders */}
      {
        orderInfo && orderInfo.advisory_order
        ?
        orderInfo.advisory_order.length > 0 ?
        orderInfo.advisory_order.map((val, index)=>(
          <div key={index} className='flex items-center gap-4 mb-3'>
              <div>
                <p className='font-medium text-secondary1 text-sm'>{val.advisory_product.name} | Qty: {val.quantity} | ₦{val.advisory_product.display_price}</p>
              </div>
              <div className='flex'>
                <div className={getOrderBg(val && val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                >
                    <p className={getOrderText( val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                      >{ val.order_status ? capitalizeFirstLetter(val.order_status[val.order_status.length - 1]) : 'PENDING'}</p> 
                  </div>
              </div>
           </div>
        ))
        :
        ""
        :
        ""
      }

      {/* mechanization order */}
      {
        orderInfo && orderInfo.mechanization_order
        ?
        orderInfo.mechanization_order.length > 0 ?
        orderInfo.mechanization_order.map((val, index)=>(
          <div key={index} className='flex items-center gap-4 mb-3'>
              <div>
               <p  className='font-medium text-secondary1 text-sm '>{val.mechanization_product.name} | Qty: {val.quantity} | ₦{val.mechanization_product.display_price}</p>
              </div>
              <div className='flex'>
                <div className={getOrderBg(val && val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                >
                    <p className={getOrderText( val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                      >{ val.order_status ? capitalizeFirstLetter(val.order_status[val.order_status.length - 1]) : 'PENDING'}</p> 
                  </div>
              </div>
          </div>
        ))
        :
        ""
        :
        ""
      }

      {/* insurance orders */}
      {
        orderInfo && orderInfo.insurance_order
        ?
        orderInfo.insurance_order.length > 0 ?
        orderInfo.insurance_order.map((val, index)=>(
          <div key={index} className='flex items-center gap-4 mb-3'>
              <div>
                <p  className='font-medium text-secondary1 text-sm'>{val.insurance_product.name} | Qty: {val.quantity} | ₦{val.insurance_product.display_price}</p>
              </div>
              <div className='flex'>
                <div className={getOrderBg(val && val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                >
                    <p className={getOrderText( val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                      >{ val.order_status ? capitalizeFirstLetter(val.order_status[val.order_status.length - 1]) : 'PENDING'}</p> 
                  </div>
              </div>
          </div>
        ))
        :
        ""
        :
        ""
      }

      {/* livestock orders */}
      {
        orderInfo && orderInfo.livestock_order
        ?
        orderInfo.livestock_order.length > 0 ?
        orderInfo.livestock_order.map((val, index)=>(
          <div className='flex items-center gap-4 mb-3'>
            <div>
                <p key={index} className='font-medium text-secondary1 text-sm'>{val.input_product_name} | Qty: {val.quantity} | ₦{val.total_display_price}</p>
           </div>
           <div className='flex'>
                <div className={getOrderBg(val && val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                >
                    <p className={getOrderText( val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                      >{ val.order_status ? capitalizeFirstLetter(val.order_status[val.order_status.length - 1]) : 'PENDING'}</p> 
                  </div>
              </div>
          </div>

        ))
        :
        ""
        :
        ""
      }
          </div>

          <div className='mt-6'>
            <h5 className="text-xs text-secondary1">Payment Status</h5>
                <div className='flex mt-2'>
                <div className=
                    {
                      orderInfo && orderInfo.payment_status ? orderInfo.payment_status ?
                      'called'
                      :
                      'notcalled'
                      :
                      'notcalled'
                    }
                    >
                    <p className=
                      {
                        orderInfo && orderInfo.payment_status ? orderInfo.payment_status ?
                        'calledText'
                        :
                        'notcalledText'
                        :
                        'notcalledText'
                      }
                      >{orderInfo && orderInfo.payment_status ? orderInfo.payment_status ? 'Paid': 'Unpaid': 'Unpaid'}</p> 
                  </div>
                </div>
          </div>

         
    </DrawerComponent>

          <Breadcrumb breadcrumb={breadcrumb} />

          <div className="bg-white px-6 py-8 rounded-md mt-5">
              <h4 className="text-tertiary font-semibold text-lg">
                Hello{' '}
                {userInfo
                  ? capitalizeFirstLetter(userInfo.first_name)
                  : 'There'}
                ,
              </h4>
              <p className="text-secondary font-normal mt-1">
              You have been able to create {' '}
                <span className="font-semibold">{agentOrderCount} Order(s)</span>
              </p>
            </div>

          
          

          <div className='mt-8 bg-white rounded-lg px-6 py-4'>
            <h4 className='text-primary font-semibold text-lg'>Orders</h4>
            <div className="mt-5 flex gap-8">
               
                <div className="flex items-center gap-3">
                  <div>
                    <p className="text-secondary text-sm font-medium">
                      Payment Status:
                    </p>
                  </div>
                  <div
                    className="flex gap-3 items-center cursor-pointer relative"
                    onClick={handlePayOpen}
                  >
                    <p className="text-secondary2 text-sm font-medium">
                      {payTitle}
                    </p>
                    <img src={arrow_down} className="w-3 h-3" alt="arrow" />
                    <ul
                      className={
                        isPayOpen
                          ? 'absolute bg-white rounded-md top-6 shadow-bpo w-44 py-3 px-2 z-30'
                          : 'hidden'
                      }
                    >
                      {paymentStatus.map((val) => (
                        <>
                          <li
                            key={val.id}
                            onClick={() => handlePayStatus(val)}
                            className="px-6 py-2 rounded-md hover:bg-[#EDF7EE]"
                          >
                            <div className="flex gap-3">
                              {payId === val.id && (
                                <img src={checkbox} alt="checkbox" />
                              )}
                              <p className="text-secondary text-sm font-normal">
                                {val.text}
                              </p>
                            </div>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
          </div>

           {/* tables */}
           <div className="overflow-x-auto relative mt-2 sm:rounded-tr-lg sm:rounded-tl-lg">
             <table className="w-full text-left">
              
                 <thead className="text-primary bg-white text-sm border-b-[#F7F7F7] border-b-2">
                     <tr>
                         <th scope="col" className="py-4 px-6 font-semibold ">
                          Order ID 
                         </th>
                         
                         <th scope="col" className="py-4 px-6 font-semibold">
                           Cost
                         </th>
                         <th scope="col" className="py-4 px-6 font-semibold">
                          Date
                         </th>
                         <th scope="col" className="py-4 px-6 font-semibold">
                          Time
                         </th> 
                         <th scope="col" className="py-4 px-6 font-semibold">
                         USSD Short Code
                         </th>   
                         <th scope="col" className="py-4 px-6 font-semibold">
                           Payment Status
                         </th>
                       
                     </tr>
                 </thead>
           
                    {!loader && (
                      <tbody>
                        {agentOrders.length > 0
                          ? agentOrders.map((item) => (
                            <tr key={item.id} className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]" onClick={() => toggleOrder(item.id)}>
                            <th scope="row" className="py-4 px-6 font-normal text-secondary1 whitespace-nowrap ">
                                {item.id}
                            </th>
                            
                            <td className="py-4 px-6 text-secondary1">
                                ₦{item.total_display_price}
                            </td>
                            <td className="py-4 px-6 text-secondary1">
                              {moment(item.created).format('Do, MMM YYYY')}
                            </td>
                            <td className="py-4 px-6 text-secondary1">
                              {moment(item.created).format('hh:mm:ss a')}
                            </td>  
                            <td className="py-4 px-6 text-secondary1">
                            {`*347*46*${item.short_code}#`}
                            </td>   
                            <td className="py-4 px-6">
                            <div className='flex'>
                                <div className={
                                  item.payment_status ?
                                  'called'
                                  :
                                  'notcalled'
                                }
                                >
                                  <p className={
                                    item.payment_status ?
                                    'calledText'
                                    :
                                    'notcalledText'
                                  }
                                  >
                                    {
                                      item.payment_status ? 'Paid': 'Unpaid'
                                    }</p> 
                                </div>
                              </div>     
                            </td>
                          
                        </tr>
                            ))
                          : ''}
                      </tbody>
                    )}        
                
             </table>

             {!loader && agentOrders.length === 0 ? (
                <div className='max-w-sm mx-auto mt-20'>
                  <div className='text-center'>
                   <h6 className='text-[#344335] text-base font-semibold'>
                   A journey of a thousand orders begins with a call.
                   </h6>
                   <p className='text-[#5C715E] text-xs mt-1 font-medium'>
                   Somebody at Crop2Cash
                   </p>
                   </div>
               </div>
              ) : (
                ''
              )}

            {!loader && agentOrders.length > 0 ? (
                <div className="mt-1">
                  <PaginationComponent
                    page={currentPage}
                    onPageChange={onPageChange}
                    pageSize={10}
                    totalItems={agentOrderCount}
                  />
                </div>
              ) : (
                ''
              )}

            {loader && (
                <div className="animate-pulse w-full mt-4">
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                </div>
              )}

           </div>
     </div>
    </>
  )
}

export default Orders
