import { createSlice } from '@reduxjs/toolkit'
import { getOrders } from './orderActions'



const initialState = {
    loader: false,
    agentOrders: [],
    agentOrderCount: 0
}

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    increment: (state) => {
        state.count += 1
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
      },
    
  },
  extraReducers: {
    //   Get Orders ----------
    [getOrders.pending]: (state)=>{
        state.loader = true
    },
    [getOrders.fulfilled]: (state, {payload}) =>{
        state.loader = false
        state.agentOrders = payload.data.data
        state.agentOrderCount = parseInt(payload.data.total)
    },
    [getOrders.rejected]: (state) =>{
        state.loader = false
    }
   
  }
})

// Action creators are generated for each case reducer function
export const { increment } = orderSlice.actions

export default orderSlice.reducer