import React, { useEffect, useState } from 'react'
// import logo from '../../assets/logo.svg'
import logo1 from '../../assets/Black.svg'
import { Link, useNavigate } from 'react-router-dom'
import eye_off from '../../assets/eye-off.svg'
import eye from '../../assets/eye.svg'
import { Form, Formik } from 'formik'
import { registerValidator } from '../../validationSchema/validator'
import warning from '../../assets/warning.svg'
import { useDispatch, useSelector } from 'react-redux'
import { userRegister } from '../../redux/Auth/authActions'
import { clearSignUpSuccess } from '../../redux/Auth/auth'
import Select from 'react-select';

const SignUpPage = () => {

  const [passwordShown, setPasswordShown] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {loading, signup_success} = useSelector((state) => state.auth);

  const [selectedLanguage, setSelectedLanguage] = useState(["eng"])
  const [isLanguageError, setIsLanguageError] = useState(false)

  const languages = [
    {
      id: 1,
      name: "English",
      tag: "eng"
    },
    {
      id: 2,
      name: "Hausa",
      tag: "hau"

    },
    {
      id: 3,
      name: "Yoruba",
      tag: "yor"

    }
  ]

  const handleLanguage = (val) =>{
    setIsLanguageError(false)
    let langaugeArray = [];
    val.map(o => 
      langaugeArray.push(o.tag)
   );
   setSelectedLanguage(langaugeArray)
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }


  const handleSubmit =  (values) => {
  
    if(selectedLanguage.length > 0){
        const resp ={
          ...values,
          languages: selectedLanguage
        }
        dispatch(userRegister(resp))
    }
    else{
      setIsLanguageError(true)
    }
  
  }

  useEffect(()=>{
    if(signup_success){
      setTimeout(() =>{
        dispatch(clearSignUpSuccess())
        navigate('/')
      },[3000])
    }
  },[signup_success, navigate, dispatch])

  return (
    <>
      <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[450px] h-[450px] rounded-full blur-[150px]"></div>

        <div className="flex justify-between">
          <div>
            <img src={logo1} alt="logo" className="md:w-[129px] md:h-[40px]" />
          </div>
          <div className="z-10">
            
          </div>
        </div>

        {/* signup section divs */}
        <div className="md:max-w-lg lg:max-w-md mx-auto md:py-16 py-20">
          <div>
            <h6 className="text-primary font-semibold text-xl">
              Create Account
            </h6>
            <p className="text-secondary1 font-medium text-sm mt-1">
              Enter your Credentials below to get create your account{' '}
            </p>
          </div>
          <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
            validationSchema={registerValidator}
            initialValues={{
              email: '',
              firstname: '',
              lastname: '',
              password: '',
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form className="mt-8" onSubmit={handleSubmit}>
                <div class="grid md:grid-cols-2 md:gap-6">
                  <div>
                    <label className="block mb-2 text-secondary text-sm font-medium">
                      First name
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      placeholder="First name"
                      className={
                        touched.firstname && errors.firstname
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.firstname && errors.firstname ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.firstname && errors.firstname}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <label className="block mb-2 text-secondary text-sm font-medium">
                      Last name
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Last name"
                      className={
                        touched.lastname && errors.lastname
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.lastname && errors.lastname ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.lastname && errors.lastname}
                        </small>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="mt-5">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Language
                  </label>
                   <Select 
                        isMulti
                        onChange={handleLanguage}
                        classNamePrefix="select_control"
                        className="select_style"
                        defaultValue={languages[0]}
                        options={languages}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                       />
                </div>
                {isLanguageError ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          Language is required
                      </small>
                    </div>
                  ) : null}

                <div className="mt-5">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    className={
                      touched.email && errors.email
                        ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                        : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    }
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.email && errors.email}
                      </small>
                    </div>
                  ) : null}
                </div>

                <div className="mt-5">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Password
                  </label>
                  <div className="relative">
                    <input
                      type={passwordShown ? 'text' : 'password'}
                      name="password"
                      placeholder="Password"
                      className={
                        touched.password && errors.password
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <img
                      onClick={togglePasswordVisiblity}
                      src={passwordShown ? eye : eye_off}
                      alt="eye"
                      className="absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer"
                    />
                  </div>
                  {touched.password && errors.password ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.password && errors.password}
                      </small>
                    </div>
                  ) : null}
                </div>

                <div className="mt-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-primary
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                    Sign Up
                  </button>
                </div>

                <div className='text-center mt-3'>
                    <p className='text-[#344335] text-sm font-semibold'>Have an account? <Link to="/" className='text-primary font-semibold text-sm'>Log In</Link> </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default SignUpPage
