import React, { useEffect, useState } from 'react'
// import logo from '../../assets/logo.svg'
import logo1 from '../../assets/Black.svg'
import { Link, useNavigate } from 'react-router-dom'
// import close_green from '../../assets/close-green.svg'
import logout_icon from '../../assets/logout1.svg'
import chevron_down from '../../assets/chevron-down.svg'
import chevron_up from '../../assets/chevron-up.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getAgentCampaign } from '../../redux/Campaign/campaignActions'
import { Logout } from '../../redux/Auth/auth'
import NotificationsIcon from '@mui/icons-material/Notifications'

import Notifications from '../../Components/Notifications/Notifications'

const CampaignPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userInfo } = useSelector((state) => state.auth)
  const { campaigns, loader } = useSelector((state) => state.campaign)

  const capitalizeFirstLetter = (str) => {
    let val = str.toLowerCase()
    let words = val.split(' ')
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1)
    }
    return words.join(' ')
  }

  const handleLogout = () => {
    dispatch(Logout())
    navigate('/')
  }

  const formatLanguage = (val) => {
    let resp
    switch (val) {
      case 'eng':
        resp = 'English'
        break
      case 'yor':
        resp = 'Yoruba'
        break
      case 'hau':
        resp = 'Hausa'
        break
      default:
        break
    }
    return resp
  }

  const handleReload = () => {
    dispatch(getAgentCampaign())
  }

  const [isOpen, setIsOpen] = useState(false)
  const toggleLogout = () => {
    setIsOpen(!isOpen)
  }

  const [isOpenNotification, setIsOpenNotification] = useState(false)

  useEffect(() => {
    dispatch(getAgentCampaign())
  }, [dispatch])

  return (
    <>
      <div className="px-6 md:px-10 py-8 bg-[#f5f5f5] min-h-screen relative">
        <div className="flex justify-between items-center">
          <div>
            <img src={logo1} alt="logo" className="md:w-[129px] md:h-[40px]" />
            {/* <img src={logo} alt="logo" className="md:w-[162px] md:h-[33px]" /> */}
          </div>
          <div className="relative">
            <div className="lg:flex gap-2 items-center">
              <div className="relative">
                <div
                  className="lg:flex items-center justify-center rounded-full cursor-pointer bg-[#2B8C34] w-[40px] h-[40px] mr-[8px]"
                  onClick={() => setIsOpenNotification(!isOpenNotification)}
                >
                  <NotificationsIcon
                    className="text-white "
                    style={{ fontSize: '18px' }}
                  />
                </div>
              </div>
              {/* {isOpenNotification && (
                <div className="absolute mr-[90%] bg-white h-[510px] w-[384px]"></div>
              )} */}
              <div className="lg:flex items-center justify-center rounded-full cursor-pointer bg-[#2B8C34] w-[40px] h-[40px]">
                <p className="text-center font-medium text-sm text-white">
                  {userInfo ? userInfo.first_name.charAt(0).toUpperCase() : 'S'}
                  {userInfo ? userInfo.last_name.charAt(0).toUpperCase() : 'A'}
                </p>
              </div>
              <div
                onClick={toggleLogout}
                className="flex items-center gap-3 cursor-pointer"
              >
                <p className="text-black font-semibold text-sm">
                  {' '}
                  {userInfo ? userInfo.first_name : 'Sales'}{' '}
                  {userInfo ? userInfo.last_name : 'Agent'}
                </p>
                <img
                  className="cursor-pointer"
                  src={isOpen ? chevron_up : chevron_down}
                  alt="chevron"
                />
              </div>
            </div>
            <div
              className={
                isOpen
                  ? 'absolute top-12 w-full left-0 right-0'
                  : 'hidden absolute top-14 w-full left-0'
              }
            >
              <button
                onClick={handleLogout}
                className="w-full py-4 flex items-center justify-center px-4 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
              >
                <img src={logout_icon} alt="logout_icon" />
                <p className="text-white font-medium text-sm ml-2">Log Out</p>
              </button>
            </div>
          </div>
        </div>

        {isOpenNotification && (
          <Notifications
            closeNotification={() => setIsOpenNotification(false)}
          />
        )}

        <div className="bg-white px-6 py-8 rounded-md mt-8 mb-8">
          <h4 className="text-tertiary font-semibold text-lg">
            Hello{' '}
            {userInfo ? capitalizeFirstLetter(userInfo.first_name) + ',' : null}
          </h4>

          <p className="text-secondary font-normal mt-1">
            {campaigns.length > 0
              ? 'This a list of campaigns that you have been assigned to'
              : 'It seems like you haven’t been assigned to any campaign yet'}
          </p>
        </div>

        {loader &&
          ['2', '1', '4', '5', '3'].map((item) => (
            <div className="mb-4 max-w-4xl mx-auto bg-[#FFFFFF] rounded-lg py-6 px-6">
              <div className="animate-pulse w-full">
                <div className="h-4 bg-gray-200 rounded-md  w-full mb-2"></div>
                <div className="h-12 bg-gray-200 rounded-md  w-full mb-2"></div>
                <div className="h-6 bg-gray-200 rounded-md  w-full mb-2"></div>
                <div className="h-6 bg-gray-200 rounded-md w-full mb-2"></div>
                <div className="h-6 bg-gray-200 rounded-md  w-full"></div>
              </div>
            </div>
          ))}

        {!loader && campaigns.length === 0 && (
          <div className="max-w-sm mx-auto mt-28">
            <p className="text-[#344335] font-medium text-base text-center">
              Its seems like you haven’t been assigned to any campaign yet
            </p>
            <div className="mt-4 text-center">
              <button
                onClick={handleReload}
                className="text-[#2B8C34] font-medium text-sm"
              >
                Reload
              </button>
            </div>
          </div>
        )}

        {!loader && campaigns.length
          ? campaigns.map((item) => (
              <div className="mb-6 max-w-4xl mx-auto bg-[#FFFFFF] rounded-lg py-6 px-6">
                {/* title */}
                <div className="flex justify-between items-center">
                  <div>
                    <h5 className="text-[#344335] font-semibold">
                      {item.campaign_name}
                    </h5>
                  </div>
                  {/* <div className='bg-[#DBFFDF] text-white flex gap-2 rounded-[4px] items-center py-1 px-2'>
                                <p className='text-[#2B8C34] text-xs font-medium'>50 Farmers has been added</p>
                                <img src={close_green} alt="close" className='img-fluid' />
                            </div> */}
                </div>
                <p className="mt-2 text-[#344335] font-medium text-sm">
                  {item.description}
                </p>

                <ul className="mt-3 flex gap-2">
                  {item.languages !== null
                    ? item.languages.map((val, index) => (
                        <>
                          <li
                            key={index}
                            className="bg-[#96A397] text-[#fff] text-xs font-medium px-2 py-1 rounded-[4px]"
                          >
                            {formatLanguage(val)}
                          </li>
                        </>
                      ))
                    : ''}
                </ul>

                <div className="mt-4 flex gap-4">
                  <div>
                    <h6 className="text-[#96A397] font-semibold text-sm">
                      Calls Made
                    </h6>
                    <p className="text-[#344335] font-semibold text-sm">
                      {item.calls_made}/{item.total_farmers}
                    </p>
                  </div>
                  <div>
                    <h6 className="text-[#96A397] font-semibold text-sm">
                      Sales Made
                    </h6>
                    <p className="text-[#344335] font-semibold text-sm">
                      {item.total_sales ? item.total_sales : 0}
                    </p>
                  </div>
                </div>

                <div className="mt-4">
                  <Link
                    className="text-[#2B8C34] text-sm font-medium"
                    to={`/home/${item.id}`}
                  >
                    View Campaign
                  </Link>
                </div>
              </div>
            ))
          : ''}

        {campaigns.length === 0 ? (
          <div className="absolute left-1/2 bottom-10 transform -translate-x-1/2 -translate-y-1/2">
            <button
              onClick={handleLogout}
              className="py-4 flex items-center px-6 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
            >
              <img src={logout_icon} alt="logout_icon" />
              <p className="text-white font-medium text-sm ml-2">Log Out</p>
            </button>
          </div>
        ) : (
          <div className="flex justify-center mt-20">
            <button
              onClick={handleLogout}
              className="py-4 flex items-center px-6 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
            >
              <img src={logout_icon} alt="logout_icon" />
              <p className="text-white font-medium text-sm ml-2">Log Out</p>
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default CampaignPage
