import React from 'react'
import {Pagination} from '@carbon/react'
import './pagination.scss'


const PaginationComponent = ({
  page,pageSize,totalItems, onPageChange
}) => {

  
  return (
    <>
    <Pagination
        style={{backgroundColor: 'white', borderTop: '1px solid #F7F7F7', color: '#5C715E', }}
         backwardText="Previous page"
          forwardText="Next page"
          itemsPerPageText="Items per page:"
          page={page}
          pageNumberText="Page Number"
          pageSize={pageSize}
          onChange={(page) => onPageChange(page)}
          pageSizes={[
            10,
            20,
            30,
            40,
            50
          ]}
          
          totalItems={totalItems}
        />
    </>
  )
}

export default PaginationComponent
