import React,{useState, useEffect, useRef, useCallback, useMemo} from 'react'
import Breadcrumb from '../../Components/Breadcrumbs/Breadcrumb'
import go_back from '../../assets/goBack.svg'
import history_icon from '../../assets/history_icon.svg'
import add_icon from '../../assets/add.svg'
import arrow_down from '../../assets/arrow-down.svg'
import checkbox from '../../assets/check-box.svg'
import { Link, useParams, useNavigate } from 'react-router-dom'
import search_icon from '../../assets/search.svg'
import search_green from '../../assets/search_green.svg'
import minus_icon from '../../assets/minus.svg'
import minus from '../../assets/minus_active.svg'
import plus_active from '../../assets/plus_active.svg'
import plus_icon from '../../assets/plus_icon.svg'
import copy_icon from '../../assets/copy.svg'
import Modal from '../../Components/Modals/modal'
import {useDispatch, useSelector} from 'react-redux'
import { getFarmerById, getLGAByStateID, getStates } from '../../redux/Home/homeActions'
import {debounce} from 'lodash'
import { addComplaint, addItemsToCart, atlasSearch, createCallsOrder, deleteCartItems,
   getCartItems, getNoteHistory, updateAssignment, UpdateCartQantity } from '../../redux/Calls/callActions'
import {clearShortCode, filterAtlasSearch, ProcessLoad} from '../../redux/Calls/calls'
import cogoToast from 'cogo-toast'
import cart_icon from '../../assets/cart_icon.svg'
import cart_grey from '../../assets/cart_grey.svg'
import info_icon from '../../assets/info.svg'
import line_icon from '../../assets/Line.svg'
import { Form, Formik } from "formik";
import { locationValidator } from "../../validationSchema/validator";
import InfoAlert from '../../Components/Alerts/infoAlert'
import moment from 'moment';
import info_white from '../../assets/info-white.svg'
import warning from '../../assets/warning.svg'
import {Mixpanel} from '../../Components/Helpers/MixPanel'
import {CopyToClipboard} from 'react-copy-to-clipboard';




const CallBoardPage = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const interval = useRef()

  const {farmer, states, lgas, farmerWalletBalance} = useSelector((state) => state.home)

  const { campaign  } = useSelector((state) => state.campaign)

  const {searchResult, loading, cart,processloader,deleteLoader, addCartLoader,groupByResult, notesHistory, isOrderLoader, saveLoader,shortCode, informationCard} = useSelector((state) => state.call)


  const query = useParams();


  const [breadcrumb] = useState([
    {
      text: 'Home',
      href: `/home/${campaign.id}`,
      disabled: true,
    },

    {
      text: ' Farmer Profile',
      href: `/farmer/${query.id}`,
      disabled: true,
    },
    {
      text: ' Call Farmer',
      href: `/callfarmer/1`,
      disabled: false,
    },
  ])

   // call time counter
  
   
   

  const [isStartCall, setIsStartCall] = useState(false)
  const [isStarted, setIsStarted] = useState(false)

  const [seconds, setSeconds] = useState(0)

  const [alertShow, setAlertShow] = useState(true)

  const closeAlert = () =>{
    setAlertShow(false)
  }
 

  const handleStartCall = () => {
    Mixpanel.track("Initiate Call")
    setIsStartCall(true)
    setIsStarted(true)
      interval.current = setInterval(() => {
          setSeconds(seconds => seconds + 1)
      }, 1000)  
  }

  const formatTime = ( seconds) =>{
    const hour = Math.floor(seconds/3600)
    const secondsLeft = seconds % 3600
    const minute  = Math.floor(secondsLeft/60)
    const sc = secondsLeft - (minute * 60)
    if(hour > 0){
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${sc.toString().padStart(2, '0')}`  
    }
    return `${minute.toString().padStart(2, '0')}:${sc.toString().padStart(2, '0')}`
  }

const handleHoldCall = () =>{
  clearInterval(interval.current)
  setIsStartCall(false)
}

  const handleEndCall = () =>{
    clearInterval(interval.current)
    setIsStartCall(false)
    setShowCallModal(true)
  }
  

  const [isAdd, setIsAdd] = useState(false)

  const addNote = () =>{
    setIsAdd(!isAdd)
  }

  const seeHistory = () =>{
    setIsAdd(!isAdd)
  }

  const [callScriptMsg, setCallScriptMsg] =  useState(
       <>
        “Hello, My name is (agent’s name) from Crop2Cash on a recorded line! Am I speaking with Mr, Ms (customer's name) 
            <div className='mt-1'>
            “I am calling with respect to our farm inputs, do you know that you can get access to purchase original farm inputs at supplier’s (wholesale) price directly from us?
            Our inputs include Fertilizers, seed dressings, seeds and agrochemicals for both crops and livestock at no additional cost.
            </div>
           <div className='mt-1'>
           “What products do you use on your farm (Customer’s name)?”
            </div>
            <div className='mt-1'>
            “What can we help you provide today?” (Farmer answers and states preferred input. Agents can recommend a substitute product in a situation where the farmer’s choice isn't available.
          Farmers should be educated on the price of inputs, terms and conditions as applicable to logistics and refund where necessary and proceed to complete order on payment confirmation.
            </div>
            <div className='mt-1'>
            Please be aware that orders are typically completed within 3-5 working days. Thank you for your trust and for taking the time to speak to me today, Mr/Ms (customer’s name), do have a pleasant day ahead.
            </div>
      </>
  )
  const [callStatus] = useState([
    {
      id: 1,
      text: 'Sales Call',
      content: () => (
        <>
        “Hello, My name is (agent’s name) from Crop2Cash on a recorded line! Am I speaking with Mr, Ms (customer's name) 
            <div className='mt-1'>
            “I am calling with respect to our farm inputs, do you know that you can get access to purchase original farm inputs at supplier’s (wholesale) price directly from us?
            Our inputs include Fertilizers, seed dressings, seeds and agrochemicals for both crops and livestock at no additional cost.
            </div>
           <div className='mt-1'>
           “What products do you use on your farm (Customer’s name)?”
            </div>
            <div className='mt-1'>
            “What can we help you provide today?” (Farmer answers and states preferred input. Agents can recommend a substitute product in a situation where the farmer’s choice isn't available.
          Farmers should be educated on the price of inputs, terms and conditions as applicable to logistics and refund where necessary and proceed to complete order on payment confirmation.
            </div>
            <div className='mt-1'>
            Please be aware that orders are typically completed within 3-5 working days. Thank you for your trust and for taking the time to speak to me today, Mr/Ms (customer’s name), do have a pleasant day ahead.
            </div>
      </>
      )
    },
    {
      id: 2,
      text: 'Follow Up Call',
      content: () => (
        <>
        Greetings from Crop2Cash! My name is “agent’s name”. 
        May I know if I am unto Mr, Ms “customer name”. (Agent continues upon customer’s name confirmation)
        May I have a moment of your time? (Agent continues upon customer’s permission)
         <div className='mt-1'>
         I am calling you with respect to your pending order request.
         </div>
         <div className='mt-1'>
          <ul className='list-disc mt-1 ml-4'>
            <li>May I know if you have any challenge trying to initiate payment?
              How may I help with respect to your challenge?
              (Agent proffers a resolution with respect to the farmer’s challenge)
              </li>
          </ul>
        </div>
        <div className='mt-1'>
        Thank you for your trust and time Mr/Ms (customer’s name), do have a pleasant day ahead.
          </div> 
        </>
      ),
    }
  ])

  const [isCallOpen, setIsCallOpen] = useState(false)

  const [callTitle, setCallTitle] = useState('Sales Call')
  const [callId, setCallID] = useState(1)

  const handleCallStatus = ( val ) =>{
    const {id,text} = val
    setCallTitle(text)
    setCallID(id)
    setIsCallOpen(!isCallOpen)
    // filter and set the call script message
    let msg = callStatus.find((val) => val.id === id).content
    setCallScriptMsg(msg)

  }

  const handleCallOpen = () =>{
    setIsCallOpen(!isCallOpen)
  }

 
  const [types] = useState([
    {
      id: 1,
      tag: 'all',
      text: 'All'
    },
    {
      id: 2,
      tag: 'crop',
      text: 'Crop'
    },
    {
      id: 3,
      tag: 'livestock',
      text: 'Livestock'
    },
    {
      id: 4,
      tag: 'advisory',
      text: 'Advisory'
    },
    {
      id: 5,
      tag: 'insurance',
      text: 'Insurance'
    }
  ])

  const [chipId, setChip] = useState(1);
  const toggleChip = (val) =>{
    const {id, tag} = val
    setChip(id === chipId ? null : id) 
    console.log(tag)
    dispatch(filterAtlasSearch(tag))
  }

  const [searchValue, setSearchValue] = useState("")
  const [isPageOpen, setPageOpen] = useState(true)
  const [productInfo, setProductInfo] = useState({})

 // handle search
   const handleSearch = useMemo(()=>
   debounce((val)=>{
     console.log(val)
     setPageOpen(true)
     setChip(1)
    //  api to dispatch and make the actions for search
      dispatch(atlasSearch({val}))
   },750),
  [dispatch]
 )

  const handleChange = useCallback((e) =>{
    let val = e.target.value
    setSearchValue(val)
    handleSearch(val)
  },[handleSearch])

  const [complaint, setComplaint] = useState("")
  const [notes, setNotes] = useState("")

  // const [count] = useState(1)

  const increment = (type, productId) =>{

    // dispatch and increase quantity
     let cartId = cart.find((item)=> item.item_type === type.toUpperCase() && item.product_id === productId).id
     let quantity = cart.find((item)=> item.item_type === type.toUpperCase() && item.product_id === productId).quantity

     dispatch(ProcessLoad(cartId))

     dispatch(UpdateCartQantity(
      {
        id:cartId,
        quantity: quantity + 1,
        campaignId: campaign.id
      }))
  }


  const CountCheck = (type, productId) =>{
    let typeTag = type  ? type.toUpperCase(): type
    let count = cart.find((item)=> item.item_type === typeTag && item.product_id === productId)
    let countVal = count ? count.quantity : 1
    return countVal;
  }

  const IDCheck = (type, productId) =>{
    console.log(productId)
    console.log(type)
    let typeTag = type ? type.toUpperCase(): type
    console.log(typeTag)
    let id = cart.find((item)=> item.item_type === typeTag  && item.product_id === productId)
    let idVal = id ? id.id : -1
    let res = processloader.indexOf(idVal)
    return res;
  }

  const decrement = (type, productId) =>{
    let typeTag = type ? type.toUpperCase(): type
    let cartId = cart.find((item)=> item.item_type === typeTag && item.product_id === productId).id
    let quantity = cart.find((item)=> item.item_type === typeTag && item.product_id === productId).quantity

    dispatch(ProcessLoad(cartId))

    dispatch(UpdateCartQantity(
      {
        id:cartId,
        quantity: quantity - 1,
        campaignId: campaign.id
      }))
  }


  const handleBack = () =>{
    setPageOpen(true)
  }

  const handleViewProduct = (type, id) =>{
    console.log(type)
    console.log(id)
    // open next page
    setPageOpen(false)
    // filter from the search products display
    const res = searchResult.find((val)=> val.product_details.product_type === type && val.vendor_price_details.product_id === id)
    setProductInfo(res)
  }

  const [show, setShow] = useState(false)
  const [showCallModal, setShowCallModal] = useState(false)

  const showModal = () =>{
    setShow(!show)
  }

  const callModal = () =>{
    setShowCallModal(!showCallModal)
  }

  const InCartCheck = (type, productId) =>{
    console.log(type)
    let typeTag = type !== "" ? type.toUpperCase() : ""
    console.log(productId)
    const inCart = cart.some((item) =>
    item.item_type === typeTag && item.product_id === productId ? true : false)
    console.log(inCart)
    return inCart
  }

  const handleAddCart = () =>{
    let result = {
          farmerId: query.id,
          productType:  productInfo.product_details.product_type,
          quantity: 1,
          productId: productInfo.vendor_price_details.product_id,
          vendorId: productInfo.vendor_price_details.vendor_id,
          vendorName: productInfo.vendor_price_details.vendor_name,
          productPrice: productInfo.product_details.display_price,
          campaignId: campaign.id
      }
      dispatch(addItemsToCart({result}))
  }

  const addVariationCart = () =>{
    cogoToast.success('View your cart to complete the order if variations have been selected from!')
    setShow(false)
  }


  const handleIncrement = (type,productId, quantity) =>{
       let typeTag = type != null ? type.toUpperCase(): type
       let cartId = cart.find((item)=> item.item_type === typeTag && item.product_id === productId).id

       dispatch(ProcessLoad(cartId))
       dispatch(UpdateCartQantity(
        {
          id:cartId,
          quantity: quantity + 1,
          campaignId: campaign.id
        }))
  }

  const handleDecrement = (type, productId, quantity) =>{
      let typeTag = type != null ? type.toUpperCase(): type
      let cartId = cart.find((item)=> item.item_type === typeTag && item.product_id === productId).id
      dispatch(ProcessLoad(cartId))
      dispatch(UpdateCartQantity(
        {
          id:cartId,
          quantity: quantity - 1,
          campaignId: campaign.id
        }))
  }

  const handleMinusIcon = (val) =>{
    let res;
    if(val && val.quantity){
      if(val.quantity === 0){
        res = minus_icon
      }
      else{
        res = minus
      }
    }
    else{
      res = minus_icon
    }
    return res;
  }

  const handleMinusAction = (val) =>{
    let res;
    if(val && val.quantity){
      if(val.quantity === 0){
        res = 'pointer-events-none'
      }
      else{
        res = 'cursor-pointer'
      }
    }
    else{
      res = 'pointer-events-none'
    }
    return res;
  }

  // const toggleMultipleVendors = () =>{
  //   setShow(true)
  //   // filter from vendors list and show on modal multiple vendors
  // }

  const [showCodeModal, setShowCodeModal] = useState(false)

  const codeModal = () =>{
    dispatch(clearShortCode())
    setShowCodeModal(!showCodeModal)
  }

  const displayCart = () =>{
    setShowCart(true)
  }

  const [showCart, setShowCart] = useState(false)

  const cartModal = () =>{
    setShowCart(!showCart)
  }

  const handleDelete = (id) =>{
    dispatch(deleteCartItems({
      id,
      campaignId: campaign.id
    }))
  }

  const [tabValue, setTabValue] = useState('Delivery Address')

  const [showCheckout, setCheckoutModal] = useState(false)

  const checkoutModal = () =>{
    setCheckoutModal(!showCheckout)
  }


  const handleState = (val)=>{
    // make api call to get LGAS under state
    dispatch(getLGAByStateID({
      id: val
    }))
  }

  const handleCheckout = () =>{
    setShowCart(false)
    setTimeout(()=>{
      setCheckoutModal(true)
    },1000)
  }

  const [deliveryInfo, setDeliveryInfo] = useState({})

  const setTabDelivery = () =>{
    // make api call to fetch lgas by state id
    dispatch(getLGAByStateID({
      id: farmer.state_id
    }))
    setTabValue('Delivery Address')
  }

  const handleDeliveryAddress = (values) =>{
    setDeliveryInfo(
      {
        ...values,
        stateName: states.find((val)=> val.id === values.stateID).name,
        lgaName: lgas.find((val)=> val.id === values.lgaID).name
      }
    )
    setTabValue('Confirm Order')
  }

  const handleConfirmOrder = () =>{
    console.log(deliveryInfo)
     // makes an api call to checkout the order added to cart for the farmer
     let resp = {
        farmerId: query.id,
        totalPrice: getTotalAmount().totalPrice,
        stateID: deliveryInfo.stateID,
        lgaID: deliveryInfo.lgaID,
        deliveryAddress: deliveryInfo.deliveryAddress,
        farmerPhonenumber: farmer.phone_number,
        campaignId: campaign.id
     }
     dispatch(createCallsOrder({resp}))

  }


  useEffect(()=>{
    if(shortCode !== null){
      setCheckoutModal(false)

      setTimeout(()=>{
        setShowCodeModal(true)
      },1000)
    }
  },[shortCode])

  const [tabClose, setTabClose] = useState(true)

  const handleTabClose = useCallback((event) => {
     event.preventDefault();
      if(tabClose){
        console.log('beforeunload event triggered');
        return (event.returnValue = 'Are you sure you want to exit?');
      }
     },
    [tabClose]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [handleTabClose]);


  const setCallStatus = (val) =>{
    setCallDesc(val)
    setIsCallDescError(false)
    if(val === "FOLLOW_UP"){
      setIsFollowUp(true)
    }    
    else{
      setIsFollowUp(false)
    }
  }

  const [callDesc, setCallDesc] = useState("")
  const [followUpDate, setFollowUpDate] = useState("")
  const [isFollowUp, setIsFollowUp] = useState(false)
  const [iscallDescError, setIsCallDescError] = useState(false)
  const [isfollowError, setIsFollowError] = useState(false)

  const handleDescribeCall = () =>{

    // validate of call status is selected and if follow up date is selected
    if(callDesc === ""){
        setIsCallDescError(true)
    }
    else if(callDesc === "FOLLOW_UP" && followUpDate === ""){
      setIsFollowError(true)
    }
    else{
      setIsFollowError(false)
  
      // make api call to describe call session  
      // add complaints
      if(complaint !== ""){
        dispatch(addComplaint({
          complaint,
          farmer_id: query.id
        }))
      }
      
      // update assgignment
      dispatch(updateAssignment({
        callStatus: callDesc,
        followUpDate: followUpDate,
        callDuration: seconds,
        note: notes,
        campaignId: campaign.id
      }))
    }

    setTabClose(false)

      setTimeout(()=>{
        // close current tab
        navigate(`/home/${campaign.id}`)
      },3000)
  }
 

  useEffect(()=>{
    const {id} = query
    dispatch(getFarmerById({
      id
    }))
    dispatch(getCartItems({
      id,
      campaignId: campaign.id
    }))
    dispatch(getNoteHistory({
      id
    }))
    dispatch(getStates())
  },[dispatch, query, campaign])

   // ------- cart total amount calculation
   const getTotalAmount = () => {
    let totalPrice = 0
    cart.forEach(item => {
      totalPrice += item.total_item_price * item.quantity
    })
    return {
      totalPrice
    }
  }

  const handlCopy = () =>{
    cogoToast.success('Phone number Copied!')
  }

 



  return (
    <div className='mt-3 pb-10'>

      {/* Modal for Items added to Cart  */}
      <Modal title="Cart" subtitle="Item Details"  show={showCart} showModal={cartModal}>

        <div className='mt-8'>

             {
                cart && cart.length > 0 ?
                cart.map((item, index) =>(
               <div key={index} className='flex mt-5 mb-6 justify-between items-start'>
                <div className='flex gap-3'>
                  <div>
                    <h6 className='text-[#344335] text-sm font-semibold'>{item.product_name}</h6>
                    <p className='text-[#344335] text-sm mt-1'>
                        {item.vendor_name}
                    </p>
                    {/* quantity counter */}
                    <div className='flex items-center gap-2 mt-2'>
                    <div>
                      <div className='flex justify-between items-center min-w-[108px] bg-[#ECEFEC] rounded-lg py-2 px-3 '>
                          <div 
                            className={
                              IDCheck(item.item_type, item.product_id) !== -1 ||
                              item.quantity === 1 ?
                              'pointer-events-none'
                              :
                              'cursor-pointer'
                            }
                            onClick={() => handleDecrement(item.item_type, item.product_id, item.quantity)}
                            >
                            <img src={
                                  IDCheck(item.item_type, item.product_id) !== -1 || item.quantity === 1 ? minus_icon : minus}
                                alt="minus" className="w-[14px] h-[14px]" />
                          </div>
                          <div>
                            <p className='text-[#344335] text-sm font-semibold'>{item.quantity}</p>
                          </div>
                          <div className='cursor-pointer'
                               onClick={() => handleIncrement(item.item_type, item.product_id, item.quantity)}
                           >
                            <img 
                            src={
                              IDCheck(item.item_type, item.product_id) !== -1 
                              ?
                              plus_icon
                              :
                              plus_active }
                               className="w-[14px] h-[14px]"  alt="plus" />
                          </div>
                      </div>
                      </div>
                      <img src={line_icon} alt="line" />
                      <button
                      disabled={deleteLoader}
                          onClick={() => handleDelete(item.id)}
                       className='text-[#9B7000]
                       disabled:text-[#9B7000] disabled:opacity-[0.4]
                        text-sm font-medium'>Delete Item</button>
                  </div>
                  </div>
                </div>
                  <div>
                    <h6 className='text-[#344335] text-sm font-medium'>₦{item.total_item_price}/{item.product_size}{item.product_measurement}</h6>
                  </div>
               </div>
                ))
                  :
                <div className='max-w-sm mx-auto mt-12 mb-10'>
                   <div className='flex justify-center'>
                    <div className='bg-[#DBFFDF] w-[60px] h-[60px] rounded-full flex justify-center items-center'>
                      <img src={cart_icon} alt="cart" />
                    </div>
                    </div>
      
                    <div className='text-center mt-3'>
                      <p className='text-[#344335] text-xs font-medium'>Looks like we have an empty cart</p>
      
                      <p className='text-[#7C8D7E] text-xs font-medium mt-1'>
                      Add item(s) to cart
                      </p>
                    </div>
    
                </div>
                }


            {
              cart && cart.length > 0
              ?
              <div className='mt-10 mb-4 flex justify-center'>
                  <button 
                  onClick={() => handleCheckout()}
                    className='bg-primary rounded-lg w-full text-white py-3 px-6 text-sm'>Checkout ₦{getTotalAmount().totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</button>
            </div>
            :
            ""
            } 

        </div>

      </Modal>

      {/* Modal for checking out from cart and creating an order with checkout code */}
      <Modal title="Checkout" subtitle="" show={showCheckout} showModal={checkoutModal} >
          <div className='mt-6'>

            {/* tabs */}
            <div className="flex cursor-pointer">
                <p
                  className={
                    tabValue === 'Delivery Address'
                      ? 'text-sm font-semibold  text-[#2B8C34] border-b-[#2B8C34] border-b-2'
                      : 'text-sm font-semibold text-[#96A397]'
                  }
                  onClick={() => setTabDelivery()}
                >
                  Delivery Address
                </p>
                <p
                  className={
                    tabValue === 'Confirm Order'
                      ? 'text-sm font-semibold text-[#2B8C34] border-b-[#2B8C34] border-b-2 ml-[24px]'
                      : 'text-sm font-semibold text-[#96A397] ml-[20px]'
                  }
                  onClick={() => setTabValue('Confirm Order')}
                >
                  Confirm Order
                </p> 
            </div>

            {tabValue === 'Delivery Address' ? (   
              <div className='mt-6'>
        
                <Formik
                    onSubmit={(values, { setSubmitting }) =>
                    handleDeliveryAddress(values, setSubmitting)
                        }
                        enableReinitialize={true}
                        validationSchema={locationValidator}
                        initialValues={{
                          stateID:  farmer && farmer.state_id ? farmer.state_id : "",
                          lgaID: farmer && farmer.lga_id ? farmer.lga_id : "",
                          deliveryAddress: ""
                        }}
                      >
                        {({
                          handleChange,
                          isSubmitting,
                          handleSubmit,
                          handleBlur,
                          values,
                          touched,
                          errors,
                          setFieldValue
                       }) => (
                    <Form onSubmit={handleSubmit}>
                <div className='flex gap-4'>
                  <div className='relative w-[180px]'>
                          <select
                            name="stateID"
                            placeholder="Choose a State"
                            value={values.stateID}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(
                                        e,
                                        setFieldValue("lgaID", "")
                                      );
                                    handleState(e.currentTarget.value);
                              }}
                            className='appearance-none cursor-pointer w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-3'
                          >
                             <option selected disabled>Choose a State</option>
                              {states.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        <div className='absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer'>
                            <img src={arrow_down} alt="arrow" />
                        </div>
                  </div>
                  
                  <div>
                    <div className='relative w-[180px]'>
                        <select
                        name="lgaID"
                        placeholder="Choose a LGA"
                        value={values.lgaID}
                        onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue=""
                        className='appearance-none cursor-pointer w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-3'
                      >
                          <option selected value="" disabled>Choose LGA</option>
                          {lgas.map((option) => (
                              <option key={option.id} value={option.id}>{option.name}</option>
                              ))}
                        </select>
                        <div className='absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer'>
                              <img src={arrow_down} alt="arrow" />
                          </div>         
                    </div>
                      <small style={{ color: "#dc3545" }}>
                                 {touched.lgaID && errors.lgaID}
                              </small>
                  </div>
                </div>

                <div className='mt-5'>
                  <textarea id="deliveryAddress" rows="8" 
                    className='appearance-none w-full
                    placeholder:text-[#96A397] placeholder:text-sm
                      text-secondary1
                      text-sm
                      focus:border-[#ABB6AC]
                      focus:border outline-none
                      rounded-lg resize-none
                      bg-[#F7F7F7] py-3 px-4'
                      value={values.deliveryAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                       placeholder="Please fill the delivery address"></textarea>
                </div> 

               <div className='mt-6 mb-4'>
                  <button 
                  type="submit"
                    className='bg-primary rounded-lg w-full text-white py-3 px-6 text-sm'>Next</button>
              </div>
              </Form>
                    )}
                  </Formik>

            </div>  
              
            )
            :
            tabValue === 'Confirm Order' ?
            (
              <div className='mt-6'>

                <div className='bg-[#F7F7F7] rounded-lg py-3 px-3'>
                    <p className='text-[#96A397] text-xs font-medium'>Delivery Address</p>
                    <h4 className='text-[#344335] text-sm font-medium'>{deliveryInfo && deliveryInfo.deliveryAddress ? deliveryInfo.deliveryAddress: ''}</h4>
                    <h6 className='text-[#344335] text-xs font-medium'>{deliveryInfo && deliveryInfo.stateName ? deliveryInfo.stateName: ''} | {deliveryInfo && deliveryInfo.lgaName ? deliveryInfo.lgaName: ''}</h6>
                  </div>

                  {
                  cart && cart.length > 0 ?
                  cart.map((item, index) =>(
                    <div key={index} className='flex mt-3 mb-4 items-center justify-between'>
                    <div>
                        <h6 className='text-[#344335] text-sm font-semibold'>{item.product_name}</h6>
                        <p className='text-[#344335] text-sm font-medium'>{item.vendor_name}</p>
                        <p className='text-[#344335] text-sm font-medium'><span className='text-[#96A397] text-sm font-medium mr-1'>Qty:</span>{item.quantity}</p>
                    </div>
                    <div>
                        <p className='text-[#344335] text-sm font-medium'><i className='mdi mdi-currency-ngn'></i>{item.total_item_price}</p>
                    </div>  
                  </div>
                  ))
                  :
                  ""
                  }


                <div className='bg-[#ECEEEC] w-full h-[2px]'></div>

                <div className='text-right'>
                  <h4 className='text-primary font-semibold text-lg'>₦{getTotalAmount().totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h4>
                  {
                    farmerWalletBalance < getTotalAmount().totalPrice &&
                      <div className='flex justify-end'>
                        <div className='flex gap-1 items-center'>
                          <img src={warning} alt="refresh" className='cursor-pointer' />
                          <p className='text-[#B92043] text-xs font-medium'>Insufficent Balance</p>
                        </div>
                     </div>
                  }
  
                </div>

                <div className='mt-8 mb-4'>
                  <button 
                  disabled={isOrderLoader}
                  onClick={() => handleConfirmOrder()}
                    className='bg-primary rounded-lg 
                    disabled:bg-[#ABB6AC] 
                    w-full text-white py-3 px-6 text-sm'>Confirm Order</button>
                </div>


            </div>
            ):
            null
            }



          </div>

      </Modal>


      {/* Modal for checkout code after an item has been ordered/checked out */}
      <Modal show={showCodeModal} showModal={codeModal} 
       title=""
       subtitle=""
       >
            {/* SMS */}
            <div className='mt-8'>
                <div className='flex justify-center'>
                  <img src={cart_icon} alt="cart" />
                </div>

                <div className='text-center mt-2'>
                  <h4 className='text-[#344335] font-semibold text-lg'>Order has been sent</h4>
                    <p className='text-[#96A397] text-xs font-medium'>A Short Code has been sent to complete the order</p>
                  <h3 className='text-primary font-semibold text-xl mt-3'>*347*46*{shortCode}#</h3>

                 <div  className='flex justify-center'>
                  <div className='mt-5 flex gap-1 items-center bg-[#E7FBFE] rounded-[4px] py-2 px-3'>
                      <img src={info_icon} alt="info" />
                      <p className='text-[#0066AF] text-xs font-medium'>You can track the payment status on the order history</p>
                  </div>  
                  </div>

                </div>

                <div className='mt-10 mb-8 flex justify-center'>
                  <button 
                  onClick={codeModal}
                    className='text-[#5C715E] font-medium text-sm'>Close</button>
                </div>
            </div>
     </Modal>

      {/* modaL for describing a call after it's ended */}
      <Modal show={showCallModal} showModal={callModal} 
       title="Describe your call"
       subtitle="Please fill the form with the apporiate answer"
       >
            {/* Call desccription */}
            <div className='mt-8'>
                
            <div className="grid md:grid-cols-2 md:gap-4">
                   <div>
                       <p className="font-medium text-sm mb-1 text-[#344335]">Call Status</p>
                       <div className='relative'>
                        <select
                          name="callStatus"
                          placeholder="Select Status"
                          className='appearance-none cursor-pointer w-full placeholder:text-[#96A397] placeholder:text-sm text-[#5C715E] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-3'            
                          value={callDesc}
                           onChange={(e)=> setCallStatus(e.currentTarget.value)}
                        >
                            <option value=""  selected disabled>Select Status</option>
                            <option value="CALLED">Called</option>
                            <option value="MISSED">Missed</option>
                            <option value="INCOMPLETE">Incomplete</option>
                            <option value="FOLLOW_UP">Follow Up</option>
                          </select>
                            <div className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer'>
                                <img src={arrow_down} alt="arrow" />
                            </div>
                        </div>
                        {iscallDescError &&  <small style={{ color: "#dc3545" }}>
                                Select a call status     
                              </small>
                              }
                  </div>
                    
                  <div>
                      <p className="font-medium text-sm mb-1 text-[#ABB6AC]">Follow Up Date</p>
                      <div className='relative'>
                      <input
                        type="datetime-local"
                        id="dateOfBirth"
                        disabled={!isFollowUp}
                        className={
                          isFollowUp ?
                          'appearance-none cursor-pointer w-full placeholder:text-[#96A397] placeholder:text-sm text-[#5C715E] text-base focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white  py-3 px-3':
                          'appearance-none cursor-pointer w-full placeholder:text-[#344335] placeholder:text-sm text-[#5C715E] text-base focus:border-[#2B8C34]  focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-[#F7F7F7] py-3 px-3'
                        }
                         value={followUpDate}
                         onChange={(e) => setFollowUpDate(e.target.value)}
                      />
                      {isfollowError &&  <small style={{ color: "#dc3545" }}>
                           You haven’t filled follow date    
                            </small>
                          }
                      </div>
                  </div> 
              </div>
              

              <div className='mt-8 mb-3'>
                <button 
                 onClick={handleDescribeCall}
                 disabled={saveLoader}
                  className='bg-primary
                  disabled:bg-[#ABB6AC] rounded-lg w-full text-white py-3 px-6 text-sm'>Save</button>
              </div>
          </div>
      </Modal>

      {/* modal for adding to cart - multiple vandors to select from */}
       <Modal show={show} showModal={showModal} 
       title="Please Select Variation"
       subtitle="Select vendor(s) to add product to cart"
       >
            {/* products display of vendors */}
            <div className='mt-6'>    
            
                 <div className='flex mb-4 items-center justify-between bg-[#F7F7F7] rounded-lg py-2 px-4'>
                  <div>
                      <h6 className='text-[#344335] text-sm font-semibold' >Yemi stores</h6>
                      <p className='text-[#344335] text-sm font-medium'><i className='mdi mdi-currency-ngn'></i>400/20kg
                      </p>
                  </div>
                  <div className='flex justify-between items-center min-w-[118px] bg-[#ECEFEC] rounded-lg py-3 px-3 '>
                      <div 
                        className={
                          handleMinusAction()
                        }>
                        <img src={
                              handleMinusIcon()}
                            alt="minus" className="w-[14px] h-[14px]" />
                      </div>
                      <div>
                        <p className='text-[#344335] text-sm font-semibold'>1</p>
                      </div>
                      <div className='cursor-pointer'>
                        <img src={plus_active}  className="w-[14px] h-[14px]"  alt="plus" />
                      </div>
                  
                  </div>
                </div>
                

                <div className='mt-8'>
                  <button 
                  onClick={addVariationCart}
                    className='bg-primary rounded-lg w-full text-white py-3 px-6 text-sm'>Add to Cart</button>
                </div>
            </div>
        </Modal>

        <div>
          <Breadcrumb breadcrumb={breadcrumb} />
        </div>
        
         {/* GO BACK SECTION */}
        <Link to={`/farmer/${query.id}`} className="flex items-center cursor-pointer mt-[25px]">
          <img src={go_back} alt="go back" className="w-[20px] h-[20px]" />
          <p className="text-sm font-semibold text-[#5C715E] ml-[4px] ">
            Go Back
          </p>
        </Link>
        
           <InfoAlert show={alertShow} handleClose={closeAlert} icon={info_white} successMsg="Hi, Please ensure to start your call to record this session." />
        
        
        <div className={
              alertShow ?
              'mt-3 rounded-lg bg-white pt-4 pb-6 px-4'
              :
              'mt-6 rounded-lg bg-white pt-4 pb-6 px-4'
            }
          >
          <div className='flex justify-between items-center'>
             <div>
              <h4 className='text-[#1D5D23] font-semibold text-xl'>Call Board</h4>
            </div>
            <div className='flex gap-4 items-center'>
                {
                  cart && cart.length > 0 
                  ?
                  <div className='relative flex gap-1 items-center cursor-pointerad' onClick={displayCart}>
                    <img src={cart_grey} alt="cart" className='' />
                    <p className='text-primary font-semibold text-base cursor-pointer'>Cart({cart.length})</p>
                  <span 
                  className={
                    cart.length > 0 ?
                    'w-[6px] h-[6px] rounded-full bg-[#B92043] absolute right-0 top-0'
                    :
                    'w-[6px] h-[6px] rounded-full absolute right-0 top-0'
                  }
                  ></span>
                </div>
                :
                ""
                }  
                <div>
                    <p className='text-secondary1 font-medium text-base'
                      >{ formatTime(seconds) }</p>
                </div>
                <div>
                  {
                    isStartCall ?
                    <button onClick={handleHoldCall} className='py-3 px-6 bg-[#FFF3CE] rounded-[8px] text-[#D4A407]  text-sm font-medium transition-all'>Hold Call</button> 
                    :
                    <button onClick={handleStartCall} className='py-3 px-6 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all'
                    >{isStarted ? 'Continue Call': 'Start Call'}</button>
                  }
                </div>
                <div>
              { isStarted &&  <button onClick={handleEndCall} className='py-3 px-6 bg-[#B92043] rounded-[8px] text-white  text-sm font-medium border transition-all'>End Call</button> }
                </div>
              </div>
          </div>
           
          <div className='mt-2'>
            <h6 className='text-[#344335] font-semibold text-base'>
            {campaign.campaign_name}
            </h6>
          <p className='text-[#7C8D7E] text-sm font-medium mt-1'>
          {campaign.description}
            </p>
           </div>
        </div>

        <div className='mt-5 grid grid-cols-4 gap-4'>

          {/* first column */}
            <div>

              {/* account info */}
              <div className='bg-white rounded-lg py-5 px-4'>
                 <p className='text-[#7C8D7E] font-semibold text-sm'>Account Information</p>
                 <div className='flex items-center mt-4 gap-3'>
                   <div className='w-[48px] h-[48px] flex justify-center items-center rounded-full bg-primary text-white font-medium'>
                     {farmer && farmer.first_name ? farmer.first_name.charAt(0).toUpperCase(): "O"}{farmer && farmer.last_name ? farmer.last_name.charAt(0).toUpperCase(): "A"}
                   </div>
                   <div>
                     <p className='text-secondary text-sm font-semibold'>{farmer && farmer.first_name ? farmer.first_name : 'N/A'} {farmer && farmer.last_name ? farmer.last_name : 'N/A'}</p>
                     <div className='flex items-center gap-2'>
                       <p className='text-primary font-medium text-sm'>{farmer && farmer.phone_number ? farmer.phone_number: "N/A"}</p>
                       <CopyToClipboard text={farmer && farmer.phone_number ? farmer.phone_number: "N/A"}
                          onCopy={() => handlCopy()}>
                           <img src={copy_icon} alt="copy icon" className='cursor-pointer' />
                        </CopyToClipboard>
                     </div>
                   </div>
                 </div>
                 <div className='mt-6'>
                    <p className='text-[#7C8D7E] text-sm'>Gender</p>
                    <p className='text-secondary text-sm font-medium'>{farmer && farmer.gender ? (farmer.gender === 'm' ? 'Male' : farmer.gender === 'f' ? 'Female' : 'Other' ): 'Other'}</p>
                 </div>
                 <div className='mt-4'>
                    <p className='text-[#7C8D7E] text-sm'>Crops</p>
                    <p className='text-secondary text-sm font-medium'>
                      {
                        farmer && farmer.crops ?
                          farmer.crops.length > 0 ?
                           farmer.crops.map((val, index)=> (index ? ', ': '') + val.name)
                           :
                           'N/A'
                          :
                        'N/A'
                            }
                      </p>
                 </div>
                 <div className='mt-4'>
                    <p className='text-[#7C8D7E] text-sm'>Livestocks</p>
                    <p className='text-secondary text-sm font-medium'>
                      {
                        farmer && farmer.livestock ?
                        farmer.livestock.length > 0  ?
                          farmer.livestock.map((val, index)=> (index ? ', ': '') + val.name)
                          : 
                          'N/A'
                          :
                        'N/A'
                            }
                      </p>
                 </div>
                 <div className='mt-4'>
                    <p className='text-[#7C8D7E] text-sm'>Farm Size</p>
                    <p className='text-secondary text-sm font-medium'>{farmer && farmer.farm_size ? farmer.farm_size: "N/A"}</p>
                 </div>
                 <div className='mt-4'>
                    <p className='text-[#7C8D7E] text-sm'>Location</p>
                    <p className='text-secondary text-sm font-medium'>{farmer && farmer.lga ? farmer.lga: "N/A"}, {farmer && farmer.state ? farmer.state: "N/A"} State</p>
                 </div>
              </div>

              {/* call notes */}
              <div className='bg-white rounded-lg py-5 px-4 mt-5 max-h-[300px] overflow-y-scroll'>
                  <div className='flex justify-between'>
                    <div>
                      <p className='text-[#7C8D7E] font-semibold text-sm'>Notes History</p>
                    </div>
                    {
                      isAdd ?
                    <div  onClick={seeHistory} className='flex gap-2 cursor-pointer'>
                      <img src={history_icon} alt="history" />
                      <p className='text-primary font-semibold text-sm'>See History</p>
                    </div>
                    :
                    <div onClick={addNote} className='flex gap-2 cursor-pointer'>
                    <img src={add_icon} alt="add" />
                    <p className='text-primary font-semibold text-sm'>Add Note</p>
                      </div>  
                    }         
                  </div>

                  {
                    isAdd ? 
                  
                  <div className='mt-4'>
                    <textarea id="message" rows="8" 
                    values={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    className='appearance-none w-full
                    placeholder:text-[#96A397] placeholder:text-sm
                      text-secondary1
                      text-sm
                      focus:border-[#ABB6AC]
                      focus:border outline-none
                       rounded-lg resize-none
                      bg-[#F7F7F7] py-3 px-4' placeholder="Describe your call"></textarea>
                  </div> 
                  :
                  <>
                  {
                    notesHistory && notesHistory.length > 0
                    ?
                    notesHistory.map((item)=>(
                      <div key={item.id} className='mt-4 bg-[#ECEFEC] rounded-lg py-4 px-4'>
                        <p className='text-[#5C715E] text-xs font-semibold'>Sales Agent</p>
                        <p className='text-[#5C715E] text-xs font-medium mt-1'>{moment(item.created).format('Do, MMM YYYY hh:mm:ss a')}</p>
    
                        <h6 className='text-[#5C715E] text-sm mt-2'>
                          {item.note}
                          </h6>
                    </div>
                    ))
                    :
                    <div className='text-center mt-16 mb-10'>
                      <p className='text-[#7C8D7E] text-xs fonr-medium'>No note(s) history yet!</p>
                    </div>
                  }
      
                </>

                  }
    
              </div>

            </div>

           {/* second column */}
            <div className='bg-white rounded-lg py-5 px-4 col-span-2 h-fit'>
                    
                <div>
                  <p className='text-[#7C8D7E] text-sm font-semibold'>Atlas {!isPageOpen && 'Search'}</p>
                </div>
                
                <div className='mt-2'>
                    <div className="relative">
                      <input type="text"
                           onChange={handleChange}
                           
                           value={searchValue}
                          className="appearance-none w-full placeholder:text-[#96A397] 
                          placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] 
                          focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-10"
                          placeholder="Search for products, crops" />
                           <img
                            src={search_icon}
                            alt="search"
                            className="absolute top-1/2 transform -translate-y-1/2 left-4 cursor-pointer"
                          />
                      </div>
                </div>

             

{/* ---------------------------- First Section ------------------------------------------- */}
            {
               isPageOpen &&
              <div>
                    {/* chips */}
                    <ul className='mt-4 flex gap-2'>
                        {
                          types.map((val)=>(
                              <>    
                                <li key={val.id} 
                                  onClick={() => toggleChip(val)}
                                  className={
                                    chipId === val.id ? 'bg-[#DBFFDF] text-primary text-xs font-medium px-2 py-1 rounded-[4px] cursor-pointer' 
                                    : "bg-[#ECEEEC] text-[#96A397] text-xs font-medium px-2 py-1 rounded-[4px] cursor-pointer"
                                  }>
                                  {val.text}
                              </li> 
                            </>
                          ))
                        }                   
                    </ul>

                    {/* search results title */}
                  
                  {
                    searchValue.length > 0 
                    ? 
                    <>
                    <div className='mt-4'>
                      <h5 className='text-[#344335] text-sm'>Search results for : "{searchValue}"</h5>
                    </div>

                    {
                      informationCard.length > 0 &&
                    <div className='mt-4 bg-[#EDF7EE] rounded-[5px] py-4 px-4'>
                        <h6 className="text-[#344335] font-semibold text-sm">{informationCard[0].product_name}</h6>
                        <p className="text-[#5C715E] text-sm mt-1">
                          {informationCard[0].product_information}
                        </p>
                      </div>
                      }
                      </>
                    :
                    ""
                  }  

                    {/* results */}
                    <div className='mt-4'>
                      
                        {/* ---Crop products map */}
                          <div className='mb-3'>
                           {groupByResult && groupByResult.InputProducts 
                                  ?
                              groupByResult.InputProducts.length > 0 ? <h5 className='text-[#344335] text-sm font-semibold'>Crop</h5>
                              :
                              ""
                              :
                              ""
                            }

                              {
                                groupByResult && groupByResult.InputProducts
                                ?
                                groupByResult.InputProducts.length > 0 
                                ?
                                groupByResult.InputProducts.map((item)=>(
                                <div className='mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer'>
                                  <div>
                                      <h4 className='text-[#344335] text-sm font-semibold'>{item.product_details ? item.product_details.name : 'N/A'}</h4>
                                      
                                      <div className='flex gap-2 mt-1 text-[#5C715E] font-medium text-xs'>
                                      <p>{item.vendor_price_details ? item.vendor_price_details.vendor_name: ""}</p>
                                      <p>|</p>
                                      <p><i className='mdi mdi-currency-ngn'></i>{item.product_details.display_price}/{item.product_details.product_size}{item.product_details.measurement}</p>
                                    </div>   
                                         
                                      
                                  </div>
                                  <div>
                                      <button onClick={() => handleViewProduct(item.product_details.product_type, item.vendor_price_details.product_id)} className='text-primary ctaView
                                          text-xs font-medium'>View Product</button>
                                  </div>        
                                </div>
                                ))
                                :
                                ""
                                :
                                ""
                              }     
                          </div>

                        {/* --- Livestock products */}
                        <div className='mb-3'>
                        {groupByResult && groupByResult.LivestockInputs 
                                  ?
                              groupByResult.LivestockInputs.length > 0 ? <h5 className='text-[#344335] text-sm font-semibold'>Livestock</h5>
                              :
                              ""
                              :
                              ""
                            }
                             
                              {
                                groupByResult && groupByResult.LivestockInputs
                                ?
                                groupByResult.LivestockInputs.length > 0 
                                ?
                                groupByResult.LivestockInputs.map((item)=>(
                                <div className='mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer'>
                                  <div>
                                      <h4 className='text-[#344335] text-sm font-semibold'>{item.product_details ? item.product_details.name : 'N/A'}</h4>
                                     
                                        <div className='flex gap-2 mt-1 text-[#5C715E] font-medium text-xs'>
                                        <p>{item.vendor_price_details ? item.vendor_price_details.vendor_name: ""}</p>
                                        <p>|</p>
                                        <p><i className='mdi mdi-currency-ngn'></i>{item.product_details.display_price}/{item.product_details.product_size}{item.product_details.measurement}</p>
                                      </div>   
                                        
                                      
                                  </div>
                                  <div>
                                      <button onClick={() => handleViewProduct(item.product_details.product_type, item.vendor_price_details.product_id)} className='text-primary ctaView
                                          text-xs font-medium'>View Product</button>
                                  </div>        
                                </div>
                                ))
                                :
                                ""
                                :
                                ""
                              }     
                          </div>
                        
                        {/* Advisory products */}
                        <div className='mb-3'>
                        {groupByResult && groupByResult.AdvisoryProducts
                                  ?
                              groupByResult.AdvisoryProducts.length > 0 ? <h5 className='text-[#344335] text-sm font-semibold'>Advisory</h5>
                              :
                              ""
                              :
                              ""
                            }
                       
                       
                              {
                                groupByResult && groupByResult.AdvisoryProducts
                                ?
                                groupByResult.AdvisoryProducts.length > 0 
                                ?
                                groupByResult.AdvisoryProducts.map((item)=>(
                                <div className='mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer'>
                                  <div>
                                      <h4 className='text-[#344335] text-sm font-semibold'>{item.product_details ? item.product_details.name : 'N/A'}</h4>
                                     
                                          <div className='flex gap-2 mt-1 text-[#5C715E] font-medium text-xs'>
                                          <p>{item.vendor_price_details.vendor_name}</p>
                                          <p>|</p>
                                          <p><i className='mdi mdi-currency-ngn'></i>{item.product_details.display_price}/{item.product_details.product_size}{item.product_details.measurement}</p>
                                        </div>   
                                      
                                      
                                  </div>
                                  <div>
                                      <button onClick={() => handleViewProduct(item.product_details.product_type, item.vendor_price_details.product_id)} className='text-primary ctaView
                                          text-xs font-medium'>View Product</button>
                                  </div>        
                                </div>
                                ))
                                :
                                ""
                                :
                                ""
                              }     
                        </div>

                        {/* Mechanization */}
                        <div className='mb-3'>
                        {groupByResult && groupByResult.MechanizationProducts
                                  ?
                              groupByResult.MechanizationProducts.length > 0 ? <h5 className='text-[#344335] text-sm font-semibold'>Mechanization</h5>
                              :
                              ""
                              :
                              ""
                            }
                       
                              {
                                groupByResult && groupByResult.MechanizationProducts
                                ?
                                groupByResult.MechanizationProducts.length > 0 
                                ?
                                groupByResult.MechanizationProducts.map((item)=>(
                                <div className='mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer'>
                                  <div>
                                      <h4 className='text-[#344335] text-sm font-semibold'>{item.product_details ? item.product_details.name : 'N/A'}</h4>
                                     
                                          <div className='flex gap-2 mt-1 text-[#5C715E] font-medium text-xs'>
                                          <p>{item.vendor_price_details.vendor_name}</p>
                                          <p>|</p>
                                          <p><i className='mdi mdi-currency-ngn'></i>{item.product_details.display_price}/{item.product_details.product_size}{item.product_details.measurement}</p>
                                        </div>   
                                        
                                
                                  </div>
                                  <div>
                                      <button onClick={() => handleViewProduct(item.product_details.product_type, item.vendor_price_details.product_id)} className='text-primary ctaView
                                          text-xs font-medium'>View Product</button>
                                  </div>        
                                </div>
                                ))
                                :
                                ""
                                :
                                ""
                              }     
                        </div>

                        {/* Insurance */}
                        <div className='mb-3'>
                        {groupByResult && groupByResult.InsuranceProducts
                                  ?
                              groupByResult.InsuranceProducts.length > 0 ? <h5 className='text-[#344335] text-sm font-semibold'>Insurance</h5>
                              :
                              ""
                              :
                              ""
                            }
                         
                              {
                                groupByResult && groupByResult.InsuranceProducts
                                ?
                                groupByResult.InsuranceProducts.length > 0 
                                ?
                                groupByResult.InsuranceProducts.map((item)=>(
                                <div className='mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer'>
                                  <div>
                                      <h4 className='text-[#344335] text-sm font-semibold'>{item.product_details ? item.product_details.name : 'N/A'}</h4>
                                     
                                          <div className='flex gap-2 mt-1 text-[#5C715E] font-medium text-xs'>
                                          <p>{item.vendor_price_details.vendor_name}</p>
                                          <p>|</p>
                                          <p><i className='mdi mdi-currency-ngn'></i>{item.product_details.display_price}/{item.product_details.product_size}{item.product_details.measurement}</p>
                                        </div>   
                                        
                                      
                                  </div>
                                  <div>
                                      <button onClick={() => handleViewProduct(item.product_details.product_type, item.vendor_price_details.product_id)} className='text-primary ctaView
                                          text-xs font-medium'>View Product</button>
                                  </div>        
                                </div>
                                ))
                                :
                                ""
                                :
                                ""
                              }     
                        </div>
                        
              
                        {
                        !loading && searchResult.length === 0 ?
                         <div className='mt-20 mb-20'>
                          <div className='flex justify-center'>
                            <div className='bg-[#DBFFDF] w-[60px] h-[60px] rounded-full flex justify-center items-center'>
                              <img src={search_green} alt="search" />
                            </div>
                          </div>
      
                          <div className='text-center mt-3'>
                            <p className='text-[#7C8D7E] text-xs font-medium max-w-[16rem] mx-auto'>Search for any product using the name of a product or crop</p>
                          </div>
                        </div>
                        :
                        ""
                        }
          
                    
                    {loading && (
                        ['1','2', '3', '4', '5'].map(()=>(
                          <div className="animate-pulse w-full mt-4">
                            <div className="h-2 bg-gray-200 rounded-lg w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                          </div>
                        )) 
                      )}
                      
                    </div>
                 </div>
             }
      {/* ---------------- Second Section ------------------------------------------------- */}
              {/* GO BACK SECTION */}
          
          {
            !isPageOpen &&
            <div>
                <div onClick={handleBack} className="flex items-center cursor-pointer mt-5">
                    <img src={go_back} alt="go back" className="w-[20px] h-[20px]" />
                    <p className="text-sm font-semibold text-[#5C715E] ml-[4px] ">
                      Go Back
                    </p>
                </div>

                <div className='mt-6'>
                  <h5 className='text-[#344335] font-semibold text-sm'>Product Details</h5>
                </div>

                <div className='mt-3'>
                  <h6 className='text-[#7C8D7E] text-sm mb-1'>Product Name</h6>
                  <p className='text-[#344335] text-sm font-medium'>{productInfo && productInfo.product_details ? productInfo.product_details.name || productInfo.name: 'N/A'}</p>
                </div>

                <div className='mt-3'>
                  <h6 className='text-[#7C8D7E] text-sm mb-1'>Planting Material</h6>
                  <p className='text-[#344335] text-sm font-medium'>{productInfo && productInfo.product_details ? productInfo.product_details.planting_material: 'N/A'}</p>
                </div>
            
                <div className='mt-4'>
                  <h6 className='text-[#7C8D7E] text-sm mb-1'>Vendor and Price</h6>
                      
                   <p className='text-[#344335] text-sm mb-1 font-medium'>{productInfo && productInfo.product_details ? productInfo.vendor_price_details.vendor_name: ""} <i className='mdi mdi-currency-ngn'></i>{productInfo && productInfo.product_details ? productInfo.product_details.display_price: ""}/{productInfo && productInfo.product_details ? productInfo.product_details.product_size: ""}{productInfo && productInfo.product_details ? productInfo.product_details.measurement: ""}</p> 
                               
                </div>

                <div className='mt-4'>
                  <h6 className='text-[#7C8D7E] text-sm mb-1'>Seed Variety</h6>
                  <p className='text-[#344335] text-sm mb-1 font-medium'>{productInfo && productInfo.product_details ? productInfo.product_details.product_variety : 'N/A'}</p>
                </div>

                <div className='mt-4'>
                  <h6 className='text-[#7C8D7E] text-sm mb-1'>Variety Description</h6>
                  <p className='text-[#344335] text-sm mb-1 font-medium'>
                  {productInfo && productInfo.product_details ? productInfo.product_details.description: 'N/A'}</p>
                </div>

                <div className='mt-4'>
                  <h6 className='text-[#7C8D7E] text-sm mb-1'>Procedure</h6>
                  <p className='text-[#344335] text-sm mb-1 font-medium'>
                  {productInfo && productInfo.product_details ? productInfo.product_details.procedure: 'N/A'}</p>
                </div>

                <div className='mt-4'>
                  <h6 className='text-[#7C8D7E] text-sm mb-1'>Seed Rate </h6>
                  <p className='text-[#344335] text-sm mb-1 font-medium'>
                  {productInfo && productInfo.product_details ? productInfo.product_details.rate: 'N/A'}</p>
                </div>

                <div className='mt-4'>
                  <h6 className='text-[#7C8D7E] text-sm mb-1'>Adaptability</h6>
                  <p className='text-[#344335] text-sm mb-1 font-medium'>
                  {productInfo && productInfo.product_details ? productInfo.product_details.adaptability: 'N/A'}</p>
                </div>

                <div className='mt-3 w-full h-2 border-t border-[#ECEEEC]'></div>

                {/* add cart */}
                {
                  productInfo && productInfo.product_details
                  ?
                  InCartCheck(productInfo.product_details.product_type, productInfo.vendor_price_details.product_id) 
                  ?
                  <div className='max-w-[240px] mx-auto mt-2'>
                  <div className='flex justify-between items-center bg-[#ECEFEC] rounded-lg py-3 px-3 '>
                      <div 
                        className={
                          IDCheck(productInfo.product_details.product_type, productInfo.vendor_price_details.product_id) !== -1 ||
                          CountCheck(productInfo.product_details.product_type, productInfo.vendor_price_details.product_id) === 1 ?
                          'pointer-events-none'
                          :
                          'cursor-pointer'
                        } onClick={() => decrement(productInfo.product_details.product_type, productInfo.vendor_price_details.product_id)}>
                        <img src={
                             IDCheck(productInfo.product_details.product_type, productInfo.vendor_price_details.product_id) !== -1 || CountCheck(productInfo.product_details.product_type, productInfo.vendor_price_details.product_id) === 1 ? minus_icon : minus}
                            alt="minus" className="w-[14px] h-[14px]" />
                      </div>
                      <div>
                        <p className='text-[#344335] text-sm font-semibold'>{CountCheck(productInfo.product_details.product_type, productInfo.vendor_price_details.product_id)}</p>
                      </div>
                      <div className={        
                                IDCheck(productInfo.product_details.product_type, productInfo.vendor_price_details.product_id) !== -1 ?
                                  'pointer-events-none'
                                  :
                                  'cursor-pointer'
                                }
                       onClick={() => increment(productInfo.product_details.product_type, productInfo.vendor_price_details.product_id)}>
                        <img 
                        src={
                          IDCheck(productInfo.product_details.product_type, productInfo.vendor_price_details.product_id) !== -1 ? plus_icon : plus_active}
                        className="w-[14px] h-[14px]"  alt="plus" />
                      </div>
                  </div> 
                  </div> 
                 :
                 <div className='mt-2'>
                      <div className='col-span-3'>
                        <button 
                          onClick={() => handleAddCart()}
                          disabled={addCartLoader}
                        className='bg-primary
                        disabled:bg-[#ABB6AC] 
                         rounded-lg w-full text-white py-3 px-6 text-sm'>Add to Cart</button>
                      </div>
                  </div>
                  :
                  ""
                }
             
                
               
            </div> 
            }

        </div>

            {/* third column */}
            <div>
               <div className='bg-white rounded-lg py-5 px-5 max-h-[300px] overflow-y-scroll'>
                 <p className='text-[#7C8D7E] font-semibold text-sm'>Call Script</p>

                 <div className='flex mt-4 gap-2 cursor-pointer items-center relative' onClick={handleCallOpen}>
                   <p className='text-secondary text-sm font-medium'>{callTitle}</p>
                   <img src={arrow_down} alt="arrow" />
                   <ul
                        className={
                          isCallOpen ?
                          'absolute bg-white rounded-md top-6 shadow-bpo  py-3 px-2 z-30'
                          :
                          'hidden'
                        }
                        >
                             {
                                callStatus.map((val)=>(
                                    <>     
                                    <li key={val.id}
                                     onClick={() => handleCallStatus(val)}
                                     className='px-6 py-2 rounded-md hover:bg-[#EDF7EE]'>
                                      <div className='flex gap-2'>
                                        {callId === val.id && <img src={checkbox} alt="checkbox" />} 
                                        <p className='text-secondary text-sm font-normal'>{val.text}</p>
                                      </div>
                                    </li>
                                    </>
                                ))
                            }
                        </ul>
                 </div>

                 <div className='mt-3'>
                  <p className='text-secondary1 text-sm leading-5'>
                    {callScriptMsg}
                  </p>
                 </div>
                 
              </div>

              <div className='bg-white rounded-lg py-5 px-4 mt-5'>
                  <div>
                      <p className='text-[#7C8D7E] font-semibold text-sm'>Complaint</p>   
                  </div>

                  <div className='mt-5'>
                    <textarea id="message" rows="8" 
                      onChange={(e) => setComplaint(e.target.value)}
                      value={complaint}
                    className='appearance-none w-full
                    placeholder:text-[#96A397] placeholder:text-sm
                      text-secondary1
                      text-sm
                      focus:border-[#ABB6AC]
                      focus:border outline-none
                       rounded-lg resize-none
                      bg-[#F7F7F7] py-3 px-4' placeholder="Send to Customer Support (SHARP)"></textarea>
                  </div> 
               
               
              </div>


            </div>

        </div>
        

      </div>
  )
}

export default CallBoardPage
