import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LoginPage from './Pages/Auth/login'
import SignUpPage from './Pages/Auth/signup'
import SideWrapper from './Components/SideWrapper/sideWrapper'
import Home from './Pages/Home/Home'
import AssignedFollowUpPage from './Pages/FollowUp/FollowUp'
import Orders from './Pages/Orders/Orders'
import FarmersProfile from './Pages/Farmers/FarmersProfile'
import ForgotPasswordPage from './Pages/Auth/forgot'
import ResetPasswordPage from './Pages/Auth/reset'
import ResetSuccessPage from './Pages/Auth/resetSuccess'
import CallBoardPage from './Pages/Call/CallBoard'
import ProtectedRoute from './Components/PrivateRoutes/userRoute'
import CampaignPage from './Pages/Campaign/campaign'


function App() {
  return (
    <div>
       <Router>
         <Routes>
            <Route path="/" exact element={
                    <LoginPage />
                    } />
            <Route path="/signup"  element={
                    <SignUpPage />
                  } />
            <Route path="/forgotpassword"  element={
                    <ForgotPasswordPage />
                  } />
            <Route path="/resetpassword"  element={
                    <ResetPasswordPage />
                  } />
              <Route path="/resetsuccess"  element={
                  <ResetSuccessPage />
                } />

                <Route path="/mycampaigns" element={
                    <ProtectedRoute>
                       <CampaignPage />
                   </ProtectedRoute>
                } />
              <Route path="/home/:id"   element={
                    <ProtectedRoute>
                       <SideWrapper children={<Home/>} />    
                    </ProtectedRoute>         
                    } />
                    
              <Route path="/followup-calls" 
                  element={
                    <ProtectedRoute>
                      <SideWrapper children={<AssignedFollowUpPage/>} />    
                    </ProtectedRoute> 
                    }
                  />
              <Route path="/orders" 
                  element={
                    <ProtectedRoute>
                      <SideWrapper children={<Orders/>} />
                    </ProtectedRoute>
                }
             />
            <Route path="/farmer/:id" 
                element={
                  <ProtectedRoute>
                     <SideWrapper children={<FarmersProfile/>} />
                  </ProtectedRoute>
                  }
              />
             <Route path="/callfarmer/:id" 
                element={
                  <ProtectedRoute>
                     <SideWrapper children={<CallBoardPage/>} />
                  </ProtectedRoute>
                  }
              />
         </Routes>
      </Router>

    </div>
  )
}

export default App
