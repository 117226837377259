import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


export const getOrders = createAsyncThunk(
  'Orders',
  async ({page, campaignId}, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}sales/orders?page=${page}&campaign=${campaignId}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)
