import React from 'react'
// import logo from '../../assets/logo.svg'
import logo1 from '../../assets/Black.svg'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { forgotPasswordValidator } from '../../validationSchema/validator'
import warning from '../../assets/warning.svg'
import { useSelector, useDispatch } from 'react-redux'
import { userForgot } from '../../redux/Auth/authActions'

const ForgotPaswordPage = () => {

  const dispatch = useDispatch()
  const {loading} = useSelector((state) => state.auth);


  const handleSubmit = (values) => {
    dispatch(userForgot(values)) 
  }

  return (
    <>
      <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[450px] h-[450px] rounded-full blur-[150px]"></div>

        <div className="flex justify-between">
          <div>
          <img src={logo1} alt="logo" className="md:w-[129px] md:h-[40px]" />
          </div>
          <div className="z-10">
            
          </div>
        </div>

        {/* forgot section divs */}
        <div className="md:max-w-lg lg:max-w-md mx-auto md:py-28 py-20">
          <div>
            <h6 className="text-primary font-semibold text-xl">
              Forgotten your email?
            </h6>
            <p className="text-secondary1 font-medium text-sm mt-1">
              Don’t feel bad, it happens to the best of us. Enter your email
              address and we'll send you a link to reset your email.
            </p>
          </div>
          <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
            validationSchema={forgotPasswordValidator}
            initialValues={{
              email: '',
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form  className="mt-8" onSubmit={handleSubmit}>
                <div>
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    className={
                      touched.email && errors.email
                        ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                        : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    }
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.email && errors.email}
                      </small>
                    </div>
                  ) : null}
                </div>

                <div className="mt-8">
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-primary
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                    Send Reset Link
                  </button>
                </div>

                <div className='text-center mt-3'>
                    <p className='text-[#344335] text-sm font-semibold'>Remember password? <Link to="/" className='text-primary font-semibold text-sm'>Back to Login</Link> </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default ForgotPaswordPage
