import { createSlice } from '@reduxjs/toolkit'
import {createOrder, deleteFarmersCart, getAssignedFarmers, getAssignedFollowUps, getAssignedInfo, getCrops, getFarmerAssignment, getFarmerById, 
  getFarmersCalls, 
  getFarmersCart, 
  getFarmersComplaints, getFarmersOrders, getLGAByStateID, getLivestock, getStates, searchAssignedFarmers, UpdateFarmersCart, updateFarmersProfile} from './homeActions'

const initialState = {
  loading: false,
  infoLoad: false,
  assignedTotalCount: 0,
  isAssigned: false,
  assignedFarmers: [],
  assignedSearchFarmers: [],
  assignedInfo: {},
  farmer: {},
  farmerWalletBalance: 0,
  crops: [],
  livestock: [],
  states: [],
  lgas: [],
  updateLoader: false,
  complaints: [],
  orderLoader: false,
  orders: [],
  ordersCount: 0,
  callLoader: false,
  callHistory: [],
  callCount: 0,
  cart: [],
  processloader: [],
  deleteLoader: false,
  followUpCount: 0,
  followUpLoader: false,
  followUps: [],
  searchFollowUps: [],
  shortCode: null,
  isCheckoutLoader: false,
  assignmentId: null,
  callStatus: null
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    INCREMENT_QTY: (state,{payload}) =>{
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
      let incrementItems = state.cart.map((item) => item.id === payload.id ?
       { 
         ...item,
          quantity: item.quantity + 1
        } : item);
      state.cart = incrementItems
    },
    DECREMENT_QTY: (state, {payload}) =>{
      let decrementItems = state.cart.map((item) => item.id === payload.id ? 
         {
            ...item,
            quantity: item.quantity - 1,
          }
        : item);
       state.cart = decrementItems
    },
    deleteCart: (state, {payload}) =>{
      let removedItem = state.cart.filter((item) => item.id !== payload.id);
      state.cart = removedItem
    },
    resetShortCode: (state) =>{
      state.shortCode = null
    },
    // searchAssignedFarmers: (state, {payload}) =>{
    //   let resp = state.assignedSearchFarmers.filter((val) => {
    //     return (
    //       val.farmer.first_name.toLowerCase().includes(payload.toLowerCase()) ||
    //       val.farmer.last_name.toLowerCase().includes(payload.toLowerCase()) ||
    //       val.call_status.toLowerCase().includes(payload.toLowerCase())
    //     );
    //   });
    //   state.assignedFarmers = resp
    // },
    searchAssignedFollowUps: (state, {payload}) =>{
      let resp = state.searchFollowUps.filter((val) => {
        return (
          val.farmer.first_name.toLowerCase().includes(payload.toLowerCase()) ||
          val.farmer.last_name.toLowerCase().includes(payload.toLowerCase()) ||
          val.call_status.toLowerCase().includes(payload.toLowerCase())
        );
      });
      state.followUps = resp
    }
  },
  extraReducers: {
    // assignedFarmers -------------------- ---------------------------------------------------------------
    [getAssignedFarmers.pending]: (state) => {
      state.loading = true
    },
    [getAssignedFarmers.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.assignedTotalCount = payload ? parseInt(payload.total) : 0
      state.assignedFarmers = payload ? payload.data.sort((a) => a.call_status === "FOLLOW UP" ? -1 : 1) : []
      state.assignedSearchFarmers = payload ? payload.data.sort((a) => a.call_status === "FOLLOW UP" ? -1 : 1):  []
      state.isAssigned = payload ? payload.isAssigned : false
    },
    [getAssignedFarmers.rejected]: (state, { payload }) => {
      state.loading = false
    },
     // assigned Info -------------------- ---------------------------------------------------------------
      [getAssignedInfo.pending]: (state) => {
      
      },
      [getAssignedInfo.fulfilled]: (state, { payload }) => {
        state.assignedInfo = payload
      },
      [getAssignedInfo.rejected]: (state, { payload }) => {
      },

    // search assigned farmers
    [searchAssignedFarmers.pending]: (state) =>{
      state.loading = true
    },
    [searchAssignedFarmers.fulfilled]: (state, {payload}) =>{
      state.loading = false
      state.assignedTotalCount = payload ? parseInt(payload.total) : 0
      state.assignedFarmers = payload ? payload.data.sort((a) => a.call_status === "FOLLOW UP" ? -1 : 1) : []
      state.assignedSearchFarmers = payload ? payload.data.sort((a) => a.call_status === "FOLLOW UP" ? -1 : 1):  []
      state.isAssigned = payload ? payload.isAssigned : false
    },
    [searchAssignedFarmers.rejected]: (state) =>{
      state.loading = false
    },

   // Farmers profile -------------------- ---------------------------------------------------------------
      [getFarmerById.pending]: (state) => {
        state.infoLoad = true
      },
      [getFarmerById.fulfilled]: (state, { payload }) => {
        state.farmer = payload.data.data
        state.farmerWalletBalance = payload.data.data.wallet_amount
        state.lgas = payload.lga.lgas ? payload.lga.lgas.sort((a, b) => a.name > b.name ? 1 : -1) : []
        state.infoLoad = false
      },
      [getFarmerById.rejected]: (state, { payload }) => {
        state.infoLoad = false
      },

      // ----- Crops -----------------------
      [getCrops.pending]: (state) =>{
      },
      [getCrops.fulfilled]: (state, {payload}) =>{
        state.crops = payload.data
      },
      [getCrops.rejected]: (state) =>{ 
      },

      // ---- Livestock
      [getLivestock.pending] : (state) =>{
      },
      [getLivestock.fulfilled] : (state, {payload}) =>{
        state.livestock = payload.data
      },
      [getLivestock.rejected] : (state) =>{
      },

        // ---- states
        [getStates.pending] : (state) =>{
        },
        [getStates.fulfilled] : (state, {payload}) =>{
          state.states = payload.states.sort((a, b) => a.name > b.name ? 1 : -1)
        },
        [getStates.rejected] : (state) =>{
        },

         // ---- LGAS
         [getLGAByStateID.pending] : (state) =>{
        },
        [getLGAByStateID.fulfilled] : (state, {payload}) =>{
          state.lgas = payload.lgas.sort((a, b) => a.name > b.name ? 1 : -1)
        },
        [getLGAByStateID.rejected] : (state) =>{
        },

       // ---- Update Farmers profile
         [updateFarmersProfile.pending] : (state) =>{
           state.updateLoader = true
        },
        [updateFarmersProfile.fulfilled] : (state, {payload}) =>{
          state.updateLoader = false
        },
        [updateFarmersProfile.rejected] : (state) =>{
          state.updateLoader = true
        },

        // ----- Farmers complaints ---------
        [getFarmersComplaints.pending] : (state) =>{
        },
        [getFarmersComplaints.fulfilled] : (state, {payload}) =>{
            state.complaints = payload.data
        },
        [getFarmersComplaints.rejected]: (state) =>{
        },

        // ---- Farmers Orders ----------
        [getFarmersOrders.pending]: (state) =>{
          state.orderLoader = true
        },  
        [getFarmersOrders.fulfilled]: (state, {payload}) =>{
          state.orderLoader = false
          state.orders = payload.data
          state.ordersCount = parseInt(payload.count)
        },
        [getFarmersOrders.rejected]: (state) =>{
          state.orderLoader = false
        },

        // ----- Farmers call history -------------
        [getFarmersCalls.pending] : (state) =>{
            state.callLoader = true
        },
        [getFarmersCalls.fulfilled] : (state, {payload}) =>{
            state.callLoader = false
            state.callHistory = payload.data
            state.callCount = parseInt(payload.total)
        },
        [getFarmersCalls.rejected] : (state) =>{
            state.callLoader = false
        },

        // get farmers assignment -----
        [getFarmerAssignment.pending]: (state) =>{

        },
        [getFarmerAssignment.fulfilled]: (state, {payload}) =>{
          state.assignmentId = payload.data[payload.data.length - 1].id
        },
        [getFarmerAssignment.rejected]: (state) =>{

        },

        // ---- Farmers cart -----
        [getFarmersCart.pending]: (state) =>{

        },
        [getFarmersCart.fulfilled]: (state, {payload}) =>{
          let res = payload.data
          if(res && res.cart_items){
            if(res.cart_items.some((val)=> val.id === null)){
              state.cart = []
            }
            else{
              state.cart = res.cart_items
            }
          }
          else{
            state.cart = []
          }          
        },
        [getFarmersCart.rejected]: (state) =>{

        },

        // update farmers cart
        [UpdateFarmersCart.pending]: (state,action)=>{
            let id = action.meta.arg.id
           state.processloader = [...state.processloader, id]
        },
        [UpdateFarmersCart.fulfilled]: (state, {payload})=>{
          state.processloader = state.processloader.splice(1)
          let incrementItems = state.cart.map((item) => item.id === payload.id ?
            { 
              ...item,
              quantity: payload.quantity
            } : item);
            state.cart = incrementItems
           },
        [UpdateFarmersCart.rejected]: (state) =>{

        },

        // delete from farmers cart ------
        [deleteFarmersCart.pending]: (state)=>{
            state.deleteLoader = true
        },
        [deleteFarmersCart.fulfilled]: (state, {payload})=>{
           state.deleteLoader = false
           let removedItem = state.cart.filter(item => item.id !== payload.id)
           state.cart = removedItem
        },
        [deleteFarmersCart.rejected]: (state)=>{
           state.deleteLoader = false
        },

        // --- Create an Order via farmers account ------ 
        [createOrder.pending]: (state)=>{
            state.isCheckoutLoader = true
        },
        [createOrder.fulfilled]: (state, {payload})=>{
            state.isCheckoutLoader = false
            state.shortCode = payload.data.data.short_code
            state.cart = []
        },
        [createOrder.rejected]: (state)=>{
            state.isCheckoutLoader = false
        },


        // --- Assigned follow ups ------
        [getAssignedFollowUps.pending]: (state) =>{
            state.followUpLoader = true
        },
        [getAssignedFollowUps.fulfilled]: (state, {payload}) =>{
            state.followUpLoader = false
            state.followUps = payload.data
            state.searchFollowUps = payload.data
            state.followUpCount = parseInt(payload.total)
        },
        [getAssignedFollowUps.rejected]: (state)=>{
          state.followUpLoader = false
        }
 

  }
})

// Action creators are generated for each case reducer function
export const { INCREMENT_QTY, DECREMENT_QTY, deleteCart, resetShortCode, searchAssignedFollowUps } = homeSlice.actions

export default homeSlice.reducer