import React, { useEffect, useState } from 'react'
// import logo from '../../assets/logo.svg'
import logo1 from '../../assets/Black.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import eye_off from '../../assets/eye-off.svg'
import eye from '../../assets/eye.svg'
import { Form, Formik } from 'formik'
import { resetPasswordValidator } from '../../validationSchema/validator'
import warning from '../../assets/warning.svg'
import { useDispatch, useSelector } from 'react-redux'
import { userReset } from '../../redux/Auth/authActions'

const ResetPasswordPage = () => {
  const [passwordShown, setPasswordShown] = useState(false)
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);

  const navigate = useNavigate()

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const {isReset, loading} = useSelector((state) => state.auth);

  const toggleConfirmPasswordVisiblity = () => {
    setPasswordConfirmShown(passwordConfirmShown ? false : true);
  };

  const search = useLocation().search;

  const dispatch = useDispatch()

  const handleSubmit = (values) => {
    const otp = new URLSearchParams(search).get("otp");
    const userId = new URLSearchParams(search).get("id");
    var creds = {
        otp,
        userId: userId,
        password: values.password,
    };
    dispatch(userReset(creds));
  }

  useEffect(()=>{
    if(isReset){
      navigate('/resetsuccess')
    }
  },[isReset, navigate])





  return (
    <>
      <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[450px] h-[450px] rounded-full blur-[150px]"></div>

        <div className="flex justify-between">
          <div>
          <img src={logo1} alt="logo" className="md:w-[129px] md:h-[40px]" />
          </div>
          <div className="z-10">
          
          </div>
        </div>

        {/* forgot section divs */}
        <div className="md:max-w-lg lg:max-w-md mx-auto md:py-28 py-20">
          <div>
            <h6 className="text-primary font-semibold text-xl">
              Set New Password
            </h6>
            <p className="text-secondary1 font-medium text-sm mt-1">
              Looks like second chances do exist. Type in your new password
            </p>
          </div>
          <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
            validationSchema={resetPasswordValidator}
            initialValues={{
              password: '',
              confirm_password: '',
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form className="mt-8" onSubmit={handleSubmit}>
                <div className="mt-5">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Password
                  </label>
                  <div className="relative">
                    <input
                      type={passwordShown ? 'text' : 'password'}
                      name="password"
                      placeholder="Password"
                      className={
                        touched.password && errors.password
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <img
                      onClick={togglePasswordVisiblity}
                      src={passwordShown ? eye : eye_off}
                      alt="eye"
                      className="absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer"
                    />
                  </div>
                  {touched.password && errors.password ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.password && errors.password}
                      </small>
                    </div>
                  ) : null}
                </div>

                <div className="mt-5">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      type={passwordConfirmShown ? 'text' : 'password'}
                      name="confirm_password"
                      placeholder="Password"
                      className={
                        touched.confirm_password && errors.confirm_password
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.confirm_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <img
                      onClick={toggleConfirmPasswordVisiblity}
                      src={passwordConfirmShown ? eye : eye_off}
                      alt="eye"
                      className="absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer"
                    />
                  </div>
                  {touched.confirm_password && errors.confirm_password ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.confirm_password && errors.confirm_password}
                      </small>
                    </div>
                  ) : null}
                </div>

                <div className="mt-8">
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-primary
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                    Continue
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default ResetPasswordPage
