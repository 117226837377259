import React from 'react';
import close_icon from '../../assets/close-white.svg'



const InfoAlert = ({show, handleClose, icon, successMsg}) => {

    return ( 
        <>
        <div className=
            {
               show ? 'flex justify-center mt-6'
               :
               'hidden'
            }>
            <div className='bg-[#0066AF] text-white flex gap-2 rounded-lg items-center py-4 px-6'>
                <img src={icon} alt="icon" className='img-fluid'  />
                <p className='text-white text-sm'>{successMsg}</p>
                <img src={close_icon} onClick={handleClose} alt="close" className='img-fluid ml-4 cursor-pointer' />
            </div>
        </div>
        </>
     );
}
 
export default InfoAlert;