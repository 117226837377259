import { createSlice } from '@reduxjs/toolkit'
import {userLogin, userRegister, userForgot, userReset} from './authActions'


const initialState = {
  loading: false,
  error: null,
  userInfo: null,
  token: "",
  finagToken: "",
  isAuthenticated: false,
  signup_success: false,
  isReset: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearSignUpSuccess: (state) => {
      state.signup_success = true
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    },
    clearResetSuccess: (state) =>{
      state.isReset = false
    },
    clearError : (state) =>{
      state.error = null
    },
    Logout: (state) =>{
      state.isAuthenticated = false
      localStorage.setItem("token", "")
      localStorage.setItem("finagtoken", "");
    }
  },
  extraReducers: {
    // login user -------------------- ---------------------------------------------------------------
    [userLogin.pending]: (state) => {
      state.loading = true
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload.user
      state.token = payload.token
      state.finagToken = payload.finag_token
      state.isAuthenticated = true
      state.error = null
      localStorage.setItem("token", payload.token);
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
      state.isAuthenticated = false
    },

    // Register User ------------------ --------------------------------------------------------
    [userRegister.pending]: (state) => {
      state.loading = true
    },
    [userRegister.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.signup_success = true
    },
    [userRegister.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

     // Forgot Passowrd ------------------ --------------------------------------------------------
     [userForgot.pending]: (state) => {
      state.loading = true
    },
    [userForgot.fulfilled]: (state, { payload }) => {
      state.loading = false
    },
    [userForgot.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

    // Reset Password ------------------ --------------------------------------------------------
       [userReset.pending]: (state) => {
        state.loading = true
      },
      [userReset.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.isReset = true
      },
      [userReset.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.isReset = false
      },



  }
})

// Action creators are generated for each case reducer function
export const { clearSignUpSuccess, Logout, clearError, clearResetSuccess } = authSlice.actions

export default authSlice.reducer