import * as Yup from 'yup'

export const registerValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  password: Yup.string()
    .min(6, 'Password cannot be less than 6 characters')
    .required('Password is required'),
})

export const loginValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const forgotPasswordValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
})

export const resetPasswordValidator = Yup.object({
  password: Yup.string()
    .min(6, 'Password cannot be less than 6 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .required('Passwords must match')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export const locationValidator = Yup.object({
  stateID: Yup.string(),
  lgaID: Yup.string().required("Choose LGA"),
   deliveryAddress: Yup.string()
}); 

