import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../Components/Breadcrumbs/Breadcrumb'
import go_back from '../../assets/goBack.svg'
import { Link, useParams } from 'react-router-dom'
import PaginationComponent from '../../Components/Pagination/Pagination'
import minus_icon from '../../assets/minus.svg'
import minus from '../../assets/minus_active.svg'
import plus_active from '../../assets/plus_active.svg'
import plus_icon from '../../assets/plus_icon.svg'
import line_icon from '../../assets/Line.svg'
import arrow_down from '../../assets/arrow-down.svg'
import cart_icon from '../../assets/cart_icon.svg'
import info_icon from '../../assets/info.svg'
import Modal from '../../Components/Modals/modal'
import { useSelector, useDispatch } from 'react-redux'
import { createOrder, deleteFarmersCart, getCrops, getFarmerById, getFarmersCalls, getFarmersCart, 
  getFarmersComplaints, getFarmersOrders, getLGAByStateID, getLivestock, getStates, UpdateFarmersCart, updateFarmersProfile } from '../../redux/Home/homeActions'
import moment from 'moment'
import Select from 'react-select';
import { useFormik, Form, Formik } from "formik";
import DrawerComponent from '../../Components/Drawers/drawers'
import { locationValidator } from "../../validationSchema/validator";
import warning from '../../assets/warning.svg'
import { resetShortCode } from '../../redux/Home/home'

const FarmersProfile = () => {
  const [tabValue, setTabValue] = useState('Account Information')

  const { campaign  } = useSelector((state) => state.campaign)


   const query = useParams();

  const dispatch = useDispatch()

  const [breadcrumb] = useState([
    {
      text: 'Home',
      href: `/home/${campaign.id}`,
      disabled: true,
    },

    {
      text: ' Farmer Profile',
      href: `/farmers/1`,
      disabled: false,
    },
  ])

  const {farmer, crops, livestock, updateLoader, complaints,
    orders, orderLoader, ordersCount, callLoader, callHistory,
    callCount, cart,processloader, deleteLoader, isCheckoutLoader,shortCode, states, lgas, farmerWalletBalance} = useSelector((state) => state.home)

  const capitalizeFirstLetter = (str) => {
    let val = str.toLowerCase()
    let words = val.split(" ");
   for (let i = 0; i < words.length; i++) {
       words[i] = words[i][0].toUpperCase() +  words[i].substr(1);
   }
    return words.join(" ");
  }

  const formatDuration = ( val ) =>{
    let seconds = parseInt(val)
    let result = "";
    const hour = Math.floor(seconds/3600)
    const secondsLeft = seconds % 3600
    const minute  = Math.floor(secondsLeft/60)
    const sc = secondsLeft - (minute * 60)
    if(hour > 0){
      result += `${hour}${hour === 1 ? 'hr ' : 'hrs '}`
    }
    if(minute > 0){
      result += `${minute}${minute === 1 ? 'min ' : 'mins '}`
    }
    if(sc > 0){
      result += `${sc}${sc === 1 ? 'sec ' : 'secs'}`
    }  
    return result
  }


  const [currentPage, setCurrentPage] = useState(1)

  const onNextOrderChange = (val) =>{
    const { page } = val
    setCurrentPage(page)
    dispatch(getFarmersOrders({
      id: query.id, page: page,
      campaignId: campaign.id
    }))
  }

  const getOrderBg = (val) => {
    let result
    switch (val) {
      case 'PENDING':
        result = 'missed'
        break;
      case 'DELIVERED':
        result = 'called'
        break;
      case 'PROCESSING':
        result = 'missed'
        break;
      case 'DISPATCHED':
       result = 'pending'
       break;
      case 'CANCELLED' :
        result= 'notcalled'
        break;
      default:
        result = 'noitem'
        break
    }
    return result
  }

  // const formatProductType = (val) =>{
  //   let result
  //   switch (val) {
  //     case 'crop_input':
  //       result = 'Crop'
  //       break;
  //     case 'livestock_input':
  //       result = 'Livestock'
  //       break;
  //     case 'advisory':
  //       result = 'Advisory'
  //       break;
  //     case 'insurance':
  //      result = 'Insurance'
  //      break;
  //     case 'mechanization' :
  //       result= 'Mechanization'
  //       break;
  //     default:
  //       result = 'No Type'
  //       break
  //   }
  //   return result
  // }

  const getOrderText = (val) =>{
    let result
    switch (val) {
      case 'PENDING':
        result = 'missedText'
        break;
      case 'DELIVERED':
        result = 'calledText'
        break;
      case 'PROCESSING':
        result = 'missedText'
        break;
      case 'DISPATCHED':
       result = 'pendingText'
       break;
      case 'CANCELLED' :
        result= 'notcalledText'
        break;
      default:
        result = 'noitemText'
        break
    }
    return result
  }


  const [callPage, setCallPage] = useState(1)

  const onNextCallChange = (val) =>{
    const { page } = val
    setCallPage(page)
    dispatch(getFarmersCalls({
      id: query.id, page: page,
      campaignId: campaign.id
    }))
   }



  const [complaint, setComplaint] = useState({})
  const [isComplainOpen, setIsComplainOpen] = useState(false)

  const [callInfo, setCallInfo] = useState({})
  const [isCallOpen, setIsCallOpen] = useState(false)

  const [orderInfo, setOrderInfo] = useState({})
  const [isOrderOpen, setIsOrderOpen] = useState(false)


  const toggleComplain = (id) =>{
    setIsComplainOpen(!isComplainOpen)
    // find the complaint selected
    let complaint = complaints.find((val) => val.id === id)
    setComplaint(complaint)
  }

  const closeComplain = () =>{
    setIsComplainOpen(!isComplainOpen)
  }

  const toggleOrder = (id) =>{
    setIsOrderOpen(!isOrderOpen)
    // find the order selected
    let order = orders.find((val)=> val.id === id)
    setOrderInfo(order)
  }

  const closeOrder = () =>{
    setIsOrderOpen(!isOrderOpen)
  }

  const handleCallToggle = (id) =>{
    setIsCallOpen(!isCallOpen)
    // finr the call selected
    let call = callHistory.find((val) => val.id === id)
    console.log(call)
    setCallInfo(call)
  }

  const closeCallInfo = () =>{
    setIsCallOpen(!isCallOpen)
  }


  const handleIncrement = (cartId, quantity) =>{
    dispatch(UpdateFarmersCart(
      {
        id:cartId,
        quantity: quantity + 1,
        campaignId: campaign.id
      }))
  }

  const handleDecrement = (cartId, quantity) =>{
    dispatch(UpdateFarmersCart(
      {
        id:cartId,
        quantity: quantity - 1,
        campaignId: campaign.id
      }))
  }

  const handleDelete = (id) =>{
    dispatch(deleteFarmersCart({
      id,
      campaignId: campaign.id,
    }))
  }

  const [show, setShow] = useState(false)
  const [showCodeModal, setShowCodeModal] = useState(false)

  const showModal = () =>{
    setShow(!show)
  }

  const codeModal = () =>{
    dispatch(resetShortCode())
    setShowCodeModal(!showCodeModal)
  }

 

  const handleState = (val)=>{
    // make api call to get LGAS under state
    dispatch(getLGAByStateID({
      id: val
    }))
  }
  

  const [deliveryInfo, setDeliveryInfo] = useState({})

  const handleCheckout = (values) =>{
    console.log(values)
    setDeliveryInfo(
      {
        ...values,
        stateName: states.find((val)=> val.id === values.stateID).name,
        lgaName: lgas.find((val)=> val.id === values.lgaID).name,
        
      }
    )
    setShow(true)
  }


  const handleConfirmOrder = () =>{
   
    // makes an api call to checkout the order added to cart for the farmer
      let resp = {
        farmerId: query.id,
        totalPrice: getTotalAmount().totalPrice,
        stateID: deliveryInfo.stateID,
        lgaID: deliveryInfo.lgaID,
        deliveryAddress: deliveryInfo.deliveryAddress,
        farmerPhonenumber: farmer.phone_number,
        campaignId: campaign.id
    }
    dispatch(createOrder({resp}))
 
  }

  useEffect(()=>{
    if(shortCode !== null){
      setShow(false)

      setTimeout(()=>{
        setShowCodeModal(true)
      },1000)
    }
  },[shortCode])

  useEffect(()=>{
    const {id} = query
    dispatch(getFarmerById({
      id
    }))
   dispatch(getCrops())
   dispatch(getLivestock())
   dispatch(getFarmersComplaints({
     id
   }))
   dispatch(getFarmersOrders({
     id, page: 1,
     campaignId: campaign.id
   }))
   dispatch(getFarmersCalls({
     id, page: 1,
     campaignId: campaign.id
   }))
   dispatch(getFarmersCart({
     id,
     campaignId: campaign.id
   }))
   dispatch(getStates())
  },[dispatch, query, campaign])
  
  

  const {
    values,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize:true,
    initialValues: {
      firstname:  farmer && farmer.first_name ? farmer.first_name : '',
      lastname :  farmer && farmer.last_name ? farmer.last_name : 'Lastname',
      phonenumber :  farmer && farmer.phone_number ? farmer.phone_number: 'Phonenumber',
      gender :  farmer && farmer.gender ? farmer.gender : 'Male',
      dateOfBirth :  farmer && farmer.date_of_birth ? moment(farmer.date_of_birth).format('DD-MM-YYYY') : 'DOB',
      createdAt :  farmer && farmer.created ? moment(farmer.created).format('DD-MM-YYYY') : 'Date Joined',
      age : farmer && farmer.date_of_birth ?  moment().diff(farmer.date_of_birth, 'years') : '0',
      farmerLGA : farmer && farmer.lga ? farmer.lga : 'LGA',
      farmerState : farmer && farmer.state  ? farmer.state: 'State',
      farm_size: farmer && farmer.farm_size ? farmer.farm_size : '0',
      selectedCropOption: farmer && farmer.crops ? crops.filter((el) => {
                  return farmer.crops.some((f) => {
                    return f.name === el.name && f.id.toString() === el.id
                  });
                }) : [],
      selectedLivestockOption:  farmer && farmer.livestock ? livestock.filter((el) => {
        return farmer.livestock.some((f) => {
          return f.name === el.name && f.id.toString() === el.id
        });
      }) : [],
     
    },
    onSubmit(values, { setSubmitting }) {
      handSubmit(values, setSubmitting);
    },
  });


const DropDown = props => {
    const options = props.multi
      ? [{ name: 'Select All', id: 'all' }, ...props.options]
      : props.options;
    return (
      <div>
        <Select
          classNamePrefix="select_control"
          closeMenuOnSelect={false}
          className="select_style"
          defaultValue={props.value}
          options={options}
          isMulti={props.multi}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          onChange={selected => {
            props.multi &&
            selected.length &&
            selected.find(option => option.id === 'all')
              ? props.handleDropdown(options.slice(1))
              : !props.multi
              ? props.handleDropdown((selected && selected.value) || null)
              : props.handleDropdown(selected);
          }}
        />
      </div>
    );
  };


  const handleCrop = value => {
    setFieldValue("selectedCropOption", value)
    let cropArray = [];
    value.map(o => cropArray.push(o.id));
    console.log(cropArray)
  };

  const handleLivestock = value => {
    setFieldValue("selectedLivestockOption", value)
    let livestockArray = [];
    value.map(o => livestockArray.push(o.id));
    console.log(livestockArray)
  };

  const handSubmit = (values) =>{
    let cropArray = [];
    let livestockArray = [];
    values.selectedCropOption.map(o => cropArray.push(o.id));
    values.selectedLivestockOption.map(o => livestockArray.push(o.id));
    const creds = {
      crop: cropArray,
      livestock: livestockArray,
      id: query.id
    }
    dispatch(updateFarmersProfile(creds))
  }

  // ------- cart total amount calculation
  const getTotalAmount = () => {
    let totalPrice = 0
    cart.forEach(item => {
      totalPrice += item.total_item_price * item.quantity
    })
    return {
      totalPrice
    }
  }

   
  return (

    <div className='mt-3 pb-10'>

  {/* checkout confirmation modal */}
    <Modal show={show} showModal={showModal} 
       title="Confirm Order"
       subtitle="Please confirm the order before sending it"
       >
            {/* products display and address */}
            <div className='mt-6'>
              <div className='bg-[#F7F7F7] rounded-lg py-3 px-3'>
                <p className='text-[#96A397] text-xs font-medium'>Delivery Address</p>
                <h4 className='text-[#344335] text-sm font-medium'>{deliveryInfo && deliveryInfo.deliveryAddress ? deliveryInfo.deliveryAddress: ''}</h4>
                <h6 className='text-[#344335] text-xs font-medium'>{deliveryInfo && deliveryInfo.stateName ? deliveryInfo.stateName: ''} | {deliveryInfo && deliveryInfo.lgaName ? deliveryInfo.lgaName: ''}</h6>
              </div>

                {/* items */}

                {
                  cart && cart.length > 0 ?
                  cart.map((item, index) =>(
                    <div key={index} className='flex mt-3 mb-4 items-center justify-between'>
                    <div>
                        <h6 className='text-[#344335] text-sm font-semibold'>{item.product_name}</h6>
                        <p className='text-[#344335] text-sm font-medium'>{item.vendor_name}</p>
                        <p className='text-[#344335] text-sm font-medium'><span className='text-[#96A397] text-sm font-medium mr-1'>Qty:</span>{item.quantity}</p>
                    </div>
                    <div>
                        <p className='text-[#344335] text-sm font-medium'><i className='mdi mdi-currency-ngn'></i>{item.total_item_price}</p>
                    </div>  
                  </div>
                  ))
                  :
                  ""
                  }

                <div className='bg-[#ECEEEC] w-full h-[2px]'></div>

                <div className='text-right'>
                  <h4 className='text-primary font-semibold text-lg'>₦{getTotalAmount().totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h4>
                </div>

                <div className='mt-8'>
                  <button 
                     disabled={isCheckoutLoader}
                    onClick={handleConfirmOrder}
                    className='bg-primary 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg w-full text-white py-3 px-6 text-sm'>Confirm Order</button>
                </div>
            </div>
     </Modal>

  {/* Code Modal for an order  */}
  <Modal show={showCodeModal} showModal={codeModal} 
       title=""
       subtitle=""
       >
            {/* SMS */}
            <div className='mt-8'>
                <div className='flex justify-center'>
                  <img src={cart_icon} alt="cart" />
                </div>

                <div className='text-center mt-2'>
                  <h4 className='text-[#344335] font-semibold text-lg'>Order has been sent</h4>
                    <p className='text-[#96A397] text-xs font-medium'>A Short Code has been sent to complete the order</p>
                  <h3 className='text-primary font-semibold text-xl mt-3'>*347*46*{shortCode}#</h3>

                 <div  className='flex justify-center'>
                  <div className='mt-5 flex gap-1 items-center bg-[#E7FBFE] rounded-[4px] py-2 px-3'>
                      <img src={info_icon} alt="info" />
                      <p className='text-[#0066AF] text-xs font-medium'>You can track the payment status on the order history</p>
                  </div>  
                  </div>

                </div>

                <div className='mt-10 mb-8 flex justify-center'>
                  <button 
                  onClick={codeModal}
                    className='text-[#5C715E] font-medium text-sm'>Close</button>
                </div>
            </div>
     </Modal>

  {/* complaints drawer */}
  <DrawerComponent title="Complaint" show={isComplainOpen} showDrawer={closeComplain} >
  
      <div className='mt-8'>
        <h5 className="text-xs text-secondary1">Created by</h5>
        <p className='font-medium text-secondary1 text-sm mt-1'>{complaint && complaint.user ? capitalizeFirstLetter(complaint.user.first_name): 'N/A'} {complaint && complaint.user ? capitalizeFirstLetter(complaint.user.last_name) : "N/A"}</p>
      </div>

      <div className='mt-8'>
        <h5 className="text-xs text-secondary1">Status</h5>
            <div className='flex mt-2'>
            <div className=
            {
              complaint && complaint.complaint_status && (complaint.complaint_status === 'unresolved' || complaint.complaint_status === 'UNRESOLVED') ?
              'flex items-center bg-[#FDDED4] rounded-[4px] px-3 py-1'
              :
              'flex items-center bg-[#DBFFDF] rounded-[4px] px-3 py-1'
            }
            >
                <p className=
                  {
                  complaint && complaint.complaint_status &&  (complaint.complaint_status === 'unresolved' || complaint.complaint_status === 'UNRESOLVED') ?
                  'text-[#B92043] text-sm font-medium'
                  :
                  'text-primary text-sm font-medium'
                }
                  >{complaint && complaint.complaint_status ? capitalizeFirstLetter(complaint.complaint_status) : "N/A"}</p> 
              </div>
            </div>
      </div>

      <div className='mt-8'>
        <h5 className="text-xs text-secondary1">Date</h5>
        <p className='font-medium text-secondary1 text-sm mt-1'>{complaint && complaint.complaint_date ? moment(complaint.complaint_date).format('Do, MMM YYYY'): "N/A"}</p>
      </div>

      <div className='mt-8'>
        <h5 className="text-xs text-secondary1">Time</h5>
        <p className='font-medium text-secondary1 text-sm mt-1'>{complaint && complaint.complaint_date ? moment(complaint.complaint_date).format('hh:mm:ss a'): "N/A"}</p>
      </div>

      <div className='mt-8'>
        <label htmlFor="message" className="block mb-2 text-xs font-medium text-secondary1">Complaint</label>
        <textarea id="message" rows="8" 
        value={complaint && complaint.complaint ? complaint.complaint: ""}
        disabled
          className='appearance-none w-full
        placeholder:text-[#96A397] placeholder:text-sm
          text-secondary1
          text-sm
          focus:border-[#2B8C34] focus:outline-none  
          focus:shadow-input rounded-lg border border-[#96A397]
          bg-white py-3 px-4' placeholder="Leave a comment..."></textarea>
      </div>  

  </DrawerComponent>

  {/* order info drawer */}
  <DrawerComponent title="Order Info" show={isOrderOpen} showDrawer={closeOrder} >

  <div className='mt-8'>
      <h5 className="text-xs text-secondary1">Order ID</h5>
      <p className='font-medium text-secondary1 text-sm mt-1'>{orderInfo && orderInfo.id ? orderInfo.id: 'N/A' }</p>
    </div>

    <div className='mt-8'>
      <h5 className="text-xs text-secondary1">USSD Short Code</h5>
      <p className='font-medium text-secondary1 text-sm mt-1'>{orderInfo && orderInfo.short_code ? `*347*46*${orderInfo.short_code}#` : '*347*46*4#' }</p>
    </div>

    <div className='mt-8'>
      <h5 className="text-xs text-secondary1">Date</h5>
      <p className='font-medium text-secondary1 text-sm mt-1'>{orderInfo && orderInfo.created ? moment(orderInfo.created).format('Do, MMM YYYY'): "N/A"}</p>
    </div>

    <div className='mt-8'>
      <h5 className="text-xs text-secondary1">Time</h5>
      <p className='font-medium text-secondary1 text-sm mt-1'>{orderInfo && orderInfo.created ? moment(orderInfo.created).format('hh:mm:ss a'): "N/A"}</p>
    </div>
  
    <div className='mt-8'>
        <h5 className="text-xs text-secondary1">Total Cost</h5>
        <p className='font-medium text-secondary1 text-sm mt-1'>₦{orderInfo && orderInfo.total_display_price ? orderInfo.total_display_price : '0' }</p>
      </div>

      <div className='mt-8'>
              <h5 className="text-xs text-secondary1">Farmer's Name</h5>
              <p className='font-medium text-secondary1 text-sm mt-1'>{orderInfo && orderInfo.farmer ? `${orderInfo.farmer.first_name} ${orderInfo.farmer.last_name}` : 'N/A' }</p>
       </div>

        <div className='mt-8'>
          <h5 className="text-xs text-secondary1">Farmer's Contact</h5>
          <p className='font-medium text-secondary1 text-sm mt-1'>{orderInfo && orderInfo.farmer ? `${orderInfo.farmer.phone_number}` : 'N/A' }</p>
        </div>


    <div className='mt-8'>
      <h5 className="text-xs text-secondary1">Item(s) </h5>
      {/* input orders */}
      {
        orderInfo && orderInfo.input_orders
        ?
        orderInfo.input_orders.length > 0 ?
        orderInfo.input_orders.map((val, index)=>(
         <div key={index} className='flex items-center gap-4 mb-3'>
          <div>
            <p  className='font-medium text-secondary1 text-sm'>{val && val.input_product ?val.input_product.name: 'N/A'} | Qty: {val.quantity} | ₦{val && val.input_product ? val.input_product.display_price: 'N/A'}</p>
          </div>
          <div className='flex'>
            <div className={getOrderBg(val && val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
            >
                <p className={getOrderText( val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                  >{ val.order_status ? capitalizeFirstLetter(val.order_status[val.order_status.length - 1]) : 'PENDING'}</p> 
              </div>
          </div>
         </div>
        ))
        :
        ""
        :
        ""
      }
      {/* advisory orders */}
      {
        orderInfo && orderInfo.advisory_order
        ?
        orderInfo.advisory_order.length > 0 ?
        orderInfo.advisory_order.map((val, index)=>(
          <div key={index} className='flex items-center gap-4 mb-3'>
              <div>
                <p className='font-medium text-secondary1 text-sm'>{val.advisory_product.name} | Qty: {val.quantity} | ₦{val.advisory_product.display_price}</p>
              </div>
              <div className='flex'>
                <div className={getOrderBg(val && val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                >
                    <p className={getOrderText( val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                      >{ val.order_status ? capitalizeFirstLetter(val.order_status[val.order_status.length - 1]) : 'PENDING'}</p> 
                  </div>
              </div>
           </div>
        ))
        :
        ""
        :
        ""
      }

      {/* mechanization order */}
      {
        orderInfo && orderInfo.mechanization_order
        ?
        orderInfo.mechanization_order.length > 0 ?
        orderInfo.mechanization_order.map((val, index)=>(
          <div key={index} className='flex items-center gap-4 mb-3'>
          <div>
           <p  className='font-medium text-secondary1 text-sm '>{val.mechanization_product.name} | Qty: {val.quantity} | ₦{val.mechanization_product.display_price}</p>
          </div>
          <div className='flex'>
            <div className={getOrderBg(val && val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
            >
                <p className={getOrderText( val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                  >{ val.order_status ? capitalizeFirstLetter(val.order_status[val.order_status.length - 1]) : 'PENDING'}</p> 
              </div>
          </div>
       </div>
        ))
        :
        ""
        :
        ""
      }

      {/* insurance orders */}
      {
        orderInfo && orderInfo.insurance_order
        ?
        orderInfo.insurance_order.length > 0 ?
        orderInfo.insurance_order.map((val, index)=>(
         <div key={index} className='flex items-center gap-4 mb-3'>
          <div>
            <p  className='font-medium text-secondary1 text-sm'>{val.insurance_product.name} | Qty: {val.quantity} | ₦{val.insurance_product.display_price}</p>
          </div>
          <div className='flex'>
            <div className={getOrderBg(val && val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
            >
                <p className={getOrderText( val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                  >{ val.order_status ? capitalizeFirstLetter(val.order_status[val.order_status.length - 1]) : 'PENDING'}</p> 
              </div>
          </div>
        </div>
        ))
        :
        ""
        :
        ""
      }

      {/* livestock orders */}
      {
        orderInfo && orderInfo.livestock_order
        ?
        orderInfo.livestock_order.length > 0 ?
        orderInfo.livestock_order.map((val, index)=>(
          <div className='flex items-center gap-4 mb-3'>
            <div>
                <p key={index} className='font-medium text-secondary1 text-sm'>{val.input_product_name} | Qty: {val.quantity} | ₦{val.total_display_price}</p>
           </div>
           <div className='flex'>
                <div className={getOrderBg(val && val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                >
                    <p className={getOrderText( val.order_status ? val.order_status[val.order_status.length - 1]: 'PENDING')}
                      >{ val.order_status ? capitalizeFirstLetter(val.order_status[val.order_status.length - 1]) : 'PENDING'}</p> 
                  </div>
              </div>
          </div>
        ))
        :
        ""
        :
        ""
      }
    
    </div>

    <div className='mt-8'>
      <h5 className="text-xs text-secondary1">Payment Status</h5>
          <div className='flex mt-2'>
          <div className=
              {
                orderInfo && orderInfo.payment_status ? orderInfo.payment_status ?
                'called'
                :
                'notcalled'
                :
                'notcalled'
              }
              >
              <p className=
                 {
                  orderInfo && orderInfo.payment_status ? orderInfo.payment_status ?
                  'calledText'
                  :
                  'notcalledText'
                  :
                  'notcalledText'
                }
                >{orderInfo && orderInfo.payment_status ? orderInfo.payment_status ? 'Paid': 'Unpaid': 'Unpaid'}</p> 
            </div>
          </div>
    </div>

  </DrawerComponent>


  {/* call History Drawer */}
  <DrawerComponent title="Call Info" show={isCallOpen} showDrawer={closeCallInfo} >

      <div className='mt-8'>
          <h5 class="text-xs text-secondary1">Call ID</h5>
          <p className='font-medium text-secondary1 text-sm mt-1'>{callInfo && callInfo.id ? callInfo.id: 'N/A'}</p>
        </div>
     
        <div className='mt-8'>
          <h5 class="text-xs text-secondary1">Date</h5>
          <p className='font-medium text-secondary1 text-sm mt-1'>{callInfo && callInfo.created ? moment(callInfo.created).format('Do, MMM YYYY'): 'N/A'}</p>
        </div>

        <div className='mt-8'>
          <h5 class="text-xs text-secondary1">Time</h5>
          <p className='font-medium text-secondary1 text-sm mt-1'>{callInfo && callInfo.created ? moment(callInfo.created).format('hh:mm:ss a'): 'N/A'}</p>
        </div>


        <div className='mt-8'>
          <h5 className="text-xs text-secondary1">Call Duration</h5>
          <p className='font-medium text-secondary1 text-sm mt-1'>{callInfo && callInfo.call_duration ? formatDuration(callInfo.call_duration): 'N/A'}</p>
        </div>

        <div className='mt-8'>
          <label htmlFor="message" className="block mb-2 text-xs font-medium text-secondary1">Call Note</label>
          <textarea id="message" rows="8" 
          value={callInfo && callInfo.note ? callInfo.note ? callInfo.note.note : "": ""}
          className='appearance-none w-full
          placeholder:text-[#96A397] placeholder:text-sm
            text-secondary1
            text-sm
            focus:border-[#2B8C34] focus:outline-none  
            focus:shadow-input rounded-lg border border-[#96A397]
            bg-white py-3 px-4' placeholder="Leave a comment..." disabled></textarea>
        </div> 

        <div className='mt-4'>
          <button disabled type="button" className="text-white bg-primary py-3 px-6
                          hover:bg-[#24752B] transition-all
                          disabled:bg-[#ABB6AC] 
                  rounded-lg text-sm flex " > Save</button>
        </div>  

</DrawerComponent>

    
      <div>
        <Breadcrumb breadcrumb={breadcrumb} />
      </div>

      {/* GO BACK SECTION */}
      <Link to={`/home/${campaign.id}`} className="flex items-center cursor-pointer mt-[25px]">
        <img src={go_back} alt="go back" className="w-[20px] h-[20px]" />
        <p className="text-sm font-semibold text-[#5C715E] ml-[4px] ">
          Go Back
        </p>
      </Link>
      <div className="bg-white rounded-[8px] mt-[42px] md:mt-[22px] lg:mt-[22px] pt-6 pl-6 pr-6 pb-2">
        <div className="block md:flex lg:flex justify-between items-center">
          <p className="font-semibold text-xl text-[#1D5D23]">
            Farmer Profile
          </p>
          <div className="flex gap-4 my-4 lg:my-0 ">
            <Link to={`/callfarmer/${query.id}`} className="py-3 px-6 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all">
              Initiate Call
            </Link>
            {/* <button disabled className="py-3 px-6 bg-white rounded-[8px]  border border-[#2B8C34] text-[#2B8C34]  text-sm font-medium  hover:bg-[#EDF7EE] hover:text-primary  transition-all">
              Create Order
            </button> */}
          </div>
        </div>

        {/* TAB */}
        <div className="flex mt-8 cursor-pointer">
          <p
            className={
              tabValue === 'Account Information'
                ? 'text-sm font-semibold  text-[#2B8C34] border-b-[#2B8C34] border-b-2'
                : 'text-sm font-semibold text-[#96A397]'
            }
            onClick={() => setTabValue('Account Information')}
          >
            Account Information
          </p>
          <p
            className={
              tabValue === 'Call History'
                ? 'text-sm font-semibold text-[#2B8C34] border-b-[#2B8C34] border-b-2 ml-[24px]'
                : 'text-sm font-semibold text-[#96A397] ml-[24px]'
            }
            onClick={() => setTabValue('Call History')}
          >
            Call History
          </p>
          <p
            className={
              tabValue === 'Order History'
                ? 'text-sm font-semibold text-[#2B8C34] border-b-[#2B8C34] border-b-2 ml-[24px]'
                : 'text-sm font-semibold text-[#96A397] ml-[24px]'
            }
            onClick={() => setTabValue('Order History')}
          >
            Order History
          </p>
          <p
            className={
              tabValue === 'Complaints'
                ? 'text-sm font-semibold text-[#2B8C34] border-b-[#2B8C34] border-b-2 ml-[24px]'
                : 'text-sm font-semibold text-[#96A397] ml-[24px]'
            }
            onClick={() => setTabValue('Complaints')}
          >
            Complaints
          </p>
          <div className='relative'>
          <p
            className={
              tabValue === 'Cart'
                ? 'text-sm font-semibold text-[#2B8C34] border-b-[#2B8C34] border-b-2 ml-[24px]'
                : 'text-sm font-semibold text-[#96A397] ml-[24px]'
            }
            onClick={() => setTabValue('Cart')}
          >
            Cart({cart.length})
            <span className=
            {
              cart && cart.length > 0 
              ?
              "w-[6px] h-[6px] rounded-full bg-[#B92043] absolute right-0 top-0 animate-ping"
              :
              ""
            }
            ></span>
          </p>
          </div>
        </div>
      </div>
      <div>
        {tabValue === 'Account Information' ? (      

        <form onSubmit={handleSubmit}> 
          <div>

            <div className="mt-[20px] pt-6 pl-6 pr-6 pb-10 bg-white rounded-[8px] text-[#7C8D7E]">
                  
              {/* Personal Information */}
              <p className="font-semibold text-sm">Personal Details</p> 
               <div className="grid md:grid-cols-3 md:gap-4 mt-[16px]">
                  <div>
                    <p className="font-medium text-sm text-[#96A397]">
                      First name
                    </p>
                    <input
                      type="text"
                      id="firstname"
                      className="outline-none text-[#ABB6AC]
                       w-full bg-[#F7F7F7] h-[48px] px-3 rounded-[8px] 
                       placeholder:text-[#ABB6AC] text-sm font-medium
                       border-[#ABB6AC] border mt-1 placeholder:text-sm placeholder:font-medium"
                      placeholder="Abubakar"
                      disabled
                      value={values.firstname}
                    />
                  </div>
                  <div className="mt-2 md:mt-0">
                    <p className="font-medium text-sm text-[#96A397]">
                      Last name
                    </p>
                    <input
                      type="text"
                      id="lastname"
                      className="outline-none  
                      text-[#ABB6AC] w-full
                       bg-[#F7F7F7] h-[48px] px-3 rounded-[8px]
                       placeholder:text-[#ABB6AC] text-sm font-medium
                        border-[#ABB6AC] border mt-1 placeholder:text-sm placeholder:font-medium"
                      placeholder="Jesam"
                      disabled
                      value={values.lastname}
                    />
                  </div>
                  <div className="mt-2 md:mt-0">
                    <p className="font-medium text-sm text-[#96A397]">
                      Phone number
                    </p>
                    <input
                      type="text"
                      id="phonenumber"
                      className="outline-none 
                      text-[#ABB6AC] w-full bg-[#F7F7F7]
                        h-[48px] px-3 rounded-[8px] border-[#ABB6AC]
                        placeholder:text-[#ABB6AC] text-sm font-medium
                         border mt-1 placeholder:text-sm placeholder:font-medium"
                      placeholder="+234553789837"
                      disabled
                      value={values.phonenumber}
                    />
                  </div>
              </div>

              <div className='grid md:grid-cols-3 md:gap-4 mt-[25px]'>

                <div className="">
                    <p className="font-medium text-sm text-[#96A397]">Gender</p>
                    <input
                      type="text"
                      id="gender"
                      className="outline-none
                      text-[#ABB6AC] w-full bg-[#F7F7F7] 
                       placeholder:text-[#ABB6AC] text-sm font-medium
                        h-[48px] px-3 rounded-[8px] border-[#ABB6AC] border mt-1 placeholder:text-sm placeholder:font-medium"
                      placeholder="Male"
                      disabled
                      value={values.gender === 'f' ? 'Female' : 'Male'}
                    />
                </div>

                 <div className='col-span-2'>
                   
                   <div className="flex gap-4">
                    <div className="">
                      <p className="font-medium text-sm text-[#96A397]">
                        Date of birth
                      </p>
                      <input
                        type="text"
                        id="dateOfBirth"
                        className="outline-none 
                        text-[#ABB6AC] w-full bg-[#F7F7F7] text-sm font-medium
                         h-[48px] px-3 rounded-[8px] border-[#ABB6AC]
                          border mt-1 placeholder:text-sm placeholder:font-medium"
                        disabled
                        value={values.dateOfBirth}
                      />
                    </div>
                    <div className="">
                      <p className="font-medium text-sm text-[#96A397]">Age</p>
                      <input
                        type="text"
                        id="age"
                        className="outline-none 
                        text-[#ABB6AC] w-full text-sm font-medium
                         bg-[#F7F7F7] h-[48px] px-3 rounded-[8px] border-[#ABB6AC] border mt-1 placeholder:text-sm placeholder:font-medium"
                        placeholder="45"
                        disabled
                        value={values.age}
                      />
                    </div>
                    <div className="">
                      <p className="font-medium text-sm text-[#96A397]">Date Joined</p>
                      <input
                        type="text"
                        id="createdAt"
                        className="outline-none 
                        text-[#ABB6AC] w-full text-sm font-medium
                         bg-[#F7F7F7] h-[48px] px-3 rounded-[8px] border-[#ABB6AC]
                          border mt-1 placeholder:text-sm placeholder:font-medium"
                        placeholder="DD-MM-YYYY"
                        disabled
                        value={values.createdAt}
                      />
                    </div>
                  </div>



                 </div>

              </div>

              {/* Farm Details */}
              <div>
                <p className="font-semibold text-sm mt-[36px]">Farm Details</p>

                <div className="mt-[20px] flex gap-4">

                  <div className="w-1/3">
                      <p className="font-medium text-sm text-[#344335]">Crops</p>
                      <div className='mt-1'>
                        <DropDown
                          value={values.selectedCropOption}
                          options={crops}
                          handleDropdown={handleCrop}
                          multi={true}
                        />
                    </div>
                  </div>

                  <div className="">
                    <div className="">
                      <p className="font-medium text-sm text-[#344335]">
                        Farm Size
                      </p>
                      <div className='relative'>
                        <input
                          type="text"
                          className="outline-none
                          text-[#ABB6AC]
                          h-[48px] px-3 rounded-[8px] 
                          text-sm w-full
                          border-[#ABB6AC] border mt-1 placeholder:text-sm placeholder:font-medium"
                          placeholder="0"
                          disabled
                          value={values.farm_size}
                        />
                       
                      </div>
                    </div>
                  </div>

                  <div className="w-1/3">
                    <p className="font-medium text-sm text-[#344335]">Livestock</p>
                      <div className='mt-1'>
                        <DropDown
                          value={values.selectedLivestockOption}
                          options={livestock}
                          handleDropdown={handleLivestock}
                          multi={true}
                        />
                    </div>
                  </div>


                </div>
              </div>

              {/* Farm Details */}
              <div>
                <p className="font-semibold text-sm mt-[36px]">Location</p>

                <div className="mt-[20px] flex">
                  <div className="w-1/3">
                    <p className="font-medium text-sm text-[#96A397]">State</p>
                    <input
                      type="text"
                      id="farmerState"
                      className="outline-none text-sm font-medium
                      text-[#ABB6AC] w-[95%] bg-[#F7F7F7] h-[48px] px-3 rounded-[8px] border-[#ABB6AC] border mt-1 placeholder:text-sm placeholder:font-medium"
                      placeholder="Oyo"
                      disabled
                      value={values.farmerState}
                    />
                  </div>
                  <div className="w-1/3">
                    <div className="">
                      <p className="font-medium text-sm text-[#96A397]">
                        Local Government
                      </p>
                      <input
                        type="text"
                        id="farmerLGA"
                        className="outline-none text-sm font-medium
                        text-[#ABB6AC] w-[95%] bg-[#F7F7F7] h-[48px] px-3 rounded-[8px] border-[#ABB6AC] border mt-1 placeholder:text-sm placeholder:font-medium"
                        placeholder="Ibadan North"
                        disabled
                        value={values.farmerLGA}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[24px] mb-[30px] flex items-center justify-end">
              <button 
              type="submit"
              disabled={updateLoader}
              className="ml-[24px] 
              disabled:bg-[#ABB6AC] 
              bg-[#2B8C34] text-white rounded-[8px] text-sm font-medium py-3 px-6 hover:bg-[#24752B] transition-all">
                Save Changes
              </button>
            </div>
          </div>
          </form>

        ) : tabValue === 'Call History' ? (
            <div>
               <div className='mt-5 bg-white rounded-lg px-6 py-4'>
                 <h4 className='text-[#7C8D7E] font-semibold text-sm'>Call History</h4>
               </div>
              {/* tables */}
                <div className="overflow-x-auto relative mt-2 sm:rounded-tr-lg sm:rounded-tl-lg">
                  <table className="w-full text-left">
                   
                      <thead className="text-primary bg-white text-sm border-b-[#F7F7F7] border-b-2">
                          <tr>
                              <th scope="col" className="py-4 px-6 font-semibold">
                                 Call ID                              </th>
                              <th scope="col" className="py-4 px-6 font-semibold">
                                Date
                              </th>
                              <th scope="col" className="py-4 px-6 font-semibold">
                                Time 
                              </th>
                              <th scope="col" className="py-4 px-6 font-semibold">
                               Call Duration
                              </th>
                          </tr>
                      </thead>

                       {!callLoader && (
                          <tbody>
                            {callHistory.length > 0
                              ? callHistory.map((item) => (
                               <tr key={item.id} className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]" onClick={() => handleCallToggle(item.id)}>
                              <th scope="row" className="py-4 px-6 font-normal text-secondary1 whitespace-nowrap">
                                 {item.id}
                              </th>
                             
                              <td className="py-4 px-6 text-secondary1">
                              {moment(item.created).format('Do, MMM YYYY')}
                              </td>
                              <td className="py-4 px-6 text-secondary1">
                                {moment(item.created).format('hh:mm:ss a')}
                              </td>
                              <td className="py-4 px-6 text-secondary1">
                                {formatDuration(item.call_duration)}
                              </td>
                                  </tr>
                                ))
                              : ''}
                          </tbody>
                        )}
                  </table>

                  {!callLoader && callHistory.length === 0 ? (
                    <div className="text-center mt-16">
                      <p className="text-[#5C715E] font-medium text-base">
                      The farmer hasn’t been called yet
                      </p>
                    </div>
                  ) : (
                    ''
                  )}

                {!callLoader && callHistory.length > 0 ? (
                      <div className="mt-1">
                        <PaginationComponent
                          page={callPage}
                          onPageChange={onNextCallChange}
                          pageSize={10}
                          totalItems={callCount}
                        />
                      </div>
                    ) : (
                      ''
                     )}



              {callLoader && (
                    <div className="animate-pulse w-full mt-4">
                      <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                      <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                      <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                      <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                      <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                    </div>
                  )}


                </div>
            </div>
        ) : tabValue === 'Order History' ? (
          <div>
          <div className='mt-5 bg-white rounded-lg px-6 py-4'>
            <h4 className='text-[#7C8D7E] font-semibold text-sm'>Order History</h4>
          </div>
         {/* tables */}
           <div className="overflow-x-auto relative mt-2 sm:rounded-tr-lg sm:rounded-tl-lg">
             <table className="w-full text-left">
              
                 <thead className="text-primary bg-white text-sm border-b-[#F7F7F7] border-b-2">
                     <tr>
                         <th scope="col" className="py-4 px-6 font-semibold">
                          Order ID 
                         </th>
                         
                         <th scope="col" className="py-4 px-6 font-semibold">
                           Cost
                         </th>
                         <th scope="col" className="py-4 px-6 font-semibold">
                          Date
                         </th>
                         <th scope="col" className="py-4 px-6 font-semibold">
                          Time
                         </th>
                         <th scope="col" className="py-4 px-6 font-semibold">
                         USSD Short Code
                         </th>  
                         <th scope="col" className="py-4 px-6 font-semibold">
                           Payment Status
                         </th>
                         
                     </tr>
                 </thead>

                 {!orderLoader && (
                  <tbody>
                    {orders.length > 0
                      ? orders.map((item) => (
                        <tr key={item.id} className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]" onClick={() => toggleOrder(item.id)}>
                         <th scope="row" className="py-4 px-6 font-normal text-secondary1 whitespace-nowrap">
                             {item.id}
                         </th>
                        
                         <td className="py-4 px-6 text-secondary1">
                             ₦{item.total_display_price}
                         </td>
                         <td className="py-4 px-6 text-secondary1">
                          {moment(item.created).format('Do, MMM YYYY')}
                         </td>
                         <td className="py-4 px-6 text-secondary1">
                          {moment(item.created).format('hh:mm:ss a')}
                         </td>  
                         <td className="py-4 px-6 text-secondary1">
                           {`*347*46*${item.short_code}#`}
                         </td>   
                         <td className="py-4 px-6">
                         <div className='flex'>
                             <div className={
                               item.payment_status ?
                               'called'
                               :
                               'notcalled'
                             }
                             >
                               <p className={
                                 item.payment_status ?
                                 'calledText'
                                 :
                                 'notcalledText'
                               }
                               >
                                 {
                                   item.payment_status ? 'Paid': 'Unpaid'
                                 }</p> 
                             </div>
                           </div>     
                         </td>
                      
                    </tr>
                        ))
                      : ''}
                  </tbody>
                )}
             </table>

             {!orderLoader && orders.length === 0 ? (
                <div className="text-center mt-16">
                  <p className="text-[#5C715E] font-medium text-base">
                     The farmer hasn’t made any orders yet
                  </p>
                </div>
              ) : (
                ''
              )}


            {!orderLoader && orders.length > 0 ? (
                <div className="mt-1">
                  <PaginationComponent
                    page={currentPage}
                    onPageChange={onNextOrderChange}
                    pageSize={10}
                    totalItems={ordersCount}
                  />
                </div>
              ) : (
                ''
              )}

           {orderLoader && (
                <div className="animate-pulse w-full mt-4">
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                </div>
              )}


           </div>
       </div>
        ) : tabValue === 'Complaints' ?
        (
          <div>

              <div className='mt-5 grid md:grid-cols-3 gap-4'>
                {
                  complaints && complaints.length > 0 ? complaints.map((item, index)=>(
                    <div key={index} className='bg-white rounded-lg py-4 px-6'>
                        <div className='flex justify-between items-center'>
                          <p className='text-secondary1 font-semibold text-sm'>{capitalizeFirstLetter(item.user.first_name)} {capitalizeFirstLetter(item.user.last_name)}</p>
                          <p className='text-secondary2 font-medium text-xs'>{moment(item.complaint_date).format('Do, MMM YYYY, hh:mm:ss a')}</p>
                        </div>
                        <div className='mt-3'>
                          <p className='text-secondary1 text-sm font-medium leading-5'>{item.complaint}</p>
                        </div>
                        <div className='mt-2'>
                          <p onClick={() => toggleComplain(item.id)} className='text-primary font-semibold text-xs cursor-pointer' >See More</p>
                        </div>
                    </div>
                  ))
                  :
                 ""
                }
              </div>

               {complaints.length === 0 &&  
                  <div className='mt-16 text-center'>
                      <p className='text-[#5C715E] font-medium text-base'>The farmer hasn’t made any complaints yet</p>
                  </div>
                  }

           
            </div>
        ):
          tabValue === 'Cart' ?
        (
          <>

              {
                cart && cart.length > 0 ? 
                <div className='mt-5'>
                <div className='grid md:grid-cols-3 gap-4'>
                  <div className='bg-white rounded-lg py-6 px-6 col-span-2 h-fit'>
                    <p className='text-[#7C8D7E] text-sm font-semibold'>Item Details</p>

                    {
                      cart && cart.length > 0 ?
                      cart.map((item, index) =>(
                        <div key={index} className='flex mt-5 mb-6 justify-between items-start'>
                           <div className='flex'>
                                <div>
                                    <h6 className='text-[#344335] text-sm font-semibold'>{item.product_name}</h6>
                                    <p className='text-[#344335] text-sm mt-1'>
                                      {item.vendor_name}
                                    </p>
                                    {/* quantity counter */}
                                    <div className='flex items-center gap-2 mt-2'>
                                      <div>
                                        <div className='flex justify-between items-center min-w-[108px] bg-[#ECEFEC] rounded-lg py-2 px-3 '>
                                            <div 
                                              className={        
                                                item.quantity === 1 || processloader.indexOf(item.id)!== -1 ?
                                                'pointer-events-none'
                                                :
                                                'cursor-pointer'
                                              }
                                              onClick={() => handleDecrement(item.id, item.quantity)}
                                              >
                                              <img src={
                                                    item.quantity === 1 ||  processloader.indexOf(item.id)!== -1  ? minus_icon : minus}
                                                  alt="minus" className="w-[14px] h-[14px]" />
                                            </div>
                                            <div>
                                              <p className='text-[#344335] text-sm font-semibold'>{item.quantity}</p>
                                            </div>
                                            <div className={        
                                                processloader.indexOf(item.id)!== -1 ?
                                                'pointer-events-none'
                                                :
                                                'cursor-pointer'
                                              }
                                              onClick={() => handleIncrement(item.id, item.quantity)}>
                                              <img 
                                                 src={
                                                  processloader.indexOf(item.id)!== -1 ? plus_icon : plus_active}
                                                className="w-[14px] h-[14px]"  alt="plus" />
                                            </div>
                                        </div>
                                        </div>
                                        <img src={line_icon} alt="line" />
                                        <button 
                                           onClick={() => handleDelete(item.id)}
                                           disabled={deleteLoader}
                                        className='text-[#9B7000] 
                                         disabled:text-[#9B7000] disabled:opacity-[0.4]
                                        text-sm font-medium'>Delete Item</button>
                                    </div>
                                </div>
                            </div>

                            <div>
                              <h6 className='text-[#344335] text-sm font-medium'>₦{item.total_item_price}/{item.product_size}{item.product_measurement}</h6>
                            </div>
                        </div>
                      ))
                      :
                      ""
                    }

                  </div>

                  <div>
                    
                  <Formik
                    onSubmit={(values, { setSubmitting }) =>
                          handleCheckout(values, setSubmitting)
                        }
                        enableReinitialize={true}
                        validationSchema={locationValidator}
                        initialValues={{
                          stateID:  farmer && farmer.state_id ? farmer.state_id : "",
                          lgaID: farmer && farmer.lga_id ? farmer.lga_id : "",
                          deliveryAddress: ""
                        }}
                      >
                        {({
                          handleChange,
                          isSubmitting,
                          handleSubmit,
                          handleBlur,
                          values,
                          touched,
                          errors,
                          setFieldValue
                       }) => (
                    <Form onSubmit={handleSubmit}>
                      {/* delivery address section */}
                      <div className='bg-white rounded-lg py-6 px-4'>
                        
                        <label>
                              <span className="after:content-['*'] 
                                after:ml-0.5 after:text-[#B92043] text-[#7C8D7E] text-sm font-semibold">
                                  Delivery Address
                                </span>
                          </label>
                    
                        <div className="grid md:grid-cols-2 md:gap-6 mt-4">
                          <div>
                            <div className='relative'>
                            <select
                              name="stateID"
                              placeholder="Choose a State"
                              className='appearance-none cursor-pointer w-full placeholder:text-[#96A397]
                               placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34]
                                focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-3'
                                  value={values.stateID}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(
                                        e,
                                        setFieldValue("lgaID", "")
                                      );
                                    handleState(e.currentTarget.value);
                                  }}
                            >       
                                <option selected disabled>Choose a State</option>
                                {states.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                  ))}
                              </select>
                                <div className='absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer'>
                                    <img src={arrow_down} alt="arrow" />
                                </div>
                            </div>
                          </div>
                        <div>
                          <div className='relative'>
                             <select
                              name="lgaID"
                              placeholder="Choose LGA"
                              className='appearance-none cursor-pointer w-full
                               placeholder:text-[#96A397] placeholder:text-sm text-[#344335]
                                text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input
                                 rounded-lg border border-[#96A397] bg-white py-3 px-3'
                                 value={values.lgaID}
                                 onChange={handleChange}
                                  onBlur={handleBlur}
                                  defaultValue=""
                            >
                                <option selected value="" disabled>Choose LGA</option>
                                    {lgas.map((option) => (
                                  <option key={option.id} value={option.id}>{option.name}</option>
                                  ))}
                            </select>
                              <div className='absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer'>
                                    <img src={arrow_down} alt="arrow" />
                              </div>
                              <small style={{ color: "#dc3545" }}>
                                 {touched.lgaID && errors.lgaID}
                              </small>
                            </div>

                          </div>  
                        </div>

                        <div className='mt-5'>
                            <textarea id="deliveryAddress" rows="8" 
                            className='appearance-none w-full
                            placeholder:text-[#96A397] placeholder:text-sm
                              text-secondary1
                              text-sm
                              focus:border-[#ABB6AC]
                              focus:border outline-none
                              rounded-lg resize-none
                              bg-[#F7F7F7] py-3 px-4'
                                value={values.deliveryAddress}
                                onChange={handleChange}
                                onBlur={handleBlur}
                               placeholder="Please fill the delivery address"></textarea>
                          </div> 

                      </div>

                      {/* checkout */}
                      <div className='bg-white flex justify-between items-center rounded-lg py-6 px-4 mt-5'>
                          <div>
                            <p className='text-[#7C8D7E] text-sm font-semibold'>Total Amount</p>
                            <h4 className='text-2xl text-primary font-semibold'><i className='mdi mdi-currency-ngn font-semibold'></i>{getTotalAmount().totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h4>

                             {
                               farmerWalletBalance < getTotalAmount().totalPrice &&
                              <div className='flex gap-1 items-center'>
                                <img src={warning} alt="refresh" className='cursor-pointer' />
                                <p className='text-[#B92043] text-xs font-medium'>Insufficent Balance</p>
                              </div>
                              }

                          </div>
                          <div>
                            <button
                            type="submit"
                              className="bg-primary
                                disabled:bg-[#ABB6AC] 
                                rounded-lg w-full py-3 px-6 text-white font-medium hover:bg-[#24752B] transition-all">Checkout</button>
                              </div>
                      </div>
                      </Form>
                    )}
                  </Formik>

                  </div>

                </div>
                  </div>
                  :
                 (
                 <div className='max-w-sm mx-auto'>
                  <div className='flex justify-center mt-20'>
                    <div className='bg-[#DBFFDF] w-[60px] h-[60px] rounded-full flex justify-center items-center'>
                      <img src={cart_icon} alt="cart" />
                    </div>
                    </div>
      
                    <div className='text-center mt-3'>
                      <p className='text-[#344335] text-xs font-medium'>Looks like we have an empty cart</p>
      
                      <p className='text-[#7C8D7E] text-xs font-medium mt-1'>
                      Add item(s) to cart by creating orders
                      </p>
                    </div>
      
                    <div className='flex justify-center mt-4'>
                      <a target="_blank" href={`/callfarmer/${query.id}`} rel="noreferrer" className='
                            py-3 px-6 bg-primary rounded-[8px] text-white 
                            text-sm font-medium hover:bg-[#24752B] transition-all'>Initiate Call</a>
                    </div>
      
                </div>
                )
             }
        
        
          </>
        )
           : null}
      </div>
    </div>
  )
}

export default FarmersProfile;
