import React,{useEffect, useState, useCallback, useMemo} from 'react';
import Breadcrumb from '../../Components/Breadcrumbs/Breadcrumb'
import { useNavigate } from 'react-router-dom';
import PaginationComponent from '../../Components/Pagination/Pagination'
import { useSelector, useDispatch } from 'react-redux';
import { getAssignedFollowUps } from '../../redux/Home/homeActions';
import search_icon from '../../assets/search.svg'
import {debounce} from 'lodash'
import { searchAssignedFollowUps } from '../../redux/Home/home';
import { updateFollowUpStatus, updateFarmersAssignment } from '../../redux/Calls/calls';
import moment from 'moment';

const AssignedFollowUpPage = () => {

  const {followUps, followUpLoader,followUpCount} = useSelector((state)=> state.home)
  const { campaign  } = useSelector((state) => state.campaign)


  const dispatch = useDispatch()
  const navigate = useNavigate()

  const capitalizeFirstLetter = (str) => {
    let val = str.toLowerCase()
    let words = val.split(" ");
   for (let i = 0; i < words.length; i++) {
       words[i] = words[i][0].toUpperCase() +  words[i].substr(1);
   }
    return words.join(" ");
  }

    const [breadcrumb] = useState([
        {
          text: 'Home',
          href: `/home/${campaign.id}`,
          disabled: true,
        },
        {
            text: 'Assigned Follow Up Calls',
            href: '/followup-calls',
            disabled: false,
          },
    ])


    const [searchValue, setSearchValue] = useState("")

    // handle search
    const handleSearch = useMemo(()=>
    debounce((val)=>{
     //  api to dispatch and make the actions for search
       dispatch(searchAssignedFollowUps(val))
    },750),
   [dispatch]
  )
 
   const handleChange = useCallback((e) =>{
     let val = e.target.value
     setSearchValue(val)
     handleSearch(val)
   },[handleSearch])

   
   const getCallBg = (val) => {
    let result
    switch (val) {
      case 'INCOMPLETE':
        result = 'missed'
        break
      case 'NOT CALLED':
        result = 'notcalled'
        break;
      case 'FOLLOW UP':
        result = 'pending'
        break
      case 'MISSED':
        result = 'missed'
        break;
      case 'CALLED':
        result = 'called'
        break
      default:
        result = 'notcalled'
        break
    }
    return result
  }

  const getCallText = (val) => {
    let result
    switch (val) {
      case 'INCOMPLETE':
        result = 'missedText'
        break;
      case 'NOT CALLED':
        result = 'notcalledText'
        break;
        case 'MISSED':
          result = 'missedText'
          break;
      case 'FOLLOW UP':
        result = 'pendingText'
        break
      case 'CALLED':
        result = 'calledText'
        break
      default:
        result = 'notcalledText'
        break
    }
    return result
  }
  


     const [currentPage, setCurrentPage] = useState(1)

    const onPageChange = (val) =>{
      const {page} = val
      setCurrentPage(page)
      dispatch(getAssignedFollowUps({
        page: page,
        campaignId: campaign.id
      }))
    }

    const viewProfile = (farmerId, status, id) =>{
      dispatch(updateFollowUpStatus(status))
      dispatch(updateFarmersAssignment(id))
      navigate(`/farmer/${farmerId}`)
   }


    useEffect(()=>{
      dispatch(getAssignedFollowUps({
        page: 1,
        campaignId: campaign.id
      }))
    },[dispatch, campaign])
    


    return ( 
        <>
         <div className='mt-3 pb-10'>
            <Breadcrumb breadcrumb={breadcrumb} />

            <div className='mt-6 bg-white rounded-lg px-6 py-5'>
               <h4 className='text-primary font-semibold text-lg'>Assigned Follow Up Calls</h4>

              <div className='mt-5'>
                    <div className="relative">
                      <input type="text"
                           onChange={handleChange}   
                           value={searchValue}
                          className="appearance-none w-full placeholder:text-[#96A397] min-w-[400px] 
                          placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] 
                          focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-10"
                          placeholder="Search by farmer, call status" />
                           <img
                            src={search_icon}
                            alt="search"
                            className="absolute top-1/2 transfrom -translate-y-1/2 left-4 cursor-pointer"
                          />
                      </div>
                </div>

            </div>

             {/* tables */}
          <div className="overflow-x-auto relative mt-2 sm:rounded-tr-lg sm:rounded-tl-lg">
            <table className="w-full text-left">
                <thead className="text-primary bg-white text-sm border-b-[#F7F7F7] border-b-2">
                    <tr>
                        <th scope="col" className="py-4 px-6 font-semibold">
                            Name
                        </th>
                        <th scope="col" className="py-4 px-6 font-semibold">
                            Phone Number
                        </th>
                        <th scope="col" className="py-4 px-6 font-semibold">
                            Follow Up Date
                        </th>
                        <th scope="col" className="py-4 px-6 font-semibold">
                            Call Status
                        </th>
                        <th scope="col" className="py-4 px-6 font-semibold">
                            Action
                        </th>
                    </tr>
                </thead>


                {!followUpLoader && (
                  <tbody>
                    {followUps.length > 0
                      ? followUps.map((item) => (
                        <tr key={item.id} className="bg-white text-sm">
                        <th scope="row" className="py-3 px-6 font-normal text-secondary1 whitespace-nowrap">
                           {item.farmer ? item.farmer.first_name : ''}{' '}
                              {item.farmer ? item.farmer.last_name : ''}
                        </th>
                        <td className="py-3 px-6 text-secondary1">
                        {item.farmer ? item.farmer.phone_number: ""}
                        </td>
                        <td className="py-3 px-6 text-secondary1">
                        {item.follow_up_date ? moment(item.follow_up_date).format('Do, MMM YYYY hh:mm:ss a') : ""}
                        </td>
                        <td className="py-3 px-6">
                         <div className='flex'>
                            <div className={getCallBg(item.call_status)}>
                               <p className={getCallText(item.call_status)}>
                               {capitalizeFirstLetter(item.call_status)}</p> 
                            </div>
                          </div>
                           
                        </td>
                        <td className='py-3 px-6'>
                            <button 
                             onClick={() => viewProfile(item.farmer.id, item.call_status, item.id)}
                            className='rounded-lg border-primary border bg-white py-3 px-4 text-primary cursor-pointer hover:bg-[#EDF7EE] hover:text-primary transition-all'>
                               View Profile
                            </button>
                        </td>
                    </tr>
  
                        ))
                      : ''}
                  </tbody>
                )}

                </table>

              {!followUpLoader && followUps.length === 0 ? (
                  <div className="text-center mt-16">
                    <p className="text-[#5C715E] font-medium text-base">
                   You have no follow up calls assigned yet!
                    </p>
                  </div>
                ) : (
                  ''
                )}

            {!followUpLoader && followUps.length > 0 ? (
                <div className="mt-1">
                  <PaginationComponent
                    page={currentPage}
                    onPageChange={onPageChange}
                    pageSize={10}
                    totalItems={followUpCount}
                  />
                </div>
              ) : (
                ''
              )}


           {followUpLoader && (
                <div className="animate-pulse w-full mt-4">
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                </div>
              )}

           
          </div>
          </div>
        </>
     );
}
 
export default AssignedFollowUpPage;