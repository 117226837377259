import React, { useState } from 'react'
// import logo from '../../assets/logo.svg'
import logo1 from '../../assets/logo_white.svg'
import home_active from '../../assets/home_active.svg'
import home_icon from '../../assets/home.svg'
import logout from '../../assets/logout1.svg'
import cart from '../../assets/cart.svg'
import cart_active from '../../assets/cart_active.svg'
import { NavLink, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Logout } from '../../redux/Auth/auth'

const Sidebar = () => {
  const location = useLocation()
  const [toggleOpen, setToggleOpen] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.auth)
  const { campaign } = useSelector((state) => state.campaign)

  const handleLogout = () => {
    dispatch(Logout())
    navigate('/')
  }

  return (
    <div className="cursor-pointer font-urbanist">
      {/* SMALLER SCREENS */}
      {/* To get the nav bar on mobile and tablet view */}
      <div className="flex lg:hidden align-center justify-between p-4">
        <div className="">
          <div onClick={() => setToggleOpen(!toggleOpen)} className="">
            <i className="mdi mdi-menu text-black text-[24px]"></i>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-center rounded-full bg-[#2B8C34] w-[35px] h-[35px]">
            <p className=" text-center font-medium text-xs text-white">
              {userInfo ? userInfo.first_name.charAt(0).toUpperCase() : 'O'}
              {userInfo ? userInfo.last_name.charAt(0).toUpperCase() : 'A'}
            </p>
          </div>
        </div>
      </div>

      {/* SMALLER SCREENS */}
      {toggleOpen ? (
        <div className="block mt-[-69px] bg-[#2B8C34] absolute w-[256px] h-full z-10 lg:hidden">
          <div className="flex my-[15px] p-4 justify-between">
            {/* <img src={logo} alt="logo" className="w-[144.77px] h-[31px]" /> */}
            <div
              className="cursor-pointer mt-1"
              onClick={() => setToggleOpen(false)}
            >
              <i className="mdi mdi-close"></i>
            </div>
          </div>

          <div className="">
            {/* HOME */}
            <NavLink
              className={
                location.pathname.includes('/home') ||
                location.pathname === '/followup-calls' ||
                location.pathname.includes('/farmer') ||
                location.pathname.includes('/callfarmer')
                  ? 'flex bg-[#fff] text-[#2B8C34] w-[248px] pl-[36.5px] pr-[8px] pt-[13px] pb-[13px] rounded-tr-[8px] rounded-br-[8px] mt-[8px]'
                  : 'flex hover:bg-[#4E9F56] hover:w-[248px] pl-[36.5px] pr-[8px] pt-[13px] pb-[13px] hover:rounded-tr-[8px] hover:rounded-br-[8px] mt-[8px]'
              }
              to={`/home/${campaign ? campaign.id : 1}`}
            >
              <img
                src={
                  location.pathname.includes('/home') ||
                  location.pathname === '/followup-calls' ||
                  location.pathname.includes('/farmer') ||
                  location.pathname.includes('/callfarmer')
                    ? home_active
                    : home_icon
                }
                alt="home"
                className=""
              />
              <p className="pl-[16px] font-semibold text-sm">Home</p>
            </NavLink>

            {/* ORDERS */}
            <NavLink
              className={
                location.pathname === '/orders'
                  ? 'flex bg-[#fff] text-[#2B8C34] w-[248px] pl-[36.5px] pr-[8px] pt-[13px] pb-[13px] rounded-tr-[8px] rounded-br-[8px] mt-[8px]'
                  : 'flex hover:bg-[#4E9F56] hover:w-[248px] pl-[36.5px] pr-[8px] pt-[13px] pb-[13px] hover:rounded-tr-[8px] hover:rounded-br-[8px] mt-[8px]'
              }
              to="/orders"
            >
              <img
                src={location.pathname === '/orders' ? cart_active : cart}
                alt="dashboard"
                className=""
              />
              <p className="pl-[16px] font-semibold text-sm">Orders</p>
            </NavLink>
          </div>
        </div>
      ) : null}

      {/* DESKTOP SCREENS */}
      <div className="hidden lg:flex pl-[36.5px] my-[40px] ">
        <img src={logo1} alt="logo" className="md:w-[108px] md:h-[32px]" />
      </div>

      {/* DESKTOP SCREENS */}
      <div className="hidden lg:block">
        <div className="w-[256px]">
          {/* HOME */}
          <NavLink
            className={
              location.pathname.includes('/home') ||
              location.pathname === '/followup-calls' ||
              location.pathname.includes('/farmer') ||
              location.pathname.includes('/callfarmer')
                ? 'flex bg-[#fff] text-[#2B8C34] w-[248px] pl-[36.5px] pr-[8px] pt-[13px] pb-[13px] rounded-tr-[8px] rounded-br-[8px]'
                : 'flex hover:bg-[#4E9F56] hover:w-[248px] pl-[36.5px] pr-[8px] pt-[13px] pb-[13px] hover:rounded-tr-[8px] hover:rounded-br-[8px]'
            }
            to={`/home/${campaign ? campaign.id : 1}`}
          >
            <img
              src={
                location.pathname.includes('/home') ||
                location.pathname === '/followup-calls' ||
                location.pathname.includes('/farmer') ||
                location.pathname.includes('/callfarmer')
                  ? home_active
                  : home_icon
              }
              alt="home"
              className=""
            />
            <p className="pl-[16px] font-semibold text-sm">Home</p>
          </NavLink>

          {/* ORDERS */}
          <NavLink
            className={
              location.pathname === '/orders'
                ? 'flex bg-[#fff] text-[#2B8C34] w-[248px] pl-[36.5px] pr-[8px] pt-[13px] pb-[13px] rounded-tr-[8px] rounded-br-[8px] mt-[8px]'
                : 'flex hover:bg-[#4E9F56] hover:w-[248px] pl-[36.5px] pr-[8px] pt-[13px] pb-[13px] hover:rounded-tr-[8px] hover:rounded-br-[8px] mt-[8px]'
            }
            to="/orders"
          >
            <img
              src={location.pathname === '/orders' ? cart_active : cart}
              alt="dashboard"
              className=""
            />
            <p className="pl-[16px] font-semibold text-sm">Orders</p>
          </NavLink>
        </div>

        {/* LOGOUT */}
        <div
          onClick={handleLogout}
          className="flex hover:bg-[#4E9F56] hover:w-[248px] pl-[36.5px] pr-[8px] pt-[13px] pb-[13px] hover:rounded-tr-[8px] hover:rounded-br-[8px] fixed bottom-10"
        >
          <img src={logout} alt="dashboard" className="" />
          <p className="pl-[16px] font-semibold text-sm">Logout</p>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
