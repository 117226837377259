import { createSlice } from '@reduxjs/toolkit'
import { addItemsToCart, addNotes, atlasSearch, createCallsOrder, deleteCartItems, getCartItems,
   getFarmersAssignment,
   getNoteHistory, updateAssignment, UpdateCartQantity } from './callActions'


const initialState = {
  loading: false,
  searchResult: [],
  groupByResult:{},
  filteredByResult: {},
  addCartLoader: false,
  cart: [],
  notesHistory: [],
  shortCode: null,
  isOrderLoader: false,
  deleteLoader: false,
  processloader: [],
  assignmentId: null,
  orderPlaced: false,
  saveLoader: false,
  was_follow_up: false,
  informationCard: []
}

export const callSlice = createSlice({
  name: 'calls',
  initialState,
  reducers: {
      INCREMENTCARTQTY: (state,{payload}) =>{
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
          // doesn't actually mutate the state because it uses the Immer library,
          // which detects changes to a "draft state" and produces a brand new
          // immutable state based off those changes
        let incrementItems = state.cart.map((item) => item.id === payload.id ?
         { 
           ...item,
            quantity: item.quantity + 1
          } : item);
        state.cart = incrementItems
      },
      DECREMENTCARTQTY: (state, {payload}) =>{
        let decrementItems = state.cart.map((item) => item.id === payload.id ? 
           {
              ...item,
              quantity: item.quantity - 1,
            }
          : item);
         state.cart = decrementItems
      },
      ProcessLoad: (state, {payload}) =>{
        state.processloader = [...state.processloader, payload]
      },
      addToCart: (state, {payload}) =>{
          // check if product exist in cart, if true adjust quantity
          const inCart = state.cart.find((item) =>
          item.id === payload.id ? true : false)
          
          // adding a new product to cart
          let result = payload

          let newCartItems =  inCart ? state.cart.map((item)=> item.id === payload.id ?
           {...item, quantity: item.quantity + payload.quantity} : item) 
           : [...state.cart, result]

           state.cart = newCartItems  
      },
      removeCart: (state, {payload}) =>{
        let removedItem = state.cart.filter(item => item.id !== payload.id)
        state.cart = removedItem
      },
      clearShortCode: (state) =>{
        state.shortCode = null
      },
      filterAtlasSearch: (state, {payload}) =>{
          let res;
          let input = state.filteredByResult.InputProducts ? state.filteredByResult.InputProducts : []
          let livestock = state.filteredByResult.LivestockInputs ? state.filteredByResult.LivestockInputs : []
          let advisory = state.filteredByResult.AdvisoryProducts ? state.filteredByResult.AdvisoryProducts : []
          let insurance = state.filteredByResult.InsuranceProducts ? state.filteredByResult.InsuranceProducts : []
    
          switch(payload){
            case 'all':
              state.groupByResult = state.filteredByResult
              state.searchResult = [  ...input, ...livestock,
                ...advisory, ...insurance]
              break;
            case 'crop':
              res = state.filteredByResult.InputProducts
              state.groupByResult = {
                InputProducts: res,
                AdvisoryProducts: [],
                LivestockInputs: [],
                InsuranceProducts: []
              }
              state.searchResult = [...res]
              break;
            case 'livestock':
              res = state.filteredByResult.LivestockInputs
              state.groupByResult = {
                LivestockInputs: res,
                InputProducts: [],
                AdvisoryProducts: [],
                InsuranceProducts: []
              }
              state.searchResult = [...res]
              break;
            case 'advisory':
                res = state.filteredByResult.AdvisoryProducts
                state.groupByResult = {
                  LivestockInputs: [],
                  InputProducts: [],
                  AdvisoryProducts: res,
                  InsuranceProducts: []
                }
                state.searchResult = [...res]
              break;
            case 'insurance':
                res = state.filteredByResult.InsuranceProducts
                state.groupByResult = {
                  LivestockInputs: [],
                  InputProducts: [],
                  AdvisoryProducts: [],
                  InsuranceProducts: res
                }
                state.searchResult = [ ...res]
              break;
            default:
              break;
          }
      },
      updateFollowUpStatus: (state, {payload})=>{
        if(payload === "FOLLOW UP"){
          state.was_follow_up = true
        }
        else{
          state.was_follow_up = false
        }
      },
      updateFarmersAssignment: (state, {payload})=>{
          state.assignmentId = payload
      }
    
  },
  extraReducers: {
    // ATLAS SEARCH -------------------- ---------------------------------------------------------------
    [atlasSearch.pending]: (state) =>{
      state.loading = true
      state.searchResult=[]
      state.groupByResult={}
      state.filteredByResult={}
      state.informationCard = []
    },
    [atlasSearch.fulfilled]: (state, {payload}) =>{
      state.loading = false
      let input = payload.data.products.InputProducts ? payload.data.products.InputProducts : []
      let livestock = payload.data.products.LivestockInputs ? payload.data.products.LivestockInputs : []
      let advisory = payload.data.products.AdvisoryProducts ? payload.data.products.AdvisoryProducts : []
      let insurance = payload.data.products.InsuranceProducts ? payload.data.products.InsuranceProducts : []

      state.searchResult = [  ...input, ...livestock,
                             ...advisory, ...insurance]

      state.groupByResult = payload.data.products
      state.filteredByResult = payload.data.products
      state.informationCard = payload.data.information_card

    },
    [atlasSearch.rejected]: (state) =>{
      state.loading = false
    },


    // get cart items ------
    [getCartItems.pending]: (state)=>{

    },
    [getCartItems.fulfilled]: (state, {payload})=>{
      let res = payload.data
      if(res && res.cart_items){
        if(res.cart_items.some((val)=> val.id === null)){
          state.cart = []
        }
        else{
          state.cart = res.cart_items
        }
      }
      else{
        state.cart = []
      }
    },
    [getCartItems.rejected]: (state) =>{

    },

    // Add Items to cart  ----------
    [addItemsToCart.pending]: (state) =>{
        state.addCartLoader = true
    },
    [addItemsToCart.fulfilled]: (state,{payload}) =>{
        state.cart = payload.data.cart_items
        state.addCartLoader = false
    },
    [addItemsToCart.rejected] : (state)=>{
      state.addCartLoader = false

    },

    // Update quantity of items in cart ------
    [UpdateCartQantity.pending]: (state, {payload}) =>{
   
    },
    [UpdateCartQantity.fulfilled]: (state,{payload}) =>{
      let resp = state.processloader.splice(1)
      state.processloader = resp
      let incrementItems = state.cart.map((item) => item.id === payload.id ?
      { 
        ...item,
         quantity: payload.quantity
       } : item);
     state.cart = incrementItems

      //  state.cart = payload.data.cart_items
    },
    [UpdateCartQantity.rejected]: (state) =>{

    },

    // ---- Delete items from cart
    [deleteCartItems.pending]: (state)=>{
        state.deleteLoader = true
    },
    [deleteCartItems.fulfilled]: (state, {payload})=>{
        state.deleteLoader = false
        let removedItem = state.cart.filter(item => item.id !== payload.id)
        state.cart = removedItem
        // state.cart = payload.data.cart_items
    },
    [deleteCartItems.rejected]: (state)=>{
        state.deleteLoader = false
    },

    // ----- Checkout orders via call board -------- 
    [createCallsOrder.pending] : (state)=>{
        state.isOrderLoader = true 
    },
    [createCallsOrder.fulfilled]: (state, {payload})=>{
        state.shortCode = payload.data.data.short_code
        state.isOrderLoader = false 
        state.orderPlaced = true
        state.cart = []
        state.searchResult=[]
        state.groupByResult={}
        state.filteredByResult={}
        state.informationCard = []
    },
    [createCallsOrder.rejected]: (state)=>{
       state.isOrderLoader = false 
    },

    // --- notes history --------
    [getNoteHistory.pending]:(state)=>{

    },
    [getNoteHistory.fulfilled]: (state, {payload})=>{
        state.notesHistory = payload.data
        state.searchResult=[]
        state.groupByResult={}
        state.filteredByResult={}
        state.informationCard = []
    },
    [getNoteHistory.rejected]: (state)=>{

    },


    // add notes -------
    [addNotes.pending]: (state)=>{
       
    },
    [addNotes.fulfilled]: (state) =>{
     
    },
    [addNotes.rejected]: (state)=>{
      
    },

    // ---- Get farmers assigment -----
    [getFarmersAssignment.pending]: (state)=>{

    },
    [getFarmersAssignment.fulfilled]: (state, {payload})=>{
        state.assignmentId = payload.data[payload.data.length - 1].id
    },
    [getFarmersAssignment.rejected]: (state)=>{

    },

    // Update assignment  -----------
    [updateAssignment.pending]: (state)=>{
      state.saveLoader = true
    },
    [updateAssignment.fulfilled]: (state) =>{
      state.saveLoader = false
      state.orderPlaced = false
    },
    [updateAssignment.rejected]: (state) =>{
      state.saveLoader = false
    }
 

  }
})

// Action creators are generated for each case reducer function
export const { INCREMENTCARTQTY, DECREMENTCARTQTY, addToCart, removeCart, ProcessLoad, clearShortCode, filterAtlasSearch, updateFollowUpStatus, updateFarmersAssignment } = callSlice.actions

export default callSlice.reducer