import React from 'react';
import './drawer.css'
import close_icon from '../../assets/close.svg'
import { CSSTransition } from 'react-transition-group'

const DrawerComponent = ({show,showDrawer,title, children}) => {

    return ( 
        <>
           <CSSTransition
            in={show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
          <div className='drawer fixed 
          inset-0 bg-[#344335] bg-opacity-50 z-20 opacity-0
           overflow-y-auto h-full w-full transition-all duration-[0.3s]'
              >
          <div className='drawer-content fixed z-40 top-0 -right-80 md:w-[500px] w-80 h-screen py-6 px-6 overflow-y-auto transition-all duration-[0.3s] bg-white'
           >
              <div className='flex justify-between items-center'>
                <h5 className="text-lg font-semibold text-secondary1">
                    {title}</h5>
                    <button type="button"
                         onClick={showDrawer}
                     className="text-gray-400 bg-[#EDF7EE]
                              py-4 px-6
                            rounded-lg text-sm flex items-center justify-center" >
                        <img src={close_icon} className='' alt="close icon" />
                  </button>
              </div>
              
              {/* drawer body */}

              {
             children
              }

                   
          </div>
       </div>  
       </CSSTransition>
        </>
     );
}
 
export default DrawerComponent;